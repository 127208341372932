<div class="callback-container">
  <div class="message-box">
    <h1>Authenticating...</h1>
    <p>Please wait while we process your authentication.</p>
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
      [fullScreen]="false"
    ></ngx-spinner>
  </div>
</div>