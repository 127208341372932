import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { QueryChange } from 'src/interfaces/content';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchResults: any;
  private querySubject: Subject<QueryChange> = new Subject<QueryChange>();
  public queryChanges: Observable<QueryChange> = this.querySubject.asObservable();

  constructor() { }

  setSearchResults(results: any): void {
    this.searchResults = results;
  }

  getSearchResults(): any {
    return this.searchResults;
  }

  emitQueryChange(contentType: string, filter: any): void {
    this.querySubject.next({ contentType, filter });
  }
}