<div *ngIf="isLoading" class="spinner-overlay">
  <div class="spinner-container">
    <div class="la-ball-clip-rotate-pulse la-2x" style="color: #f4696b;">
      <div></div>
      <div></div>
    </div>
  </div>
</div>

<div *ngIf="errorMessage" class="error">
  {{ errorMessage }}
</div>

<div [ngClass]="{'content-container': true, 'full-vh': !allContent}" *ngIf="allContent || !isLoading">
  <ng-container *ngIf="allContent; else noContent">
    <!-- Movies Section -->
    <h2>Movies</h2>
    <p *ngIf="noMoviesMessage">{{ noMoviesMessage }}</p>
    <table class="content-table" *ngIf="!noMoviesMessage">
      <thead>
        <tr>
          <th>Image</th>
          <th>Title</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let movie of allContent?.movies?.data">
          <td><img [src]="movie.image" alt="{{ movie.title }}" class="content-image"></td>
          <td>{{ movie.title }}</td>
          <td>{{ movie.description }}</td>
          <td class="actions">
            <a [href]="movie.movieUrl" target="_blank" class="content-link">Watch Movie</a>
            <button (click)="editContent('movies', movie.id.toString())" class="btn btn-edit" title="Edit"><i class="fas fa-edit"></i></button>
            <button (click)="deleteContent('movies', movie.contentId.toString())" class="btn btn-delete" title="Delete"><i class="fas fa-trash-alt"></i></button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Shows Section -->
    <h2>Shows</h2>
    <p *ngIf="noShowsMessage">{{ noShowsMessage }}</p>
    <table class="content-table" *ngIf="!noShowsMessage">
      <thead>
        <tr>
          <th>Image</th>
          <th>Title</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let show of allContent?.shows?.data">
          <td><img [src]="show.image" alt="{{ show.title }}" class="content-image"></td>
          <td>{{ show.title }}</td>
          <td>{{ show.description }}</td>
          <td class="actions">
            <a [href]="show.showUrl" target="_blank" class="content-link">Watch Show</a>
            <button (click)="editContent('shows', show.id.toString())" class="btn btn-edit" title="Edit"><i class="fas fa-edit"></i></button>
            <button (click)="deleteContent('shows', show.contentId.toString())" class="btn btn-delete" title="Delete"><i class="fas fa-trash-alt"></i></button>
          </td>
        </tr>
        <tr *ngFor="let show of allContent?.shows?.data">
          <td colspan="4" *ngIf="show.seasons && show.seasons.length > 0">
            <h4>Seasons of {{ show.title }}</h4>
            <table class="content-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let season of show.seasons">
                  <td>{{ season.title }}</td>
                  <td>{{ season.description }}</td>
                  <td class="actions">
                    <table class="content-table">
                      <thead>
                        <tr>
                          <th>Episode Title</th>
                          <th>Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let episode of season.episodes">
                          <td>{{ episode.title }}</td>
                          <td>{{ episode.description }}</td>
                          <td class="actions">
                            <a [href]="episode.showEpisodeUrl" target="_blank" class="content-link">Watch Episode</a>
                            <button (click)="editEpisode('shows', show.contentId.toString(), season.id.toString(), episode.id.toString())" class="btn btn-edit" title="Edit"><i class="fas fa-edit"></i></button>
                            <button (click)="deleteEpisode('shows', season.id.toString(), episode.id.toString())" class="btn btn-delete" title="Delete"><i class="fas fa-trash-alt"></i></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Podcasts Section -->
    <h2>Podcasts</h2>
    <p *ngIf="noPodcastsMessage">{{ noPodcastsMessage }}</p>
    <table class="content-table" *ngIf="!noPodcastsMessage">
      <thead>
        <tr>
          <th>Image</th>
          <th>Title</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let podcast of allContent?.podcasts?.data">
          <td><img [src]="podcast.image" alt="{{ podcast.title }}" class="content-image"></td>
          <td>{{ podcast.title }}</td>
          <td>{{ podcast.description }}</td>
          <td class="actions">
            <a [href]="podcast.podcastUrl" target="_blank" class="content-link">Listen to Podcast</a>
            <button (click)="editContent('podcasts', podcast.id.toString())" class="btn btn-edit" title="Edit"><i class="fas fa-edit"></i></button>
            <button (click)="deleteContent('podcasts', podcast.contentId.toString())" class="btn btn-delete" title="Delete"><i class="fas fa-trash-alt"></i></button>
          </td>
        </tr>
        <tr *ngFor="let podcast of allContent?.podcasts?.data">
          <td colspan="4" *ngIf="podcast.episodes && podcast.episodes.length > 0">
            <h4>Episodes of {{ podcast.title }}</h4>
            <table class="content-table">
              <thead>
                <tr>
                  <th>Episode Title</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let episode of podcast.episodes">
                  <td>{{ episode.title }}</td>
                  <td>{{ episode.description }}</td>
                  <td class="actions">
                    <a [href]="episode.podcastEpisodeUrl" target="_blank" class="content-link">Listen to Episode</a>
                    <button (click)="editEpisode('podcasts', podcast.contentId.toString(), null, episode.id.toString())" class="btn btn-edit" title="Edit"><i class="fas fa-edit"></i></button>
                    <button (click)="deleteEpisode('podcasts', null, episode.id.toString())" class="btn btn-delete" title="Delete"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- RSS Feeds Section -->
    <h2>RSS Feeds</h2>
    <p *ngIf="noRssfeedMessage">{{ noRssfeedMessage }}</p>
    <table class="content-table" *ngIf="!noRssfeedMessage">
      <thead>
        <tr>
          <th>Image</th>
          <th>Title</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let content of allContent?.rssFeeds?.data">
          <td><img [src]="content.image" alt="{{ content.title }}" class="content-image"></td>
          <td>{{ content.title }}</td>
          <td>{{ content.description }}</td>
          <td class="actions">
            <button (click)="editRSSFeed(content?.contentId!.toString(), content?.rssUrl!)" class="btn btn-edit" title="Edit"><i class="fas fa-edit"></i></button>
            <button (click)="deleteRSSFeed(content?.id!.toString())" class="btn btn-delete" title="Delete"><i class="fas fa-trash-alt"></i></button>
          </td>
        </tr>
        <tr *ngFor="let content of allContent?.rssFeeds?.data">
          <td colspan="4" *ngIf="content?.feeds && content?.feeds!.length > 0">
            <h4>Feeds of {{ content?.title }}</h4>
            <table class="content-table">
              <thead>
                <tr>
                  <th>Feed URL</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let feed of content?.feeds">
                  <td><a [href]="feed?.guid" target="_blank" class="content-link">Listen to Feed</a></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template #noContent>
    <p>No content available.</p>
  </ng-template>
</div>

<div *ngIf="isModalOpen" class="modal">
  <div class="modal-content">
    <span class="close" (click)="closeModal()" (keydown)="handleKeyDown($event)" (keypress)="handleKeyPress($event)" (keyup)="handleKeyUp($event)">&times;</span>
    <h2>Edit Content</h2>
    
    <!-- Modal Message Section -->
    <div *ngIf="modalMessage" class="modal-message" [ngClass]="{'error': isError, 'success': isSuccess}">
      {{ modalMessage }}
    </div>

    <!-- Spinner for uploading state -->
    <div *ngIf="isUploading" class="spinner-overlay">
      <div class="spinner-container">
        <div class="la-ball-clip-rotate-pulse la-2x" style="color: #f4696b;">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

    <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="edit-form">
      <div class="form-grid">
        <div class="form-group" *ngIf="!isEditingRSSFeed">
          <label for="title">Title</label>
          <input id="title" formControlName="title" type="text">
          <div *ngIf="editForm.get('title')?.invalid && (editForm.get('title')?.dirty || editForm.get('title')?.touched)" class="error">
            <div *ngIf="editForm.get('title')?.errors?.['required']">Title is required.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed">
          <label for="image">Image URL</label>
          <input id="image" formControlName="image" type="text" readonly>
          <div *ngIf="editForm.get('image')?.invalid && (editForm.get('image')?.dirty || editForm.get('image')?.touched)" class="error">
            <div *ngIf="editForm.get('image')?.errors?.['required']">Image URL is required.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed">
          <label for="url">Content URL</label>
          <input id="url" formControlName="url" type="text" readonly>
          <div *ngIf="editForm.get('url')?.invalid && (editForm.get('url')?.dirty || editForm.get('url')?.touched)" class="error">
            <div *ngIf="editForm.get('url')?.errors?.['required']">Content URL is required.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed">
          <label for="releaseDate">Release Date</label>
          <input id="releaseDate" formControlName="releaseDate" type="date">
          <div *ngIf="editForm.get('releaseDate')?.invalid && (editForm.get('releaseDate')?.dirty || editForm.get('releaseDate')?.touched)" class="error">
            <div *ngIf="editForm.get('releaseDate')?.errors?.['required']">Release Date is required.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed">
          <label for="duration">Duration</label>
          <input id="duration" formControlName="duration" type="number">
          <div *ngIf="editForm.get('duration')?.invalid && (editForm.get('duration')?.dirty || editForm.get('duration')?.touched)" class="error">
            <div *ngIf="editForm.get('duration')?.errors?.['required']">Duration is required.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed && !isEditingEpisode">
          <label for="genre">Genre</label>
          <select id="genre" formControlName="genre">
            <option *ngFor="let genre of genres" [value]="genre">{{ genre }}</option>
          </select>
          <div *ngIf="editForm.get('genre')?.invalid && (editForm.get('genre')?.dirty || editForm.get('genre')?.touched)" class="error">
            <div *ngIf="editForm.get('genre')?.errors?.['required']">Genre is required.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed && !isEditingEpisode">
          <label for="contentTheme">Content Theme</label>
          <select id="contentTheme" formControlName="contentTheme">
            <option *ngFor="let theme of contentThemes" [value]="theme">{{ theme }}</option>
          </select>
          <div *ngIf="editForm.get('contentTheme')?.invalid && (editForm.get('contentTheme')?.dirty || editForm.get('contentTheme')?.touched)" class="error">
            <div *ngIf="editForm.get('contentTheme')?.errors?.['required']">Content Theme is required.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed && !isEditingEpisode">
          <label for="director">Director</label>
          <input id="director" formControlName="director" type="text">
          <div *ngIf="editForm.get('director')?.invalid && (editForm.get('director')?.dirty || editForm.get('director')?.touched)" class="error">
            <div *ngIf="editForm.get('director')?.errors?.['required']">Director is required.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed && !isEditingEpisode && currentContent?.movieUrl == null">
          <label for="producer">Producer</label>
          <input id="producer" formControlName="producer" type="text">
          <div *ngIf="editForm.get('producer')?.invalid && (editForm.get('producer')?.dirty || editForm.get('producer')?.touched)" class="error">
            <div *ngIf="editForm.get('producer')?.errors?.['required']">Producer is required.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed">
          <label for="file">Upload Video Content</label>
          <input id="file" type="file" (change)="onFileSelected($event)">
          <div *ngIf="editForm.get('file')?.invalid && (editForm.get('file')?.dirty || editForm.get('file')?.touched)" class="error">
            <div *ngIf="editForm.get('file')?.errors?.['invalidFileType']">Invalid file type. Allowed types: mp4, avi, mov, mkv.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed">
          <label for="imageFile">Upload Image</label>
          <input id="imageFile" type="file" (change)="onImageFileSelected($event)">
          <div *ngIf="editForm.get('imageFile')?.invalid && (editForm.get('imageFile')?.dirty || editForm.get('imageFile')?.touched)" class="error">
            <div *ngIf="editForm.get('imageFile')?.errors?.['invalidFileType']">Invalid file type. Allowed types: jpeg, jpg, png.</div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isEditingRSSFeed">
          <label for="description">Description</label>
          <textarea id="description" formControlName="description"></textarea>
          <div *ngIf="editForm.get('description')?.invalid && (editForm.get('description')?.dirty || editForm.get('description')?.touched)" class="error">
            <div *ngIf="editForm.get('description')?.errors?.['required']">Description is required.</div>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="isEditingRSSFeed">
        <label for="rssUrl">RSS Feed URL</label>
        <input id="rssUrl" formControlName="rssUrl" type="text">
        <div *ngIf="editForm.get('rssUrl')?.invalid && (editForm.get('rssUrl')?.dirty || editForm.get('rssUrl')?.touched)" class="error">
          <div *ngIf="editForm.get('rssUrl')?.errors?.['required']">RSS Feed URL is required.</div>
        </div>
      </div>

      <div class="form-group" *ngIf="isEditingRSSFeed">
        <label for="rssContentTheme">RSS Content Theme</label>
        <select id="rssContentTheme" formControlName="rssContentTheme">
          <option *ngFor="let theme of rssContentThemes" [value]="theme">{{ theme }}</option>
        </select>
        <div *ngIf="editForm.get('rssContentTheme')?.invalid && (editForm.get('rssContentTheme')?.dirty || editForm.get('rssContentTheme')?.touched)" class="error">
          <div *ngIf="editForm.get('rssContentTheme')?.errors?.['required']">RSS Content Theme is required.</div>
        </div>
      </div>

      <button type="submit" [disabled]="editForm.invalid" class="btn btn-submit">Update</button>
    </form>
  </div>
</div>