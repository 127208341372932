import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserModel } from 'src/app/models/models';
import { LOCAL_STORAGE } from 'src/app/utils/constants';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-signup',
  templateUrl: './admin-signup.component.html',
  styleUrls: ['./admin-signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminSignupComponent implements OnInit {
  constructor(
    private readonly auth: AuthenticationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar
  ) {}

  isLoading = false;

  async ngOnInit() {
    const canAuthenticateResult = await this.auth.canActivate();
    if (canAuthenticateResult === true) {
      this.router.navigate(['/content']);
    }

    this.route.queryParams.subscribe(params => {
      if (params['error'] === 'auth_failed') {
        this.showErrorMessage('Google authentication failed. Please try again.');
      }
    });
  }

  signUpForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
    ]),
    username: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\+\d{1,3}\s?\d{1,13}$/),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    adminSecret: new FormControl('', [Validators.required])
  });

  emailControl = this.signUpForm.get('email');
  usernameControl = this.signUpForm.get('username');
  phoneNumberControl = this.signUpForm.get('phoneNumber');
  passwordControl = this.signUpForm.get('password');
  adminSecretControl = this.signUpForm.get('adminSecret');

  isValidForm: boolean = false;

  networkErrorMessage: string = '';

  user: UserModel = new UserModel('', '', '', '');

  getEmailErrorMessage() {
    if (
      this.emailControl?.hasError('required') ||
      this.emailControl?.hasError('email') ||
      this.emailControl?.hasError('pattern')
    )
      return 'Please enter a valid email';
    else return;
  }

  getUsernameErrorMessage() {
    if (this.usernameControl?.hasError('required'))
      return 'Please enter a username';
    else return;
  }

  getPhoneNumberErrorMessage() {
    if (this.phoneNumberControl?.hasError('required')) {
      return 'Please enter a valid phone number';
    }

    if (this.phoneNumberControl?.hasError('pattern')) {
      return 'Phone number must be in the format +254 1234567890';
    }
    return 'Please enter a valid phone number';
  }

  getPasswordErrorMessage() {
    if (this.passwordControl?.value.length < 8) {
      return 'Password must be at least 8 characters long';
    }

    if (this.passwordControl?.hasError('required')) {
      return 'Please enter a password';
    }
    return '';
  }

  getAdminSecretErrorMessage() {
    if (this.adminSecretControl?.hasError('required')) {
      return 'Please enter the admin secret';
    }
    return '';
  }

  signUpWithEmailVerification() {
    if (this.signUpForm.valid) {
      this.isLoading = true;
      this.user.email = this.signUpForm.get('email')?.value;
      this.user.username = this.signUpForm.get('username')?.value;
      this.user.phoneNumber = this.signUpForm.get('phoneNumber')?.value;
      this.user.password = this.signUpForm.get('password')?.value;
      const adminSecret = this.signUpForm.get('adminSecret')?.value;

      this.auth.signUpAdmin(
        this.user.email,
        this.user.username ?? '',
        this.user.phoneNumber ?? '',
        this.user.password,
        adminSecret
      ).subscribe({
        next: (response: any) => {
          if (response.success) {
            sessionStorage.setItem(LOCAL_STORAGE.emailForSignIn, this.user.email);
            this.showSuccessMessage('Signup successful! Please verify your email.');
            this.router.navigate(['/verify-email']);
          } else {
            this.handleErrorResponse(response.error);
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.handleErrorResponse(error);
          this.isLoading = false;
        },
      });
    }
  }

  handleErrorResponse(error: any) {
    if (error.error.error === 'Admin with this email or username already exists') {
      this.showErrorMessage('Admin with this email or username already exists');
    } else {
      this.showErrorMessage('Oops! Something went wrong.');
      console.error(error.statusCode);
    }
  }

  showErrorMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['custom-snack-bar', 'custom-snack-bar-action']
    });
  }

  showSuccessMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['custom-snack-bar-success']
    });
  }

  hide = true;
}