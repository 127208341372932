import { Component, OnInit, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, switchMap, take, catchError, tap, finalize } from 'rxjs/operators';
import { Media } from 'src/interfaces/player';
import { RssFeedService } from '../services/rss-feed.service';
import moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-rssfeed-detail',
  templateUrl: './rssfeed-detail.component.html',
  styleUrls: ['./rssfeed-detail.component.scss']
})
export class RssFeedDetailComponent implements OnInit {
  rssFeed$ = new BehaviorSubject<Media | null>(null);
  episodes$ = new BehaviorSubject<any[]>([]);
  paginatedEpisodes$ = new BehaviorSubject<any[]>([]);
  loading$ = new BehaviorSubject<boolean>(true);
  error$ = new BehaviorSubject<string | null>(null);
  modalData: Media | null = null;
  feedImage: string | null = null;
  feedAuthor: string | null = null;
  currentPage = 1;
  pageSize = 15;
  totalPages = 0;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly rssFeedService: RssFeedService,
    private readonly sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(
      tap(() => {
        this.loading$.next(true);
      }),
      switchMap(params => {
        const contentId = params['id'];
        return this.rssFeedService.getRSSFeedById(contentId).pipe(
          tap(response => {
            if (!response.data) {
              throw new Error('RSS Feed not found');
            }
          }),
          map(response => {
            const { rssUrl, image, title, author } = response.data;
            this.feedImage = image;
            this.feedAuthor = author;
            return { rssUrl, image, title, author };
          }),
          switchMap(({ rssUrl, image, title, author }) => this.fetchContent(rssUrl, image, title, author)),
          catchError(error => {
            this.handleError(error);
            return of(null);
          }),
          finalize(() => {
            this.loading$.next(false);
          })
        );
      })
    ).subscribe({
      next: media => {
        if (media) {
          this.rssFeed$.next(media);
        }
      },
      error: error => {
        this.handleError(error);
      },
      complete: () => {
        this.loading$.next(false);
      }
    });
  }

  private fetchContent(rssUrl: string, image: string, title: string, author: string): Observable<Media | null> {
    return this.rssFeedService.fetchRSSFeed(rssUrl).pipe(
      map(feedData => {
        const episodes = feedData.items.sort((a: any, b: any) => new Date(a.pubDate).getTime() - new Date(b.pubDate).getTime());
        this.episodes$.next(episodes);
        this.totalPages = Math.ceil(episodes.length / this.pageSize);
        this.updatePaginatedEpisodes(episodes);
        return this.mapToMedia(rssUrl, feedData, image, title, author);
      }),
      take(1),
      catchError(error => {
        this.handleError(error);
        return of(null);
      }),
      finalize(() => {
        this.loading$.next(false);
      })
    );
  }

  private handleError(error: any): void {
    let errorMessage = 'An unknown error occurred';
    if (error.status) {
      switch (error.status) {
        case 401:
          errorMessage = 'Unauthorized';
          break;
        case 400:
          errorMessage = 'Bad Request';
          break;
        case 404:
          errorMessage = 'RSS Feed not found';
          break;
        case 500:
          errorMessage = 'An error occurred while fetching content.';
          break;
        default:
          errorMessage = `Error: ${error.message}`;
      }
    }
    this.error$.next(errorMessage);
  }

  private updatePaginatedEpisodes(episodes: any[]): void {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedEpisodes$.next(episodes.slice(startIndex, endIndex));
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedEpisodes(this.episodes$.getValue());
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePaginatedEpisodes(this.episodes$.getValue());
    }
  }

  private mapToMedia(rssUrl: string, feedData: any, image: string, title: string, author: string): Media {
    const firstEpisode = feedData.items[0];
    return {
      id: rssUrl,
      title: title,
      description: feedData.channel.description,
      contentId: rssUrl,
      contentTheme: '',
      src: firstEpisode.enclosure ?? '',
      type: 'application/rss+xml',
      thumbnail: feedData.channel.image?.url ?? '',
      label: '',
      resolution: '',
      ratio: '',
      autoplay: false,
      image: image,
      episodes: feedData.items,
      rssMetadata: {
        producer: author,
        director: '',
        releaseDate: this.formatReleaseDate(feedData.channel.pubDate),
        duration: '',
        rating: '',
        genre: feedData.channel['itunes:category'] ?? ''
      }
    };
  }

  private mapEpisodeToMedia(episode: any, author: string): Media {
    return {
      id: episode.guid,
      title: episode.title,
      description: episode.description,
      contentId: episode.guid,
      contentTheme: '',
      src: episode.enclosure ?? '',
      type: 'audio/mpeg',
      thumbnail: this.feedImage ?? '',
      label: '',
      resolution: '',
      ratio: '',
      autoplay: true,
      image: this.feedImage ?? '',
      rating: 0,
      rssEpisodeMetadata: {
        author: episode.author ?? author ?? '',
        releaseDate: this.formatReleaseDate(episode.pubDate),
        duration: episode.duration ?? '',
        genre: episode.subtitle ?? 'General',
      }
    };
  }

  private formatReleaseDate(date: string): string {
    return moment(date).format('MMMM DD, YYYY');
  }

  goBack() {
    this.router.navigate(['/content']);
  }

  generateStars(rating: number): boolean[] {
    return Array(10).fill(false).map((_, i) => i < rating);
  }

  playEpisode(episode: any): void {
    const newMedia = this.mapEpisodeToMedia(episode, this.feedAuthor ?? '');
    this.rssFeed$.pipe(take(1)).subscribe(currentMedia => {
      if (currentMedia?.src !== newMedia.src) {
        this.rssFeed$.next(newMedia);
      }
    });
  }

  openRssFeedInfo(rssFeed: Media): void {
    this.modalData = rssFeed;
    this.cdr.detectChanges();
    this.ensureModalVisible();
  }

  closeModal(): void {
    this.modalData = null;
    this.cdr.detectChanges();
  }

  ensureModalVisible(): void {
    if (isPlatformBrowser(this.platformId)) {
      const modalElement = document.querySelector('.modal') as HTMLElement;
      if (modalElement) {
        modalElement.style.display = 'flex';
        modalElement.style.visibility = 'visible';
        modalElement.style.opacity = '1';
        modalElement.style.zIndex = '1000';
        modalElement.style.justifyContent = 'center';
        modalElement.style.alignItems = 'center';
        modalElement.style.position = 'fixed';
        modalElement.style.top = '0';
        modalElement.style.left = '0';
        modalElement.style.width = '100%';
        modalElement.style.height = '100%';
        modalElement.style.background = 'rgba(0, 0, 0, 0.8)';
        modalElement.style.transition = 'opacity 0.3s ease, visibility 0.3s ease';

        const modalContent = modalElement.querySelector('.modal-content') as HTMLElement;
        if (modalContent) {
          modalContent.style.position = 'relative';
          modalContent.style.background = 'linear-gradient(135deg, #fff, #f0f0f0)';
          modalContent.style.padding = '20px';
          modalContent.style.borderRadius = '10px';
          modalContent.style.maxWidth = '600px';
          modalContent.style.width = '90%';
          modalContent.style.textAlign = 'left';
          modalContent.style.boxShadow = '0 4px 15px rgba(0, 0, 0, 0.2)';
          modalContent.style.animation = 'fade-in 0.4s ease-out';

          const keyframes = `
            @keyframes fade-in {
              from {
                opacity: 0;
                transform: translateY(-20px);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            }
          `;
          const styleSheet = document.createElement("style");
          styleSheet.media = "all";
          styleSheet.innerText = keyframes;
          document.head.appendChild(styleSheet);

          const responsiveStyles = `
            @media (max-width: 600px) {
              .modal-content {
                padding: 15px;
                font-size: 0.9rem;
              }
              .modal-content h2 {
                font-size: 1.5rem;
              }
              .modal-content p {
                font-size: 0.9rem;
              }
            }
          `;
          styleSheet.innerText += responsiveStyles;

          const closeButton = modalContent.querySelector('.close') as HTMLElement;
          if (closeButton) {
            closeButton.style.position = 'absolute';
            closeButton.style.top = '10px';
            closeButton.style.right = '10px';
            closeButton.style.fontSize = '1.5rem';
            closeButton.style.color = '#333';
            closeButton.style.cursor = 'pointer';
            closeButton.style.transition = 'color 0.2s ease';

            closeButton.addEventListener('mouseover', () => {
              closeButton.style.color = '#d9534f';
            });

            closeButton.addEventListener('mouseout', () => {
              closeButton.style.color = '#333';
            });
          }

          const title = modalContent.querySelector('h2') as HTMLElement;
          if (title) {
            title.style.fontSize = '2rem';
            title.style.marginBottom = '10px';
            title.style.color = '#333';
          }

          const paragraphs = modalContent.querySelectorAll('p') as NodeListOf<HTMLElement>;
          paragraphs.forEach(p => {
            p.style.fontSize = '1rem';
            p.style.marginBottom = '10px';
            p.style.color = '#666';
            p.style.lineHeight = '1.6';
          });

          const strongElements = modalContent.querySelectorAll('strong') as NodeListOf<HTMLElement>;
          strongElements.forEach(strong => {
            strong.style.color = '#555';
          });
        }
      }
    }
  }

  sanitizeDescription(description: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }
}