import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentService } from 'src/app/services/payment.service';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss'],
})
export class SubscriptionModalComponent implements OnInit {
  plans: any[] = [];
  selectedPlan: any = null;
  isLoading = true;
  error: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<SubscriptionModalComponent>,
    private readonly paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.initializePlans();
  }

  private initializePlans(): void {
    if (this.data?.plans) {
      this.plans = this.data.plans.filter((plan: any) => plan.plan !== 'Free 24-hour Trial');
      this.isLoading = false;

      if (this.plans.length === 0) {
        this.setError('No subscription plans are available at the moment.');
      }
    } else {
      this.setError('Failed to load subscription plans.');
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  selectPlan(plan: any): void {
    this.selectedPlan = plan;
  }

  planKeydown(event: KeyboardEvent, plan: any): void {
    if (event.code === 'Enter' || event.code === 'Space') {
      this.selectPlan(plan);
    }
  }

  confirmSelection(): void {
    if (!this.selectedPlan) {
      this.setError('Please select a plan to continue.');
      return;
    }

    const token = sessionStorage.getItem('2lietkapas');
    if (!token) {
      this.setError('Token not found in localStorage.');
      return;
    }

    try {
      const decodedToken: any = jwtDecode(token);
      const userId = decodedToken?.id;

      if (!userId) {
        this.setError('User ID not found in decoded token.');
        return;
      }

      const planId = this.selectedPlan.id;
      this.initiatePayment(userId, planId);
    } catch (error) {
      this.setError('Failed to decode token.');
    }
  }

  private initiatePayment(userId: string, planId: string): void {
    this.paymentService.initiatePayment(userId, planId).subscribe({
      next: (response) => {
        if (response.status === 'success' && response.data.paymentLink) {
          window.location.href = response.data.paymentLink;
        } else {
          this.setError('Failed to retrieve payment link.');
        }
      },
      error: (err) => {
        this.setError(err.message || 'Failed to initiate payment.');
      },
    });
  }

  private setError(message: string): void {
    this.error = message;
  }
}
