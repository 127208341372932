import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { SubscriptionService } from '../../services/subscription.service';
import {
  loadSubscriptions,
  loadSubscriptionsFailure,
  loadSubscriptionsSuccess
} from "../actions/subscription.actions";

@Injectable()
export class SubscriptionEffects {
  loadSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSubscriptions),
      mergeMap(action =>
        this.subscriptionService.getSubscriptions(action.userId).pipe(
          map((subscriptions) => loadSubscriptionsSuccess({ subscriptions })),
          catchError((error) => of(loadSubscriptionsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly subscriptionService: SubscriptionService
  ) {}
}