<div class="form-container p-2" *ngIf="showForm">
  <div class="top-title">
    <h4>Movie Upload</h4>
  </div>
  <form class="form-content p-2" [formGroup]="movieForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
    <div class="upload-animation" *ngIf="showUploadAnimation">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
        </svg>
      </div>
    </div>
    <div *ngIf="showUploadSuccessAnimation" class="alert alert-success">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="tick"
                d="M10 18l5 5l10 -10"/>
        </svg>
      </div>
      Upload Successful
    </div>
    <div *ngIf="showSaveSuccessAnimation" class="alert alert-success">
      Data Was Saved Successfully
    </div>
    <div *ngIf="showSaveErrorAnimation" class="alert alert-danger">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="cross"
                d="M12 12l12 12M12 24l12 -12"/>
        </svg>
      </div>
      {{ message }}
    </div>
    <div *ngIf="showNextUploadPrompt" class="alert alert-info">
      <p>Do you want to upload another movie?</p>
      <button type="button" class="btn btn-primary" (click)="onNextUploadResponse(true)">Yes</button>
      <button type="button" class="btn btn-secondary" (click)="onNextUploadResponse(false)">No</button>
    </div>
    <div class="form-row">
      <div class="form-column">
        <div class="p-1 form-group">
          <label for="title">Movie Title</label>
          <input type="text" class="form-control" formControlName="title" placeholder="Title" id="title">
          <div *ngIf="movieForm.controls['title'].invalid && (movieForm.controls['title'].dirty || movieForm.controls['title'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['title'].errors?.['required']">
              Title is required.
            </div>
            <div class="formalerts" *ngIf="movieForm.controls['title'].errors?.['maxlength']">
              Title exceeds the maximum length of 255 characters.
            </div>
          </div>
        </div>


        <div class="p-1 form-group">
          <label for="desc">Movie Description</label>
          <textarea class="form-control" formControlName="desc" placeholder="Movie Description" id="desc"></textarea>
          <div *ngIf="movieForm.controls['desc'].invalid && (movieForm.controls['desc'].dirty || movieForm.controls['desc'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['desc'].errors?.['required']">
              Description is required.
            </div>
            <div class="formalerts" *ngIf="movieForm.controls['desc'].errors?.['maxlength']">
              Description exceeds the maximum length of 500 characters.
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="imageFile">Image</label>
          <input id="imageFile" type="file" class="form-control" formControlName="imageFile" placeholder="Image" #imageInput>
          <div *ngIf="movieForm.controls['imageFile'].invalid && (movieForm.controls['imageFile'].dirty || movieForm.controls['imageFile'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['imageFile'].errors?.['pattern']">
              Wrong type of file, image should have a valid extension (jpg, jpeg, png).
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="file">Movie File</label>
          <input id="file" type="file" class="form-control" formControlName="file" placeholder="Movie File" #movieInput>
          <div *ngIf="movieForm.controls['file'].invalid && (movieForm.controls['file'].dirty || movieForm.controls['file'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['file'].errors?.['required']">
              Movie file is required.
            </div>
            <div class="formalerts" *ngIf="movieForm.controls['file'].errors?.['pattern']">
              Wrong type of file, movie should have a valid extension (mp4, avi, mov).
            </div>
          </div>
        </div>
        
        <div class="p-1 form-group">
          <label for="genre">Genre</label>
          <select id="genre" class="form-control" formControlName="genre">
            <option value="Comedy">Comedy</option>
            <option value="Musical">Musical</option>
            <option value="Sports">Sports</option>
            <option value="Drama">Drama</option>
            <option value="Adventure">Adventure</option>
            <option value="Romance">Romance</option>
            <option value="Documentary">Documentary</option>
          </select>
          <div *ngIf="movieForm.controls['genre'].invalid && (movieForm.controls['genre'].dirty || movieForm.controls['genre'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['genre'].errors?.['required']">
              Genre is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-column">
        <div class="p-1 form-group">
          <label for="contentTheme">Content Theme</label>
          <select id="contentTheme" class="form-control" formControlName="contentTheme">
            <option value="Family">Family</option>
            <option value="Kids">Kids</option>
            <option value="Animations">Animation</option>
            <option value="Lifestyle">Lifestyle</option>
          </select>
          <div *ngIf="movieForm.controls['contentTheme'].invalid && (movieForm.controls['contentTheme'].dirty || movieForm.controls['contentTheme'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['contentTheme'].errors?.['required']">
              Content Theme is required.
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="duration">Duration</label>
          <input id="duration" type="number" class="form-control" formControlName="duration" placeholder="Duration" required>
          <div *ngIf="movieForm.controls['duration'].invalid && (movieForm.controls['duration'].dirty || movieForm.controls['duration'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['duration'].errors?.['required']">
              Duration is required.
            </div>
            <div class="formalerts" *ngIf="movieForm.controls['duration'].errors?.['max']">
              Duration must be less than 600 minutes.
            </div>
            <div class="formalerts" *ngIf="movieForm.controls['duration'].errors?.['min']">
              Duration must be a positive number.
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="director">Director</label>
          <input id="director" type="text" class="form-control" formControlName="director" placeholder="Director" required>
          <div *ngIf="movieForm.controls['director'].invalid && (movieForm.controls['director'].dirty || movieForm.controls['director'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['director'].errors?.['required']">
              Director is required.
            </div>
            <div class="formalerts" *ngIf="movieForm.controls['director'].errors?.['maxlength']">
              Director exceeds the maximum length of 500 characters.
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="releaseDate">Release Date</label>
          <input id="releaseDate" type="date" class="form-control" formControlName="releaseDate" placeholder="Release Date" required>
          <div *ngIf="movieForm.controls['releaseDate'].invalid && (movieForm.controls['releaseDate'].dirty || movieForm.controls['releaseDate'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['releaseDate'].errors?.['required']">
              Release Date is required.
            </div>
            <div class="formalerts" *ngIf="movieForm.controls['releaseDate'].errors?.['pastDate']">
              Release Date cannot be in the past.
            </div>
          </div>
        </div>
        <div class="p-1 form-group">
          <label for="rating">Rating</label>
          <select id="rating" class="form-control" formControlName="rating">
            <option value="GE">GE</option>
            <option value="PG">PG</option>
            <option value="PG13">PG13</option>
            <option value="R">R</option>
            <option value="NC16">NC16</option>
            <option value="NC18">NC18</option>
          </select>
          <div *ngIf="movieForm.controls['rating'].invalid && (movieForm.controls['rating'].dirty || movieForm.controls['rating'].touched || submitted)">
            <div class="formalerts" *ngIf="movieForm.controls['rating'].errors?.['required']">Rating is required.</div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary" type="submit">Submit</button>
  </form>
</div>