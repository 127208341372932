import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SubscriptionState } from "../state/subscription.state";

export const selectSubscriptionState = createFeatureSelector<SubscriptionState>('subscription');

export const selectSubscriptions = createSelector(
    selectSubscriptionState,
    (state: SubscriptionState) => state.subscriptions
);

export const selectSubscriptionLoading = createSelector(
    selectSubscriptionState,
    (state: SubscriptionState) => state.loading
);

export const selectSubscriptionError = createSelector(
    selectSubscriptionState,
    (state: SubscriptionState) => state.error
);

export const selectSubLoaded = createSelector(
    selectSubscriptionState,
    (state: SubscriptionState) => state.subLoaded
);