import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input() contentData: any[] = [];
  @Input() routerLink!: string;
  @Output() itemClick: EventEmitter<any> = new EventEmitter();

  sliderConfig = {
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor() {}
  
  onItemClick(item: any): void {
    this.itemClick.emit(item);
  }

  ngOnInit(): void {}

  encodeUrl(id: string): string {
    return encodeURIComponent(id);
  }
}