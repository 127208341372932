import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { RssFeedService } from 'src/app/services/rss-feed.service';
import { AllContentResponse, ContentItem } from 'src/interfaces/content';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, finalize, switchMap, forkJoin, of, throwError } from 'rxjs';

enum ContentTheme {
  Family = 'Family',
  Lifestyle = 'Lifestyle',
  Kids = 'Kids',
  Animations = 'Animations'
}

enum RssContentTheme {
  Family = 'Family',
  Lifestyle = 'Lifestyle',
  Kids = 'Kids',
  Animations = 'Animations'
}

enum Genre {
  Comedy = 'Comedy',
  Musical = 'Musical',
  Sports = 'Sports',
  Drama = 'Drama',
  Adventure = 'Adventure',
  Romance = 'Romance',
  Documentary = 'Documentary'
}

@Component({
  selector: 'app-admin-content',
  templateUrl: './admin-content.component.html',
  styleUrls: ['./admin-content.component.scss']
})
export class AdminContentComponent implements OnInit {
  allContent: AllContentResponse | null = null;
  errorMessage: string | null = null;
  editForm: FormGroup;
  currentContent: ContentItem | null = null;
  isModalOpen: boolean = false;
  selectedFile: File | null = null;
  selectedImageFile: File | null = null;
  isLoading: boolean = false; // Loading state
  isUploading: boolean = false; // Uploading state
  modalMessage: string | null = null; // Modal message
  isError: boolean = false; // Error state for modal message
  isSuccess: boolean = false; // Success state for modal message
  isEditingEpisode: boolean = false; // Editing episode state
  isEditingRSSFeed: boolean = false; // Editing RSS feed state

  // New properties to store contentId and seasonId
  private editingContentId: string | null = null;
  private editingSeasonId: string | null = null;

  contentThemes = Object.values(ContentTheme);
  rssContentThemes = Object.values(RssContentTheme);
  genres = Object.values(Genre);

  noMoviesMessage: string | null = null;
  noShowsMessage: string | null = null;
  noPodcastsMessage: string | null = null;
  noRssfeedMessage: string | null = null;
  noEpisodesMessage: string | null = null;

  constructor(
    private readonly contentService: ContentService,
    private readonly rssFeedService: RssFeedService,
    private readonly fb: FormBuilder,
    private readonly http: HttpClient,
  ) {
    this.editForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      image: ['', Validators.required],
      url: ['', Validators.required],
      releaseDate: ['', Validators.required],
      author: [''],
      genre: ['', Validators.required],
      contentTheme: ['', Validators.required],
      duration: ['', Validators.required],
      director: [''],
      producer: [''],
      file: [null, [this.fileValidator(['mp4', 'avi', 'mov', 'mkv'])]],
      imageFile: [null, [this.fileValidator(['jpeg', 'jpg', 'png'])]],
      rssUrl: ['', Validators.required],
      rssContentTheme: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.fetchAllContent();
  }

  private fileValidator(allowedExtensions: string[]) {
    return (control: AbstractControl): ValidationErrors | null => {
      const file = control.value;
      if (file?.name) {
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          return { invalidFileType: true };
        }
      }
      return null;
    };
  }

  fetchAllContent(): void {
    this.isLoading = true; // Start loading
    this.contentService.getAllContent().subscribe({
      next: (data) => {
        this.allContent = data;
        this.isLoading = false; // Stop loading

        this.noMoviesMessage = this.allContent.movies.data.length === 0 ? 'No movies available' : null;
        this.noShowsMessage = this.allContent.shows.data.length === 0 ? 'No shows available' : null;
        this.noPodcastsMessage = this.allContent.podcasts.data.length === 0 ? 'No podcasts available' : null;
        this.noRssfeedMessage = this.allContent.rssFeeds.data.length === 0? 'No RSS feeds available' : null;
        this.noEpisodesMessage = this.allContent.podcasts.data.every(podcast => podcast.episodes.length === 0) ? 'No episodes available' : null;
      },
      error: (error) => {
        this.errorMessage = error.message;
        this.isLoading = false; // Stop loading
        console.error('Error fetching content:', this.errorMessage);
      }
    });
  }

  editContent(contentType: string, id: string): void {
    this.isEditingEpisode = false;
    this.isEditingRSSFeed = (contentType === 'rssFeeds');
    const content = this.findContentById(contentType, id);
    if (content) {
      this.currentContent = content;
      this.patchEditForm(content, contentType);
      this.openModal();
    }
  }

  editEpisode(contentType: string, contentId: string, seasonId: string | null, episodeId: string): void {
    this.isEditingEpisode = true;
    const episode = this.findEpisodeById(contentType, contentId, seasonId, episodeId);
    if (episode) {
      this.currentContent = episode;
      this.editingContentId = contentId; // Store contentId
      this.editingSeasonId = seasonId; // Store seasonId
      this.patchEditForm(episode, 'episodes');
      this.openModal();
    }
  }

  private patchEditForm(content: ContentItem, contentType: string): void {
    this.isEditingRSSFeed = (contentType === 'rssFeeds');
    this.isEditingEpisode = (contentType === 'episodes');

    if (this.isEditingRSSFeed) {
      this.editForm.patchValue({
        rssUrl: content.rssUrl,
        rssContentTheme: content.contentTheme
      });

      // Set validators for RSS feed fields
      this.editForm.get('rssUrl')?.setValidators(Validators.required);
      this.editForm.get('rssContentTheme')?.setValidators(Validators.required);

      // Clear validators for non-RSS feed fields
      this.editForm.get('title')?.clearValidators();
      this.editForm.get('description')?.clearValidators();
      this.editForm.get('image')?.clearValidators();
      this.editForm.get('url')?.clearValidators();
      this.editForm.get('releaseDate')?.clearValidators();
      this.editForm.get('author')?.clearValidators();
      this.editForm.get('contentTheme')?.clearValidators();
      this.editForm.get('genre')?.clearValidators();
      this.editForm.get('duration')?.clearValidators();
      this.editForm.get('director')?.clearValidators();
      this.editForm.get('producer')?.clearValidators();
      this.editForm.get('file')?.clearValidators();
      this.editForm.get('imageFile')?.clearValidators();

      // Update the validity of the form controls
      this.editForm.get('rssUrl')?.updateValueAndValidity();
      this.editForm.get('rssContentTheme')?.updateValueAndValidity();
    } else if (this.isEditingEpisode) {
      this.editForm.patchValue({
        title: content.title,
        description: content.description,
        image: content.image,
        url: content.episodeUrl ?? content.showEpisodeUrl ?? content.podcastEpisodeUrl,
        releaseDate: content.episodeMetadata?.releaseDate ?? content.showEpisodeMetadata?.releaseDate,
        duration: content.episodeMetadata?.duration ?? content.podcastEpisodeMetadata?.duration ?? content.showEpisodeMetadata?.duration ?? content.showMetadata?.duration,
      });

      // Set validators for episode fields
      this.editForm.get('title')?.setValidators(Validators.required);
      this.editForm.get('description')?.setValidators(Validators.required);
      this.editForm.get('url')?.setValidators(Validators.required);
      this.editForm.get('releaseDate')?.setValidators(Validators.required);
      this.editForm.get('duration')?.setValidators(Validators.required);

      // Clear validators for non-episode fields
      this.editForm.get('author')?.clearValidators();
      this.editForm.get('genre')?.clearValidators();
      this.editForm.get('contentTheme')?.clearValidators();
      this.editForm.get('director')?.clearValidators();
      this.editForm.get('producer')?.clearValidators();

      // Make image optional for episodes
      this.editForm.get('image')?.clearValidators();

      // Update the validity of the form controls
      this.editForm.get('title')?.updateValueAndValidity();
      this.editForm.get('description')?.updateValueAndValidity();
      this.editForm.get('url')?.updateValueAndValidity();
      this.editForm.get('releaseDate')?.updateValueAndValidity();
      this.editForm.get('duration')?.updateValueAndValidity();
    } else {
      this.editForm.patchValue({
        title: content.title,
        description: content.description,
        image: content.image,
        url: content.movieUrl ?? content.showUrl ?? content.podcastUrl,
        releaseDate: content.movieMetadata?.releaseDate ?? content.showMetadata?.releaseDate ?? content.podcastMetadata?.releaseDate,
        genre: content.movieMetadata?.genre ?? content.podcastMetadata?.genre ?? content.showMetadata?.genre,
        contentTheme: content.contentTheme,
        duration: content.movieMetadata?.duration ?? content.podcastMetadata?.duration ?? content.showMetadata?.duration,
        director: content.movieMetadata?.director ?? content.showMetadata?.director ?? content.podcastMetadata?.director,
        producer: content.showMetadata?.producer ?? content.podcastMetadata?.producer
      });

      // Set validators for non-episode fields
      this.editForm.get('title')?.setValidators(Validators.required);
      this.editForm.get('description')?.setValidators(Validators.required);
      this.editForm.get('image')?.setValidators(Validators.required);
      this.editForm.get('url')?.setValidators(Validators.required);
      this.editForm.get('releaseDate')?.setValidators(Validators.required);
      this.editForm.get('duration')?.setValidators(Validators.required);

      if (contentType === 'movies') {
        this.editForm.get('producer')?.clearValidators();
      } else if (contentType === 'podcasts') {
        this.editForm.get('producer')?.setValidators(Validators.required);
        this.editForm.patchValue({
          genre: content.podcastMetadata?.genre,
          contentTheme: content.contentTheme
        });
      } else if (contentType === 'shows') {
        this.editForm.get('producer')?.setValidators(Validators.required);
      } else {
        this.editForm.get('producer')?.setValidators(Validators.required);
      }

      // Clear validators for RSS feed fields
      this.editForm.get('rssUrl')?.clearValidators();
      this.editForm.get('rssContentTheme')?.clearValidators();
    }

    // Update the validity of the form controls
    this.editForm.get('title')?.updateValueAndValidity();
    this.editForm.get('description')?.updateValueAndValidity();
    this.editForm.get('image')?.updateValueAndValidity();
    this.editForm.get('url')?.updateValueAndValidity();
    this.editForm.get('releaseDate')?.updateValueAndValidity();
    this.editForm.get('duration')?.updateValueAndValidity();
    this.editForm.get('genre')?.updateValueAndValidity();
    this.editForm.get('contentTheme')?.updateValueAndValidity();
    this.editForm.get('director')?.updateValueAndValidity();
    this.editForm.get('producer')?.updateValueAndValidity();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.selectedFile = file; // Store the selected file
      // Update the form with a placeholder value or a flag
      this.editForm.patchValue({ file: 'file-selected' });
      this.editForm.get('file')?.updateValueAndValidity();
    }
  }

  onImageFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const imageFile = input.files[0];
      this.selectedImageFile = imageFile; // Store the selected image file
      // Update the form with a placeholder value or a flag
      this.editForm.patchValue({ imageFile: 'image-file-selected' });
      this.editForm.get('imageFile')?.updateValueAndValidity();
    }
  }
  
  onSubmit(): void {
    this.isUploading = true;
    if (this.isEditingRSSFeed) {
      this.updateRSSFeed();
    } else if (this.isEditingEpisode) {
      if (this.currentContent) {
        const contentType = this.getContentType(this.currentContent);
        const contentId = this.editingContentId ?? '';
        const seasonId = this.editingSeasonId ?? null;
        const episodeId = this.currentContent.id?.toString() ?? '';
        this.updateEpisode(contentType, contentId, seasonId, episodeId);
      } else {
        this.isUploading = false;
      }
    } else {
      this.updateContent();
    }
  }
  
  updateContent(): void {
    if (this.editForm.valid && this.currentContent) {
      this.isUploading = true;
      const formData = this.createFormData();
      const contentType = this.getContentType(this.currentContent);
      const id = this.currentContent.contentId?.toString();
      
      if (id) {
        if (this.isEditingEpisode) {
          const seasonId = this.currentContent.seasonId?.toString() ?? '';
          const episodeId = this.currentContent.id?.toString() ?? '';
          this.updateEpisode(contentType, id, seasonId, episodeId);
        } else {
          this.contentService.updateContent(contentType, id, formData).pipe(
            switchMap((response: any) => this.handleContentUpdateResponse(response, contentType, id)),
            catchError((error: HttpErrorResponse) => {
              this.handleUpdateError(error);
              return of(null);
            }),
            finalize(() => {
              this.isUploading = false;
            })).subscribe((response) => {
              if (response?.success) {
                this.handleUpdateSuccess('Content updated successfully!');
              } else if (!this.modalMessage) {
                this.handleUpdateError(new HttpErrorResponse({ error: 'Upload failed. Please try again.' }));
              }
            });
        }
      } else {
        console.error("Current content is null or missing contentId");
        this.isUploading = false;
      }
    }
  }

  private updateEpisode(contentType: string, contentId: string, seasonId: string | null, episodeId: string): void {
    if (this.currentContent && this.editForm.valid) {
      this.isUploading = true;
      const formData = this.createFormData();
      const episodeContentType = this.getContentTypeForEpisode(this.currentContent);

      this.contentService.updateEpisode(episodeContentType, contentId, seasonId, episodeId, formData).pipe(
        switchMap((response: any) => this.handleEpisodeUpdateResponse(response, episodeContentType)),
        catchError((error: HttpErrorResponse) => {
          console.error('Error during updateEpisode:', error);
          this.handleUpdateError(error);
          return of(null);
        }),
        finalize(() => {
          this.isUploading = false;
        })
      ).subscribe((response) => {
        if (response?.success) {
          this.handleUpdateSuccess('Episode updated successfully!');
        } else if (!this.modalMessage) {
          this.handleUpdateError(new HttpErrorResponse({ error: 'Upload failed. Please try again.' }));
        }
      });
    } else {
      console.error('Current content or editForm is invalid when updating episode');
      this.isUploading = false;
    }
  }

  updateRSSFeed(): void {
    if (this.editForm.valid) {
      const feedId = this.currentContent?.contentId?.toString();
      const url = this.editForm.get('rssUrl')?.value;
      const contentTheme = this.editForm.get('rssContentTheme')?.value;

      if (feedId && url && contentTheme) {
        this.isUploading = true;
        this.rssFeedService.updateRSSFeed(feedId, { url, contentTheme }).subscribe({
          next: () => {
            this.fetchAllContent(); // Refresh the content list after update
            this.isUploading = false; // Stop uploading
            this.handleUpdateSuccess('RSS feed updated successfully!');
          },
          error: (error: HttpErrorResponse) => {
            this.handleUpdateError(error);
          }
        });
      } else {
        this.isUploading = false;
      }
    } else {
      this.isUploading = false;
    }
  }
  
  private handleContentUpdateResponse(response: any, contentType: string, id: string) {
    if (response?.success) {
      const signedContentUrl = response.signedContentUrl;
      const signedImageUrl = response.signedImageUrl;

      if (!signedContentUrl || !signedImageUrl) {
        throw new Error('Signed URLs are missing');
      }

      const uploadContentHeaders = new HttpHeaders({ 'Content-Type': this.selectedFile?.type ?? '' });
      const uploadImageHeaders = new HttpHeaders({ 'Content-Type': this.selectedImageFile?.type ?? '' });

      const uploadContent$ = this.http.put(signedContentUrl, this.selectedFile, { headers: uploadContentHeaders }).pipe(
        catchError(this.handleError)
      );

      const uploadImage$ = this.http.put(signedImageUrl, this.selectedImageFile, { headers: uploadImageHeaders }).pipe(
        catchError(this.handleError)
      );

      return forkJoin([uploadContent$, uploadImage$]).pipe(
        switchMap(() => {
          const contentUrl = response.content.movieUrl || response.content.podcastUrl || response.content.showUrl;
          if (!contentUrl) {
            throw new Error('No valid content URL found');
          }
          return this.contentService.updateContentUrls(contentType, id, contentUrl, response.content.image);
        }),
        switchMap(() => of(response))
      );
    } else {
      throw new Error('Failed to get signed URLs');
    }
  }

  private handleEpisodeUpdateResponse(response: any, episodeContentType: string) {
    if (response?.success) {
      const signedContentUrl = response.signedContentUrl;
      const signedImageUrl = response.signedImageUrl;
      
      if (!signedContentUrl || !signedImageUrl) {
        throw new Error('Signed URLs are missing');
      }

      const uploadContentHeaders = new HttpHeaders({ 'Content-Type': this.selectedFile?.type ?? '' });
      const uploadImageHeaders = new HttpHeaders({ 'Content-Type': this.selectedImageFile?.type ?? '' });

      const uploadContent$ = this.http.put(signedContentUrl, this.selectedFile, { headers: uploadContentHeaders }).pipe(
        catchError(this.handleError.bind(this))
      );
      
      const uploadImage$ = this.http.put(signedImageUrl, this.selectedImageFile, { headers: uploadImageHeaders }).pipe(
        catchError(this.handleError.bind(this))
      );
      
      return forkJoin([uploadContent$, uploadImage$]).pipe(
        switchMap(() => {
          const contentUrl = response.content.showEpisodeUrl || response.content.podcastEpisodeUrl;
          if (!contentUrl) {
            throw new Error('No valid content URL found');
          }
          return this.contentService.updateEpisodeUrls(episodeContentType, response.content.id, contentUrl, response.content.image);
        }),
        switchMap(() => of(response))
      );
    } else {
      throw new Error('Failed to get signed URLs');
    }
  }
  
  private getContentType(content: ContentItem): string {
    if (content.movieUrl) return 'movies';
    if (content.podcastUrl) return 'podcasts';
    if (content.showUrl) return 'shows';
    return '';
  }

  private getContentTypeForEpisode(content: ContentItem): string {
    if (content.podcastEpisodeUrl) return 'podcasts';
    if (content.showEpisodeUrl) return 'shows';
    return '';
  }

  private createFormData(): FormData {
    const formData = new FormData();
    formData.append('title', this.editForm.get('title')?.value);
    formData.append('description', this.editForm.get('description')?.value);
    formData.append('image', this.editForm.get('image')?.value);
    formData.append('url', this.editForm.get('url')?.value);
    formData.append('releaseDate', this.editForm.get('releaseDate')?.value);
    formData.append('genre', this.editForm.get('genre')?.value);
    formData.append('contentTheme', this.editForm.get('contentTheme')?.value);
    formData.append('duration', this.editForm.get('duration')?.value);
    formData.append('director', this.editForm.get('director')?.value);

    if (this.currentContent?.movieUrl) {
      // Do not append author and producer for movies
    } else if (this.currentContent?.podcastUrl) {
      formData.append('producer', this.editForm.get('producer')?.value);
    } else if (this.currentContent?.showUrl) {
      formData.append('producer', this.editForm.get('producer')?.value);
    } else {
      formData.append('author', this.editForm.get('author')?.value);
      formData.append('producer', this.editForm.get('producer')?.value);
    }

    if (this.selectedFile) {
      formData.append('videoFileType', this.selectedFile.type);
    }

    if (this.selectedImageFile) {
      formData.append('imageFileType', this.selectedImageFile.type);
    }

    return formData;
  }

  private handleUpdateSuccess(message: string): void {
    this.fetchAllContent();
    this.currentContent = null;
    this.editForm.reset();
    this.selectedFile = null;
    this.selectedImageFile = null;
    this.modalMessage = message;
    this.isSuccess = true;
    this.isError = false;
    this.isUploading = false; // Stop uploading
  }

  private handleUpdateError(error: HttpErrorResponse): void {
    switch (error.status) {
      case 400:
        this.modalMessage = 'Bad Request: Please check the form data.';
        break;
      case 401:
        this.modalMessage = 'Unauthorized: Please log in.';
        break;
      case 403:
        this.modalMessage = 'Forbidden: You do not have permission to perform this action.';
        break;
      case 404:
        this.modalMessage = 'Not Found: The content you are trying to update does not exist.';
        break;
      case 500:
        this.modalMessage = 'Internal Server Error: Please try again later.';
        break;
      default:
        this.modalMessage = `Error: ${error.message}`;
    }
    this.isError = true;
    this.isSuccess = false;
    this.isUploading = false; // Stop uploading
  }

  deleteContent(contentType: string, id: string): void {
    this.isLoading = true; // Start loading
    this.contentService.deleteContent(contentType, id).subscribe({
      next: () => {
        this.fetchAllContent(); // Refresh the content list after deletion
        this.isLoading = false; // Stop loading
      },
      error: (error: HttpErrorResponse) => {
        this.handleDeleteError(error);
      }
    });
  }

  deleteEpisode(contentType: string, seasonId: string | null, episodeId: string): void {
    this.isLoading = true; // Start loading
    this.contentService.deleteEpisode(contentType, seasonId, episodeId).subscribe({
      next: () => {
        this.fetchAllContent(); // Refresh the content list after deletion
        this.isLoading = false; // Stop loading
      },
      error: (error: HttpErrorResponse) => {
        this.handleDeleteError(error);
      }
    });
  }

  deleteRSSFeed(feedId: string): void {
    this.isLoading = true; // Start loading
    this.rssFeedService.deleteRSSFeed(feedId).subscribe({
      next: () => {
        this.fetchAllContent(); // Refresh the content list after deletion
        this.isLoading = false; // Stop loading
      },
      error: (error: HttpErrorResponse) => {
        this.handleDeleteError(error);
      }
    });
  }

  editRSSFeed(feedId: string, url: string): void {
    this.isEditingRSSFeed = true;
    const content = this.findContentById('rssFeeds', feedId);
    if (content) {
      this.currentContent = content;
      this.patchEditForm(content, 'rssFeeds');
      this.openModal();
    }
  }

  private handleDeleteError(error: HttpErrorResponse): void {
    switch (error.status) {
      case 400:
        this.errorMessage = 'Bad Request: Please check the request data.';
        break;
      case 401:
        this.errorMessage = 'Unauthorized: Please log in.';
        break;
      case 403:
        this.errorMessage = 'Forbidden: You do not have permission to perform this action.';
        break;
      case 404:
        this.errorMessage = 'Not Found: The content you are trying to delete does not exist.';
        break;
      case 500:
        this.errorMessage = 'Internal Server Error: Please try again later.';
        break;
      default:
        this.errorMessage = `Error: ${error.message}`;
    }
    this.isLoading = false; // Stop loading
  }

  private findContentById(contentType: string, id: string, seasonId: string | null = null, episodeId: string | null = null): ContentItem | null {
    if (!this.allContent) return null;

    switch (contentType) {
      case 'movies':
        return this.allContent.movies.data.find(item => item.id.toString() === id) || null;
      case 'podcasts':
        return this.allContent.podcasts.data.find(item => item.id.toString() === id) || null;
      case 'shows':
        return this.allContent.shows.data.find(item => item.id.toString() === id) || null;
      case 'episodes':
        return this.findEpisodeById(contentType, id, seasonId ?? '', episodeId ?? '');
      case 'rssFeeds':
        return this.allContent.rssFeeds.data.find(item => item.contentId?.toString() === id) || null;
      default:
        return null;
    }
  }

  private findEpisodeById(contentType: string, contentId: string, seasonId: string | null, episodeId: string): ContentItem | null {
    if (!this.allContent) {
        return null;
    }

    switch (contentType) {
        case 'podcasts':
            return this.findPodcastEpisode(contentId, episodeId);
        case 'shows':
            return this.findShowEpisode(contentId, seasonId, episodeId);
        default:
            return null;
    }
}

private findPodcastEpisode(contentId: string, episodeId: string): ContentItem | null {
    if (!this.allContent?.podcasts?.data) {
        return null;
    }

    for (const podcast of this.allContent.podcasts.data) {
        if (podcast.contentId.toString() === contentId) {
            const episode = podcast.episodes.find(ep => ep.id.toString() === episodeId);
            if (episode) {
                return episode;
            }
        }
    }
    return null;
}

private findShowEpisode(contentId: string, seasonId: string | null, episodeId: string): ContentItem | null {
    const showsData = this.allContent?.shows?.data;
    if (!showsData) return null;

    for (const show of showsData) {
        if (show.contentId.toString() === contentId) {
            return this.findSeasonEpisode(show, seasonId, episodeId);
        }
    }

    return null;
}

private findSeasonEpisode(show: any, seasonId: string | null, episodeId: string): ContentItem | null {
    if (!show?.seasons) {
        return null;
    }

    for (const season of show.seasons) {
        if (season.id.toString() === seasonId) {
            const episode = season.episodes.find((ep: ContentItem) => ep.id.toString() === episodeId);
            if (episode) {
                return episode;
            }
        }
    }
    return null;
}
  
  private handleError(error: HttpErrorResponse): any {
  this.modalMessage = error.error?.message || 'An error occurred while uploading.';
  this.isError = true;
  this.isSuccess = false;
  return throwError(() => new Error(this.modalMessage!)); // Add non-null assertion operator
}

  openModal(): void {
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.modalMessage = null; // Clear modal message when closing
    this.isError = false; // Reset error state
    this.isSuccess = false; // Reset success state
    this.isEditingRSSFeed = false; // Reset RSS feed editing state
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeModal();
    }
  }

  handleKeyPress(event: KeyboardEvent): void {
    // Handle key press events if needed
  }

  handleKeyUp(event: KeyboardEvent): void {
    // Handle key up events if needed
  }
}