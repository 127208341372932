import { Component } from '@angular/core';
import { ContentService } from '../services/content.service';
import { ActivatedRoute } from '@angular/router';
import { Podcast } from 'src/interfaces/content';
import { ToggleDescriptionService } from '../services/toggle-description.service';
import { IsPaidService } from '../services/is-paid.service';
@Component({
  selector: 'app-podcasts',
  templateUrl: './podcasts.component.html',
  styleUrls: ['./podcasts.component.scss']
})
export class PodcastsComponent {
  id!: string;
  podcast!: any;
  podcastData!: {key:string, data:any}
  audioElement!: HTMLAudioElement;
  playbackPercent!: string;
  isPaid!:boolean;

  constructor(private contentService:ContentService,
    private route:ActivatedRoute,
    public toggleDesc: ToggleDescriptionService,
    private isPaidService: IsPaidService){
    this.audioElement = new Audio();
    this.audioElement.addEventListener('timeupdate', () => {
      this.playbackPercent = (this.audioElement.currentTime / this.audioElement.duration) * 100 + '%';
    });
  }
  async ngOnInit(){
    this.getPodcastEpisodes();
    this.isPaid = this.isPaidService.getPaidStatus();
  }
  encodeUrl(url:string) : string{
    return url.replace(/ /g, "%20");
  }

  playAudio(audioUrl: string) {
    this.audioElement.src = audioUrl;
    this.audioElement.play();
  }

  getPodcastEpisodes(){
    this.id = this.route.snapshot.paramMap.get('id') ?? "";
    this.contentService.podcast(this.id).subscribe(
      response => {
        this.podcast = response.data[0];
        this.podcastData = {key:'podcasts', data:this.podcast}
      }
    );
    }


}
