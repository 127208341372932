import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Media } from 'src/interfaces/player';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent {
  currentIndex: number;
  media: Media;

  constructor(
    public dialogRef: MatDialogRef<VideoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { media: Media, playlist: Media[], currentIndex: number }
  ) {
    this.currentIndex = data.currentIndex;
    this.media = data.media;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  playNext(): void {
    if (this.currentIndex < this.data.playlist.length - 1) {
      this.currentIndex++;
      this.media = this.data.playlist[this.currentIndex];
    }
  }

  playPrevious(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.media = this.data.playlist[this.currentIndex];
    }
  }
}