<div class="carousel-container">
  <app-header></app-header>

  <ng-container *ngIf="contentData?.data; else noContent">
    <div class="image-container">
      <img class="img-fluid" [src]="contentData.data.image" alt="{{ contentData.data.title }}" />

      <div class="overlay">
        <h1>{{ contentData.data.title }}</h1>
        <div class="desc">{{ contentData.data.description }}</div>

        <div class="metadata">
          <button *ngIf="contentData.data.movieMetadata?.director || contentData.data.showMetadata?.director || contentData.data.podcastMetadata?.author" class="btn btn-sm rounded-pill">
            {{ contentData.data.movieMetadata?.director || contentData.data.showMetadata?.director || contentData.data.podcastMetadata?.author }}
          </button>
          <button *ngIf="contentData.data.movieMetadata?.releaseDate || contentData.data.showMetadata?.releaseDate || contentData.data.podcastMetadata?.releaseDate" class="btn btn-sm rounded-pill">
            {{ (contentData.data.movieMetadata?.releaseDate || contentData.data.showMetadata?.releaseDate || contentData.data.podcastMetadata?.releaseDate) | date:'fullDate' }}
          </button>
          <button *ngIf="contentData.data.movieMetadata?.duration || contentData.data.showMetadata?.duration || contentData.data.podcastMetadata?.duration" class="btn btn-sm rounded-pill">
            {{ contentData.data.movieMetadata?.duration || contentData.data.showMetadata?.duration || contentData.data.podcastMetadata?.duration | duration }}
          </button>
          <button *ngIf="contentData.data.movieMetadata?.genre || contentData.data.showMetadata?.genre || contentData.data.podcastMetadata?.genre" class="btn btn-sm rounded-pill">
            {{ contentData.data.movieMetadata?.genre || contentData.data.showMetadata?.genre || contentData.data.podcastMetadata?.genre }}
          </button>
        </div>

        <div class="button-group">
          <app-play-button [contentData]="contentData" (play)="onPlay($event)"></app-play-button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #noContent>
    <div class="image-container">
      <div class="overlay">
        <h1>Welcome Onboard!</h1>
        <div class="desc">Stay tuned for exciting content coming your way!</div>

        <div class="metadata">
          <button class="btn btn-sm rounded-pill">Director: N/A</button>
          <button class="btn btn-sm rounded-pill">Release Date: N/A</button>
          <button class="btn btn-sm rounded-pill">Duration: N/A</button>
          <button class="btn btn-sm rounded-pill">Genre: N/A</button>
        </div>

        <div class="button-group">
          <!-- You might want to disable this button or show a message for no content -->
          <app-play-button [disabled]="true"></app-play-button>
        </div>
      </div>
    </div>
  </ng-template>
</div>