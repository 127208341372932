import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import Plyr from 'plyr';
import Hls from 'hls.js';
import { Media } from 'src/interfaces/player';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AudioPlayerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() media: Media | undefined;
  @Input() playlist: Media[] = [];
  @ViewChild('audioRef') audioRef!: ElementRef<HTMLAudioElement>;
  player!: Plyr;
  private hls!: Hls;
  private currentIndex: number = 0;
  private playerInitialized: boolean = false;
  private previousMediaSrc: string | undefined;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.media) {
      console.error('No media input provided');
    }
  }

  ngAfterViewInit() {
    if (this.media && !this.playerInitialized) {
      this.initializePlayer();
      this.playerInitialized = true;
      this.previousMediaSrc = this.media.src;
    }

    this.audioRef.nativeElement.addEventListener('click', this.togglePlayPause.bind(this));
  }

  ngOnDestroy() {
    this.cleanupPlayer();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['media'] && !changes['media'].firstChange) {
      if (this.media && this.media.src !== this.previousMediaSrc) {
        this.initializePlayer();
        this.previousMediaSrc = this.media.src;
      }
    }
  }

  private initializePlayer() {
    const audioElement = this.audioRef?.nativeElement;
    if (!audioElement) {
      console.error('No audio element found');
      return;
    }

    if (this.media?.src.endsWith('.m3u8')) {
      this.setupHls(audioElement);
    } else {
      this.setupNonHls(audioElement);
    }
    this.setupPlyr(audioElement);
    this.addEventListeners(audioElement);
    this.cdr.markForCheck();
  }

  private setupPlyr(audioElement: HTMLAudioElement) {
    const options: Plyr.Options = {
      captions: { active: true, update: true, language: 'auto' },
      autoplay: true,
      controls: [
        'play-large', 'restart', 'rewind', 'play', 'fast-forward', 'progress',
        'current-time', 'duration', 'mute', 'volume', 'settings'
      ],
      settings: ['speed'],
      speed: {
        selected: 1,
        options: [0.5, 1, 1.5]
      }
    };

    this.player = new Plyr(audioElement, options);
    this.player.on('ended', () => this.handleAudioEnd());
  }

  private setupHls(audioElement: HTMLAudioElement) {
    if (Hls.isSupported()) {
      this.hls = new Hls({
        capLevelToPlayerSize: true,
        autoStartLoad: true,
        startLevel: -1,
        enableWorker: true
      });

      if (this.media?.src) {
        this.hls.loadSource(this.media.src);
      } else {
        console.error('Invalid media source');
      }

      this.hls.attachMedia(audioElement);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.playAudio();
      });
    } else if (audioElement.canPlayType('application/vnd.apple.mpegurl')) {
      if (this.media?.src) {
        audioElement.src = this.media.src;
      } else {
        console.error('Invalid media source');
      }
      audioElement.addEventListener('loadedmetadata', () => this.playAudio());
    } else {
      console.error('HLS is not supported in this browser');
    }
  }

  private setupNonHls(audioElement: HTMLAudioElement) {
    if (this.media?.src) {
      audioElement.src = this.media.src;
    } else {
      console.error('Invalid media source');
    }
    audioElement.addEventListener('loadedmetadata', () => this.playAudio());
  }

  private addEventListeners(audioElement: HTMLAudioElement) {
    audioElement.addEventListener('click', () => {
      if (audioElement.paused) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    });
  }

  private handleAudioEnd() {
    this.playNext();
  }

  private playAudio() {
    const audioElement = this.audioRef?.nativeElement;
    if (!audioElement) return;

    const playPromise = audioElement.play();
    if (playPromise !== undefined) {
      playPromise.catch(error => console.error('Error starting playback:', error));
    }
  }

  private cleanupPlayer() {
    if (this.hls) {
      this.hls.destroy();
    }
    if (this.player) {
      this.player.destroy();
    }
  }

  private togglePlayPause() {
    if (this.audioRef.nativeElement.paused) {
      this.audioRef.nativeElement.play();
    } else {
      this.audioRef.nativeElement.pause();
    }
  }

  playNext() {
    this.currentIndex = (this.currentIndex + 1) % this.playlist.length;
    this.media = this.playlist[this.currentIndex];
    this.initializePlayer();
  }

  playPrevious() {
    this.currentIndex = (this.currentIndex - 1 + this.playlist.length) % this.playlist.length;
    this.media = this.playlist[this.currentIndex];
    this.initializePlayer();
  }
}