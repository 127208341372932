import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupComponent } from './signup/signup.component';

import { TruncatePipe } from './pipes/truncate.pipe';
import { SearchService } from './services/search.service';

import { MatModules } from './matModules';
import { mdbModules } from './mdbModules';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { SigninComponent } from './signin/signin.component';
import { ContentComponent } from './content/content.component';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { GoogleButtonComponent } from './components/google-button/google-button.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { FooterComponent } from './components/footer/footer.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { PlayerComponent } from './player/player.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeComponent } from './home/home.component';
import { SplashComponent } from './splash/splash.component';
import { ActiveBtnComponent } from './components/active-btn/active-btn.component';
import { InactiveBtnComponent } from './components/inactive-btn/inactive-btn.component';
import { SplashCardComponent } from './components/splash-card/splash-card.component';
import { ColoredLogoComponent } from './components/colored-logo/colored-logo.component';

import { PodcastsComponent } from './podcasts/podcasts.component';
import { LightLogoComponent } from './components/light-logo/light-logo.component';
// import { PayComponent } from './pay/pay.component';
import { HeaderComponent } from './components/header/header.component';
import { SeriesComponent } from './series/series.component';
import { MoviesComponent } from './movies/movies.component';
import { TopContentComponent } from './components/top-content/top-content.component';
import { FeedsComponent } from './feeds/feeds.component';
import { SliderComponent } from './components/slider/slider.component';
import { ThemesComponent } from './themes/themes.component';
import { ContentDescriptionComponent } from './components/content-description/content-description.component';
import { OptionButtonsComponent } from './components/option-buttons/option-buttons.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsNConditionsComponent } from './terms-n-conditions/terms-n-conditions.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { IonicModule } from '@ionic/angular';
import { DashboardComponent } from './admin/views/dashboard/dashboard.component';
import { UploadsComponent } from './admin/views/uploads/uploads.component';
import { AdminComponent } from './admin/admin.component';
import { MoviesUploadsComponent } from './components/movies-uploads/movies-uploads.component';
import { PodcastDetailsUploadsComponent } from './components/podcast-details-uploads/podcast-details-uploads.component';
import { PodcastEpisodesUploadsComponent } from './components/podcast-episodes-uploads/podcast-episodes-uploads.component';
import { ShowDetailsUploadsComponent } from './components/show-details-uploads/show-details-uploads.component';
import { SeasonDetailsUploadsComponent } from './components/season-details-uploads/season-details-uploads.component';
import { ShowEpisodesUploadsComponent } from './components/show-episodes-uploads/show-episodes-uploads.component';
import { PodcastUploadsComponent } from './components/podcast-uploads/podcast-uploads.component';
import { ShowUploadsComponent } from './components/show-uploads/show-uploads.component';
import { MovieMetadataComponent } from './components/movie-metadata/movie-metadata.component';
import { PodcastDetailsMetadataComponent } from './components/podcast-details-metadata/podcast-details-metadata.component';
import { ShowDetailsMetadataComponent } from './components/show-details-metadata/show-details-metadata.component';
import { FeedDetailsUploadComponent } from './components/feed-details-upload/feed-details-upload.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { PlayButtonComponent } from './components/play-button/play-button.component';
import { MoreInfoButtonComponent } from './components/more-info-button/more-info-button.component';
import { ViewPlansButtonComponent } from './components/view-plans-button/view-plans-button.component';
import { InfoButtonComponent } from './components/info-button/info-button.component';
import { ViewPlansComponent } from './components/view-plans/view-plans.component';
import { FilterComponent } from './filter/filter.component';
import { ProfileComponent } from './profile/profile.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MovieDetailComponent } from './movie-detail/movie-detail.component';
import { PodcastDetailComponent } from './podcast-detail/podcast-detail.component';
import { ShowDetailComponent } from './show-detail/show-detail.component';
import { DurationPipe } from './pipes/duration.pipe';

import { NgxSpinnerModule } from 'ngx-spinner';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { contentReducer } from './store/reducers/content.reducers';
import { subscriptionReducer } from './store/reducers/subscription.reducer';
import { ContentEffects } from './store/effects/content.effects';
import { SubscriptionEffects } from './store/effects/subscription.effects';
import { sessionStorageMetaReducer } from './store/reducers/session-storage.reducer';
// import { localStorageMetaReducer } from './store/reducers/local-storage.reducer';
import { FamilyComponent } from './themes/family/family.component';
import { KidsComponent } from './themes/kids/kids.component';
import { LifestyleComponent } from './themes/lifestyle/lifestyle.component';
import { AnimationsComponent } from './themes/animations/animations.component';
import { VideoModalComponent } from './components/video-modal/video-modal.component';
import { SubscriptionModalComponent } from './components/subscription-modal/subscription-modal.component';
import { SubscriptionDetailsComponent } from './components/subscription-details/subscription-details.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { PaymentPlansComponent } from './admin/views/payment-plans/payment-plans.component';
import { AdminContentComponent } from './admin/views/admin-content/admin-content.component';
import { AdminSignupComponent } from './admin/views/admin-signup/admin-signup.component';
import { AdminAuthGuard } from './admin/adminAuthGuard';
import { PaymentErrorComponent } from './components/payment-error/payment-error.component';
import { TopcontentVideoModalComponent } from './components/topcontent-video-modal/topcontent-video-modal.component';
import { GoogleCallbackComponent } from './components/google-callback/google-callback.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RssFeedDetailComponent } from './rssfeed-detail/rssfeed-detail.component';
import { FaqComponent } from './components/faq/faq.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { VerifyRssFeedComponent } from './components/verify-rss-feed/verify-rss-feed.component';
import { SafeHtmlPipe } from './safe-html.pipe';
// import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SigninComponent,
    ContentComponent,
    GoogleButtonComponent,
    EmailConfirmationComponent,
    FooterComponent,
    VideoPlayerComponent,
    PlayerComponent,
    HomeComponent,
    SplashComponent,
    ActiveBtnComponent,
    InactiveBtnComponent,
    SplashCardComponent,
    ColoredLogoComponent,
    TruncatePipe,
    PodcastsComponent,
    LightLogoComponent,
    // PayComponent,
    HeaderComponent,
    SeriesComponent,
    MoviesComponent,
    TopContentComponent,
    FeedsComponent,
    SliderComponent,
    ThemesComponent,
    ContentDescriptionComponent,
    OptionButtonsComponent,
    ResetPasswordComponent,
    PrivacyComponent,
    TermsNConditionsComponent,
    PagenotfoundComponent,
    UpdatePasswordComponent,
    DashboardComponent,
    UploadsComponent,
    AdminComponent,
    MoviesUploadsComponent,
    PodcastDetailsUploadsComponent,
    PodcastEpisodesUploadsComponent,
    ShowDetailsUploadsComponent,
    SeasonDetailsUploadsComponent,
    ShowEpisodesUploadsComponent,
    PodcastUploadsComponent,
    ShowUploadsComponent,
    MovieMetadataComponent,
    PodcastDetailsMetadataComponent,
    ShowDetailsMetadataComponent,
    FeedDetailsUploadComponent,
    AudioPlayerComponent,
    PlayButtonComponent,
    MoreInfoButtonComponent,
    ViewPlansButtonComponent,
    InfoButtonComponent,
    ViewPlansComponent,
    FilterComponent,
    ProfileComponent,
    OverlayComponent,
    SpinnerComponent,
    MovieDetailComponent,
    PodcastDetailComponent,
    ShowDetailComponent,
    DurationPipe,
    FamilyComponent,
    KidsComponent,
    LifestyleComponent,
    AnimationsComponent,
    VideoModalComponent,
    SubscriptionModalComponent,
    SubscriptionDetailsComponent,
    AuthCallbackComponent,
    EmailVerificationComponent,
    OtpVerificationComponent,
    PaymentPlansComponent,
    AdminContentComponent,
    AdminSignupComponent,
    PaymentErrorComponent,
    TopcontentVideoModalComponent,
    GoogleCallbackComponent,
    RssFeedDetailComponent,
    FaqComponent,
    AboutUsComponent,
    ContactUsComponent,
    VerifyRssFeedComponent,
    SafeHtmlPipe,
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
    BrowserAnimationsModule,
    MatModules,
    mdbModules,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    IonicModule.forRoot(),
    StoreModule.forRoot({
      content: contentReducer, 
      subscription: subscriptionReducer
    }, {
      metaReducers: [sessionStorageMetaReducer]
    }),
    EffectsModule.forRoot([ContentEffects, SubscriptionEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [SearchService, AdminAuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}