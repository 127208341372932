<div class="contact-container">
  <div class="back-button" (click)="goBack()">
    <span class="back-icon">&#8592;</span> Back
  </div>
  <h1>Contact Us</h1>
  <p>If you have any questions or feedback, please fill out the form below to get in touch with our team.</p>
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="name">Name</label>
      <input id="name" formControlName="name" type="text" placeholder="Your Name">
      <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched" class="error">
        Name is required.
      </div>
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input id="email" formControlName="email" type="email" placeholder="Your Email">
      <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched" class="error">
        Valid email is required.
      </div>
    </div>
    <div class="form-group">
      <label for="message">Message</label>
      <textarea id="message" formControlName="message" rows="5" placeholder="Your Message"></textarea>
      <div *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.touched" class="error">
        Message is required.
      </div>
    </div>
    <button type="submit" [disabled]="contactForm.invalid">Submit</button>
  </form>
  <div *ngIf="submissionSuccess" class="success-message">
    Your message has been sent successfully!
  </div>
  <div *ngIf="submissionError" class="error-message">
    There was an error sending your message. Please try again later.
  </div>
  <section class="contact-info">
    <h2>Other Ways to Reach Us</h2>
    <p>You can also reach us through the following methods:</p>
    <ul>
      <li>Email: info&#64;nwplus.biz</li>
      <li>Phone: 020 4403813</li>
      <!-- <li>Address: 123 NWPLUS TV Street, Entertainment City, EC 12345</li> -->
    </ul>
  </section>
</div>