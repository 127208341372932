import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContentService } from 'src/app/services/content.service';
import { catchError, finalize, of } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-season-details-uploads',
  templateUrl: './season-details-uploads.component.html',
  styleUrls: ['./season-details-uploads.component.scss'],
  animations: [
    trigger('uploadAnimation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('300ms')),
      transition(':leave', animate('300ms')),
    ]),
  ],
})
export class SeasonDetailsUploadsComponent implements OnInit {
  @ViewChild('imageInput') imageInput!: ElementRef;

  seasonDetailsForm!: FormGroup;
  showUploadAnimation = false;
  showUploadSuccessAnimation = false;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  showNextUploadPrompt = false;
  showForm = true;
  message = '';
  submitted = false;
  shows: any[] = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly contentService: ContentService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.seasonDetailsForm = this.formBuilder.group({
      showTitle: ['', Validators.required],
      seasonTitle: ['', [Validators.required, Validators.maxLength(255)]],
      seasonNo: ['', [Validators.required, Validators.min(1), Validators.max(100)]],
      desc: ['', [Validators.required, Validators.maxLength(500)]],
      image: ['', [Validators.required, Validators.pattern('.+\\.(jpg|jpeg|png)$')]]
    });

    this.loadShows();
  }

  loadShows(): void {
    this.contentService.getContentByType('shows').subscribe({
      next: (response) => this.shows = response.data,
      error: (error) => this.message = 'Failed to load shows'
    });
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.seasonDetailsForm.invalid) {
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
      this.cdr.detectChanges();
      setTimeout(() => {
        this.showSaveErrorAnimation = false;
        this.cdr.detectChanges();
      }, 5000);
      return;
    }

    const formData = new FormData();
    this.showUploadAnimation = true;
    formData.append('title', this.seasonDetailsForm.get('seasonTitle')?.value || '');
    formData.append('number', this.seasonDetailsForm.get('seasonNo')?.value || '');
    formData.append('description', this.seasonDetailsForm.get('desc')?.value || '');

    const imageFile = this.imageInput.nativeElement.files[0];
    if (imageFile) formData.append('imageFile', imageFile);

    const showId = this.seasonDetailsForm.get('showTitle')?.value;
    this.contentService.createSeason(showId, formData).pipe(
      catchError((error: HttpErrorResponse) => {
        this.showSaveErrorAnimation = true;

        const status = error.status;
        const errorMessage = error.error?.message || 'An error occurred while uploading.';

        switch (status) {
          case 400:
            this.message = errorMessage;
            break;
          case 401:
            this.message = 'Unauthorized request';
            break;
          case 403:
            this.message = 'Forbidden request';
            break;
          case 404:
            this.message = 'Resource not found';
            break;
          case 409:
            this.message = 'Conflict. Season with the same name already exists';
            break;
          case 500:
            this.message = 'Internal server error';
            break;
          default:
            this.message = errorMessage;
            break;
        }

        this.cdr.detectChanges();
        setTimeout(() => {
          this.showSaveErrorAnimation = false;
          this.message = '';
          this.cdr.detectChanges();
          this.resetForm();
        }, 5000);

        return of(null);
      }),
      finalize(() => {
        this.showUploadAnimation = false;
        this.cdr.detectChanges();
      })
    ).subscribe((response) => {
      if (response?.success) {
        this.showUploadSuccessAnimation = true;
        this.showSaveSuccessAnimation = true;
        this.message = response.message || 'Upload Successful';
        this.resetForm();
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showUploadSuccessAnimation = false;
          this.showSaveSuccessAnimation = false;
          this.showNextUploadPrompt = true;
          this.cdr.detectChanges();
        }, 5000);
      } else if (!this.message) {
        this.showSaveErrorAnimation = true;
        this.message = 'Upload failed. Please try again.';
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showSaveErrorAnimation = false;
          this.message = '';
          this.cdr.detectChanges();
          this.resetForm();
        }, 5000);
      }
    });
  }

  resetForm(): void {
    this.seasonDetailsForm.reset();
    this.imageInput.nativeElement.value = '';
    this.seasonDetailsForm.markAsPristine();
    this.seasonDetailsForm.markAsUntouched();
    this.submitted = false;
    this.message = '';
    this.showSaveErrorAnimation = false;
  }

  onNextUploadResponse(response: boolean): void {
    this.showNextUploadPrompt = false;
    if (response) {
      this.resetForm();
    } else {
      this.showForm = false;
      this.showUploadAnimation = false;
      this.showUploadSuccessAnimation = false;
      this.showSaveSuccessAnimation = false;
      this.showSaveErrorAnimation = false;
      this.message = '';
    }
  }
}