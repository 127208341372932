import { Injectable } from '@angular/core';
import { NetworkResponse } from '../utils/interfaces';
import { AuthenticationService } from './authentication.service';
import { LOCAL_STORAGE } from '../utils/constants';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private auth: AuthenticationService, private http: HttpClient, private router: Router) { }

  get headers() {
    const token = sessionStorage.getItem(LOCAL_STORAGE.accessToken);
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
  }

  userInfoEndpoint = `${environment.apiBaseUrl}/api/v1/user/info`;
  usersEndpoint = `${environment.apiBaseUrl}/api/users`;
  paymentEndpoint = `${environment.apiBaseUrl}/api/payments`;

  async canActivate(): Promise<boolean | UrlTree> {
    let isAdmin = false;
    let isAuthenticated = await this.auth.canActivate();

    if (isAuthenticated === true) {
      await firstValueFrom(this.http.get(this.userInfoEndpoint, { headers: this.headers }))
        .then(async (result: any) => {
          if (result.code === 200) {
            if (result.data.is_staff && result.data.is_active) {
              isAdmin = true;
            }
          }
        })
        .catch((error) => {
          console.error(error.error);
        });
    }

    return isAdmin ? isAdmin : this.router.parseUrl('/content');
  }

  async getAllUsers(): Promise<NetworkResponse> {
    return await firstValueFrom(this.http.get(this.usersEndpoint, { headers: this.headers }))
      .then((response: any) => {
        if (response.statusCode === 200) {
          const networkResponse: NetworkResponse = {
            Success: {
              code: response.statusCode,
              message: response.message,
              data: response.data
            }
          };
          return networkResponse;
        }
        return {
          Error: {
            code: response.statusCode,
            message: response.message
          }
        };
      })
      .catch((error: HttpErrorResponse) => {
        console.error(error);
        return {
          Error: {
            code: error.status,
            message: error.message
          }
        };
      });
  }

  async getAllUserPaymentInfoStatus(): Promise<NetworkResponse> {
    return await firstValueFrom(this.http.get(`${this.paymentEndpoint}/`, { headers: this.headers }))
      .then((response: any) => {
        if (response.statusCode === 200) {
          const networkResponse: NetworkResponse = {
            Success: {
              code: response.statusCode,
              message: response.message,
              data: response.data
            }
          };
          return networkResponse;
        }
        return {
          Error: {
            code: response.statusCode,
            message: response.message
          }
        };
      })
      .catch((error: HttpErrorResponse) => {
        console.error(error);
        return {
          Error: {
            code: error.status,
            message: error.message
          }
        };
      });
  }
}