import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-podcast-uploads',
  templateUrl: './podcast-uploads.component.html',
  styleUrls: ['./podcast-uploads.component.scss']
})
export class PodcastUploadsComponent {
  podcastDetailsState = false;
  podcastEpisodesState = false;
  podcastMetaDataState = false;

  showPodcastDetails() {
    this.podcastDetailsState = true;
    this.podcastEpisodesState = false;
    this.podcastMetaDataState = false;
  }

  showPodcastEpisodes() {
    this.podcastDetailsState = false;
    this.podcastEpisodesState = true;
    this.podcastMetaDataState = false;
  }

  showPodcastMetaData() {
    this.podcastDetailsState = false;
    this.podcastEpisodesState = false;
    this.podcastMetaDataState = true;
  }

  constructor(private readonly router:Router){

  }
  async ngOninit(): Promise<void>{
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/uploadPodcastDetails') {
          this.podcastDetailsState = true;
          this.podcastEpisodesState = false;
          this.podcastMetaDataState = false;
        } else if (event.url === '/uploadPodcastEpisodes') {
          this.podcastDetailsState = false;
          this.podcastEpisodesState = true;
          this.podcastMetaDataState = false;
        } else if (event.url === '/uploadPodcastMetadata') {
          this.podcastDetailsState = false;
          this.podcastEpisodesState = false;
          this.podcastMetaDataState = true;
        } else {
          this.podcastDetailsState = false;
          this.podcastEpisodesState = false;
          this.podcastMetaDataState = false;
        }
      }
    });
  }
}