import { Component, Inject, AfterViewInit, ElementRef, Renderer2, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Media } from 'src/interfaces/player';

@Component({
  selector: 'app-topcontent-video-modal',
  templateUrl: './topcontent-video-modal.component.html',
  styleUrls: ['./topcontent-video-modal.component.scss']
})
export class TopcontentVideoModalComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<TopcontentVideoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Media,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly ngZone: NgZone
  ) {}

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.adjustDialogSize();
        this.applyCustomStyles();
      }, 0);
    });
  }

  private adjustDialogSize(): void {
    const videoPlayer = this.el.nativeElement.querySelector('app-video-player');
    const dialogContent = this.el.nativeElement.querySelector('.mat-dialog-content');
    
    if (videoPlayer && dialogContent) {
      const videoHeight = videoPlayer.offsetHeight;
      const topMargin = 20; // Added top margin
      const totalHeight = videoHeight + topMargin + 40; // 40px for bottom margin
      
      const screenWidth = window.innerWidth;
      // const dialogWidth = screenWidth <= 480 ? '100vw' : screenWidth <= 768 ? '90vw' : screenWidth <= 1024 ? '80vw' : '70vw';

      let dialogWidth: string;
      if (screenWidth <= 480) {
        dialogWidth = '100vw';
      } else if (screenWidth <= 768) {
        dialogWidth = '90vw';
      } else if (screenWidth <= 1024) {
        dialogWidth = '80vw';
      } else {
        dialogWidth = '70vw';
      }
      
      this.dialogRef.updateSize(dialogWidth, `${totalHeight}px`);
      
      this.renderer.setStyle(dialogContent, 'height', 'auto');
      this.renderer.setStyle(dialogContent, 'max-height', 'none');
      this.renderer.setStyle(dialogContent, 'overflow', 'hidden');
      this.renderer.setStyle(dialogContent, 'margin-top', `${topMargin}px`);
      
      this.renderer.setStyle(videoPlayer, 'width', '100%');
      this.renderer.setStyle(videoPlayer, 'height', '100%');
    } else {
      console.error('Video player or dialog content not found');
    }
  }

  private applyCustomStyles(): void {
    const dialogContainer = this.el.nativeElement.closest('.mat-dialog-container');
    if (dialogContainer) {
      this.renderer.setStyle(dialogContainer, 'background-color', '#4B0082'); // Indigo (deep purple)
      this.renderer.setStyle(dialogContainer, 'position', 'relative');
      this.renderer.setStyle(dialogContainer, 'padding', '0'); // Remove default padding
      this.renderer.setStyle(dialogContainer, 'border-radius', '8px'); // Optional: add rounded corners
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}