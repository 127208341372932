<div class="video-container">
  <video
    #videoRef
    class="plyr"
    controls
    crossorigin
    playsinline
    [poster]="media?.image"
    style="--plyr-color-main: #995bf7"
  ></video>
  <div *ngIf="message" class="quality-message">{{ message }}</div>
</div>