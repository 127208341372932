import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { LOCAL_STORAGE } from 'src/app/utils/constants';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionModalComponent } from 'src/app/components/subscription-modal/subscription-modal.component';
import { PaymentPlanService } from 'src/app/services/payment-plan.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private subscriptionService: SubscriptionService,
    private dialog: MatDialog,
    private paymentPlanService: PaymentPlanService,
    
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = sessionStorage.getItem(LOCAL_STORAGE.accessToken);

    if (!token) {
      this.router.navigate(['/signin']);
      return false;
    }

    interface ExtendedJwtPayload extends JwtPayload {
      id: string;
    }

    const decodedToken = jwtDecode(token) as ExtendedJwtPayload;
    const userId = decodedToken.id;

    return new Promise((resolve) => {
      this.subscriptionService.hasActiveSubscription(userId).subscribe({
        next: (hasActiveSubscription) => {
          if (hasActiveSubscription) {
            resolve(true);
          } else {
            this.paymentPlanService.getAllPlans().subscribe({
              next: (plans) => {
                this.showSubscriptionModal(plans, resolve);
              },
              error: (error) => {
                console.error('Error fetching subscription plans:', error);
                this.showSubscriptionModal([], resolve);
              }
            });
          }
        },
        error: () => {
          this.router.navigate(['/signin']);
          resolve(false);
        }
      });
    });
  }

  private showSubscriptionModal(plans: any[], resolve: (value: boolean) => void): void {
    this.dialog.open(SubscriptionModalComponent, {
      data: { plans: plans }
    }).afterClosed().subscribe(() => {
      resolve(false);
    });
  }
}