import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserModel } from '../models/models';
import { LOCAL_STORAGE } from '../utils/constants';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {
  constructor(
    private readonly auth: AuthenticationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar
  ) {}

  isLoading = false;

  async ngOnInit() {
    // Navigate to content if already authenticated
    const canAuthenticateResult = await this.auth.canActivate();
    if (canAuthenticateResult === true) {
      this.router.navigate(['/content']);
    }

    // Check for Google auth error
    this.route.queryParams.subscribe(params => {
      if (params['error'] === 'auth_failed') {
        this.showErrorMessage('Google authentication failed. Please try again.');
      }
    });
  }

  signUpForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
    ]),
    username: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9]+$/) // Alphanumeric characters only
    ]),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\+\d{1,3}\s?\d{1,13}$/),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  emailControl = this.signUpForm.get('email');
  usernameControl = this.signUpForm.get('username');
  phoneNumberControl = this.signUpForm.get('phoneNumber');
  passwordControl = this.signUpForm.get('password');

  isValidForm: boolean = false;

  networkErrorMessage: string = '';

  // User model for binding form values
  user: UserModel = new UserModel('', '', '', '');

  // Validate email
  getEmailErrorMessage() {
    if (
      this.emailControl?.hasError('required') ||
      this.emailControl?.hasError('email') ||
      this.emailControl?.hasError('pattern')
    )
      return 'Please enter a valid email';
    else return;
  }

  // Validate username
  getUsernameErrorMessage() {
    if (this.usernameControl?.hasError('required'))
      return 'Please enter a username';
    if (this.usernameControl?.hasError('pattern'))
      return 'Username must only contain alphanumeric characters';
    else return;
  }

  // Validate phoneNumber
  getPhoneNumberErrorMessage() {
    if (this.phoneNumberControl?.hasError('required')) {
      return 'Please enter a valid phone number';
    }

    if (this.phoneNumberControl?.hasError('pattern')) {
      return 'Phone number must be in the format +254 1234567890';
    }
    return 'Please enter a valid phone number';
  }

  // Validate password
  getPasswordErrorMessage() {
    if (this.passwordControl?.value.length < 8) {
      return 'Password must be at least 8 characters long';
    }

    if (this.passwordControl?.hasError('required')) {
      return 'Please enter a password';
    }
    return '';
  }

  signUpWithEmailVerification() {
    if (this.signUpForm.valid) {
      this.isLoading = true;
      // Bind form values to the user model
      this.user.email = this.signUpForm.get('email')?.value;
      this.user.username = this.signUpForm.get('username')?.value;
      this.user.phoneNumber = this.signUpForm.get('phoneNumber')?.value;
      this.user.password = this.signUpForm.get('password')?.value;

      this.auth.signUp(
        this.user.email,
        this.user.username ?? '',
        this.user.phoneNumber ?? '',
        this.user.password
      ).subscribe({
        next: (response: any) => {
          if (response.success) {
            sessionStorage.setItem(LOCAL_STORAGE.emailForSignIn, this.user.email);
            this.showSuccessMessage('Signup successful! Please verify your email.');
            // Navigate to verify email page
            this.router.navigate(['/verify-email']);
          } else {
            this.handleErrorResponse(response);
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.handleErrorResponse(error);
          this.isLoading = false;
        },
      });
    }
  }

  handleErrorResponse(error: any) {
    const errorMessage = error?.error?.error || 'Oops! Something went wrong.';
    switch (errorMessage) {
      case 'User with this email already exists':
        this.showErrorMessage('User with this email already exists');
        break;
      case 'User with this username already exists':
        this.showErrorMessage('User with this username already exists');
        break;
      case 'User with this phone number already exists':
        this.showErrorMessage('User with this phone number already exists');
        break;
      case '"username" must only contain alpha-numeric characters':
        this.showErrorMessage('Username should have no spaces or special characters');
        break;
      default:
        this.showErrorMessage(errorMessage);
        break;
    }
  }

  showErrorMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['custom-snack-bar', 'custom-snack-bar-action']
    });
  }

  showSuccessMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['custom-snack-bar-success']
    });
  }

  // Password visibility toggle
  hide = true;
}