import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { clearStore } from 'src/app/store/actions/auth.actions';

export function sessionStorageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === clearStore.type) {
      sessionStorage.removeItem('state');
      sessionStorage.clear();
      return reducer(undefined, action);
    }

    if (action.type === INIT || action.type === UPDATE) {
      const storageValue = sessionStorage.getItem('state');
      if (storageValue) {
        try {
          return JSON.parse(storageValue);
        } catch {
          sessionStorage.removeItem('state');
        }
      }
    }

    const nextState = reducer(state, action);
    sessionStorage.setItem('state', JSON.stringify(nextState));
    return nextState;
  };
}