<div class="kids-content" [class.no-content]="!loading && kidsContent.length === 0">
  <button class="back-button" (click)="navigateBack()">&#8592; Back</button>
  <h1>Kids Content</h1>

  <div *ngIf="loading" class="loading-spinner">
    <p>Loading...</p>
  </div>

  <div *ngIf="!loading && kidsContent.length === 0" class="no-content">
    <p>No kids content available at the moment. Please check back later.</p>
  </div>

  <div *ngIf="!loading && kidsContent.length > 0" class="content-container">
    <div *ngFor="let item of kidsContent" class="content-item">
      <img [src]="item.image" alt="{{ item.title }}" />
      <h2>{{ item.title }}</h2>
      <p>{{ item.description }}</p>
      <button mat-raised-button color="primary" (click)="openVideoPlayer(item)">Play</button>
    </div>
  </div>
</div>