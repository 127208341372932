<div class="dashboard-container">
  <main class="dark">
    <div class="head-title">
      <div class="left">
        <h1>Dashboard</h1>
        <ul class="breadcrumb">
          <li>
            <a routerLink="/admin">Dashboard</a>
          </li>
          <li><i class='bx bx-chevron-right'></i></li>
          <li>
            <a class="active" routerLink="/admin">Home</a>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="loading" class="spinner-container">
        <div style="color: #f4696b" class="la-ball-clip-rotate-pulse la-3x">
            <div></div>
            <div></div>
        </div>
    </div>

    <ul class="box-info" *ngIf="!loading">
      <li>
        <i class='bx bxs-calendar-check'></i>
        <span class="text">
          <h3>{{activeSubscribers.length}}</h3>
          <p>Active Subscribers</p>
        </span>
      </li>
      <li>
        <i class='bx bxs-group'></i>
        <span class="text">
          <h3>{{activeSubscribers.length + inactiveSubscribers.length}}</h3>
          <p>Total Subscribers</p>
        </span>
      </li>
      <li>
        <i class='bx bxs-dollar-circle'></i>
        <span class="text">
          <h3>Ksh. {{totalSales}}</h3>
          <p>Total Sales</p>
        </span>
      </li>
    </ul>

    <div class="table-data" *ngIf="!loading">
      <div class="order">
        <div class="head">
          <h3>Subscriptions</h3>
          <i class='bx bx-search'></i>
          <i class='bx bx-filter'></i>
        </div>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody *ngIf="users.length > 0">
            <tr *ngFor="let subscriber of activeSubscribers">
              <td>
                <img src="../../../../assets/logo.png" alt="logo">
                <p>{{subscriber.username}}</p>
              </td>
              <td>
                <span class="status active">active</span>
              </td>
            </tr>
            <tr *ngFor="let subscriber of inactiveSubscribers">
              <td>
                <img src="../../../../assets/logo.png" alt="logo">
                <p>{{subscriber.username}}</p>
              </td>
              <td>
                <span class="status inactive">inactive</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="todo">
        <div class="head">
          <h3>Subscribers</h3>
          <i class='bx bx-plus'></i>
          <i class='bx bx-filter'></i>
        </div>
        <ul class="todo-list" *ngIf="users.length > 0">
          <li class="active" *ngFor="let subscriber of activeSubscribers">
            <img src="../../../../assets/logo.png" alt="logo" class="profile">
            <span>{{subscriber.username}}</span>
            <i class='bx bx-dots-vertical-rounded'></i>
          </li>
        </ul>
      </div>
    </div>
  </main>
</div>