import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  log(message: string): void {
    if (!this.isProduction()) {
      console.log(message);
    }
  }

  error(message: string): void {
    if (!this.isProduction()) {
      console.error(message);
    }
  }

  private isProduction(): boolean {
    return environment.production;
  }
}