import { Subscription } from "src/interfaces/subscription";

export interface SubscriptionState {
    subscriptions: Subscription[];
    loading: boolean;
    error: string | null;
    subLoaded: boolean;
}

export const initialSubscriptionState: SubscriptionState = {
    subscriptions: [],
    loading: false,
    error: "",
    subLoaded: false
};