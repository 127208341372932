import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss']
})
export class GoogleButtonComponent {
  @Input() buttonText: string = 'Sign in with Google'; // Default text
  @Input() context: string = 'signin'; // Default context

  constructor(
    private readonly router: Router,
    private readonly auth: AuthenticationService
  ) { }
  
  async continueWithGoogle() { 
    this.auth.googleSignIn(this.context);
  }
}