<div class="otp-verification">
  <div class="otp-container">
    <h2>Verify OTP</h2>
    <div *ngIf="errorMessage" class="error-message">
      <div class="error-card">
        <p>{{ errorMessage }}</p>
      </div>
    </div>
    <form [formGroup]="otpForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">Enter Email</label>
        <input id="email" formControlName="email" type="email" class="form-control" placeholder="Enter your email" />
        <div *ngIf="otpForm.get('email')?.invalid && otpForm.get('email')?.touched" class="error">
          <div *ngIf="otpForm.get('email')?.errors?.['required']">Email is required.</div>
          <div *ngIf="otpForm.get('email')?.errors?.['email']">Valid email is required.</div>
        </div>
      </div>
      <div class="form-group">
        <label for="otp">Enter OTP</label>
        <input id="otp" formControlName="otp" type="text" class="form-control" placeholder="Enter your OTP" />
        <div *ngIf="otpForm.get('otp')?.invalid && otpForm.get('otp')?.touched" class="error">
          OTP is required and must be 6 digits.
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="otpForm.invalid || loading">Verify</button>
    </form>
    <div *ngIf="loading" class="loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <button class="btn btn-secondary" (click)="goBack()">Back</button>
</div>