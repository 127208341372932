import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  verificationMessage: string = '';
  isSuccess: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly emailVerificationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token');
    if (token) {
      this.verifyEmail(token);
    } else {
      this.verificationMessage = 'Invalid verification link.';
      this.isSuccess = false;
    }
  }

  verifyEmail(token: string): void {
    this.emailVerificationService.verifyEmail(token).subscribe(
      response => {
        this.verificationMessage = 'Your email has been verified successfully!';
        this.isSuccess = true;
      },
      error => {
        this.verificationMessage = 'Email verification failed. The link may be expired or invalid.';
        this.isSuccess = false;
      }
    );
  }
}