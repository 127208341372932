import { Component } from '@angular/core';
import { FormControl, Validators, ValidationErrors } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    (control: FormControl) => this.noWhitespaceValidator(control)
  ]);
  otpFormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]);
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    (control: FormControl) => this.noWhitespaceValidator(control)
  ]);
  networkErrorMessage: string = '';
  isLoading: boolean = false;
  step: number = 1; // 1: Request OTP, 2: Reset Password
  hidePassword: boolean = true; // For toggling password visibility

  constructor(
    private readonly auth: AuthenticationService,
    private readonly router: Router
  ) {}

  getErrorMessage() {
    if (this.emailFormControl?.hasError('required') || this.emailFormControl?.hasError('email')) {
      return 'Please enter a valid email';
    } else if (this.otpFormControl?.hasError('required') || this.otpFormControl?.hasError('pattern')) {
      return 'Please enter a valid 6-digit OTP';
    } else if (this.passwordFormControl?.hasError('required')) {
      return 'Password is required';
    } else if (this.passwordFormControl?.hasError('minlength')) {
      return 'Password must be at least 8 characters long';
    } else if (this.passwordFormControl?.hasError('noWhitespace')) {
      return 'Password cannot contain whitespace';
    } else {
      return '';
    }
  }

  requestOtp() {
    if (this.emailFormControl.valid) {
      this.isLoading = true;
      this.auth.forgotPassword(this.emailFormControl.value ?? '').subscribe({
        next: (response) => {
          if (response.success) {
            this.step = 2; // Move to the next step
          } else {
            this.networkErrorMessage = response.message || 'Failed to send OTP. Please try again.';
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.networkErrorMessage = this.getBackendErrorMessage(err);
          this.isLoading = false;
        }
      });
    }
  }

  resetPassword() {
    if (this.emailFormControl.valid && this.otpFormControl.valid && this.passwordFormControl.valid) {
      this.isLoading = true;
      const email = this.emailFormControl.value ?? '';
      const otp = this.otpFormControl.value ?? '';
      const newPassword = this.passwordFormControl.value ?? '';

      this.auth.resetPassword(email, otp, newPassword).subscribe({
        next: (response) => {
          if (response.success) {
            this.router.navigate(['/signin']);
          } else {
            this.handleError(response.error);
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.networkErrorMessage = 'Oops! Something went wrong.';
          this.isLoading = false;
        }
      });
    }
  }

  private handleError(error: any) {
    switch (error.message) {
      case 'User not found':
        this.networkErrorMessage = 'User not found';
        break;
      case 'Invalid OTP':
        this.networkErrorMessage = 'Invalid OTP';
        break;
      case 'OTP has expired':
        this.networkErrorMessage = 'OTP has expired';
        break;
      case 'Password cannot be empty':
        this.networkErrorMessage = 'Password cannot be empty';
        break;
      case 'Account marked for deletion':
        this.networkErrorMessage = 'Account marked for deletion';
        break;
      default:
        this.networkErrorMessage = 'Oops! Something went wrong.';
        console.error(error.message);
        break;
    }
  }

  private getBackendErrorMessage(err: any): string {
    if (err.error?.error) {
      if (err.error.error === 'Account marked for deletion') {
        return 'Account marked for deletion';
      }
      return err.error.message;
    }
    return 'Failed to send OTP. Please try again.';
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }

  private noWhitespaceValidator(control: FormControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'noWhitespace': true };
  }
}