import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.scss']
})
export class UploadsComponent {
  showMetadataForm = false;

  uploadItems = [
    { title: 'Movies', link: 'movies' },
    { title: 'Podcasts', link: 'podcasts' },
    { title: 'Shows', link: 'shows' },
    { title: 'Feeds', link: 'RSSFeeds' }
  ];
}