<div class="form-container p-2" *ngIf="showForm">
  <div class="top-title">
    <h4>Episode Details Upload</h4>
  </div>
  <form class="form-content p-2" [formGroup]="showEpisodesForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
    <div class="upload-animation" *ngIf="showUploadAnimation">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
        </svg>
      </div>
    </div>
    <div *ngIf="showUploadSuccessAnimation" class="alert alert-success">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="tick"
                d="M10 18l5 5l10 -10"/>
        </svg>
      </div>
      Upload Successful
    </div>
    <div *ngIf="showSaveSuccessAnimation" class="alert alert-success">
      Data Was Saved Successfully
    </div>
    <div *ngIf="showSaveErrorAnimation" class="alert alert-danger">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="cross"
                d="M12 12l12 12M12 24l12 -12"/>
        </svg>
      </div>
      {{ message }}
    </div>
    <div *ngIf="showNextUploadPrompt" class="alert alert-info">
      <p>Do you want to upload another episode?</p>
      <button type="button" class="btn btn-primary" (click)="onNextUploadResponse(true)">Yes</button>
      <button type="button" class="btn btn-secondary" (click)="onNextUploadResponse(false)">No</button>
    </div>
    <div class="form-row">
      <div class="form-column">
        <div class="p-1 form-group">
          <label for="showTitle">Show Title</label>
          <select id="showTitle" class="form-control" formControlName="showTitle" (change)="fetchSeasons($event)">
            <option *ngFor="let show of shows" [value]="show.contentId">
              {{ show.title | slice:0:40 }}{{ show.title.length > 40 ? '...' : '' }}
            </option>
          </select>
          <div *ngIf="showEpisodesForm.controls['showTitle'].invalid && (showEpisodesForm.controls['showTitle'].dirty || showEpisodesForm.controls['showTitle'].touched || submitted)">
            <div class="formalerts" *ngIf="showEpisodesForm.controls['showTitle'].errors?.['required']">
              {{ getErrorMessage(showEpisodesForm.controls['showTitle']) }}
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="seasonNo">Season Number</label>
          <select id="seasonNo" class="form-control" formControlName="seasonNo">
            <option *ngFor="let season of seasons" [value]="season.id">{{ season.number }}</option>
          </select>
          <div *ngIf="showEpisodesForm.controls['seasonNo'].invalid && (showEpisodesForm.controls['seasonNo'].dirty || showEpisodesForm.controls['seasonNo'].touched || submitted)">
            <div class="formalerts" *ngIf="showEpisodesForm.controls['seasonNo'].errors?.['required']">
              {{ getErrorMessage(showEpisodesForm.controls['seasonNo']) }}
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="episodeTitle">Episode Title</label>
          <input id="episodeTitle" type="text" class="form-control" formControlName="episodeTitle" placeholder="Episode Title">
          <div *ngIf="showEpisodesForm.controls['episodeTitle'].invalid && (showEpisodesForm.controls['episodeTitle'].dirty || showEpisodesForm.controls['episodeTitle'].touched || submitted)">
            <div class="formalerts" *ngIf="showEpisodesForm.controls['episodeTitle'].errors?.['required']">
              {{ getErrorMessage(showEpisodesForm.controls['episodeTitle']) }}
            </div>
            <div class="formalerts" *ngIf="showEpisodesForm.controls['episodeTitle'].errors?.['maxlength']">
              {{ getErrorMessage(showEpisodesForm.controls['episodeTitle']) }}
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="episodeDesc">Episode Description</label>
          <textarea id="episodeDesc" class="form-control" formControlName="episodeDesc" placeholder="Episode Description"></textarea>
          <div *ngIf="showEpisodesForm.controls['episodeDesc'].invalid && (showEpisodesForm.controls['episodeDesc'].dirty || showEpisodesForm.controls['episodeDesc'].touched || submitted)">
            <div class="formalerts" *ngIf="showEpisodesForm.controls['episodeDesc'].errors?.['required']">
              {{ getErrorMessage(showEpisodesForm.controls['episodeDesc']) }}
            </div>
            <div class="formalerts" *ngIf="showEpisodesForm.controls['episodeDesc'].errors?.['maxlength']">
              {{ getErrorMessage(showEpisodesForm.controls['episodeDesc']) }}
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="releaseDate">Release Date</label>
          <input id="releaseDate" type="date" class="form-control" formControlName="releaseDate" placeholder="Release Date">
          <div *ngIf="showEpisodesForm.controls['releaseDate'].invalid && (showEpisodesForm.controls['releaseDate'].dirty || showEpisodesForm.controls['releaseDate'].touched || submitted)">
            <div class="formalerts" *ngIf="showEpisodesForm.controls['releaseDate'].errors?.['required']">
              {{ getErrorMessage(showEpisodesForm.controls['releaseDate']) }}
            </div>
            <div class="formalerts" *ngIf="showEpisodesForm.controls['releaseDate'].errors?.['pastDate']">
              {{ getErrorMessage(showEpisodesForm.controls['releaseDate']) }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="p-1 form-group">
          <label for="duration">Duration</label>
          <input id="duration" type="text" class="form-control" formControlName="duration" placeholder="Duration">
          <div *ngIf="showEpisodesForm.controls['duration'].invalid && (showEpisodesForm.controls['duration'].dirty || showEpisodesForm.controls['duration'].touched || submitted)">
            <div class="formalerts" *ngIf="showEpisodesForm.controls['duration'].errors?.['required']">
              {{ getErrorMessage(showEpisodesForm.controls['duration']) }}
            </div>
            <div class="formalerts" *ngIf="showEpisodesForm.controls['duration'].errors?.['max']">
              {{ getErrorMessage(showEpisodesForm.controls['duration']) }}
            </div>
            <div class="formalerts" *ngIf="showEpisodesForm.controls['duration'].errors?.['min']">
              {{ getErrorMessage(showEpisodesForm.controls['duration']) }}
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="director">Director</label>
          <input id="director" type="text" class="form-control" formControlName="director" placeholder="Director">
          <div *ngIf="showEpisodesForm.controls['director'].invalid && (showEpisodesForm.controls['director'].dirty || showEpisodesForm.controls['director'].touched || submitted)">
            <div class="formalerts" *ngIf="showEpisodesForm.controls['director'].errors?.['required']">
              {{ getErrorMessage(showEpisodesForm.controls['director']) }}
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="imageFile">Image File</label>
          <input id="imageFile" type="file" class="form-control" #imageInput (change)="onFileChange($event)">
          <div *ngIf="showEpisodesForm.controls['imageFile'].invalid && (showEpisodesForm.controls['imageFile'].dirty || showEpisodesForm.controls['imageFile'].touched || submitted)">
            <div class="formalerts" *ngIf="showEpisodesForm.controls['imageFile'].errors?.['required']">
              {{ getErrorMessage(showEpisodesForm.controls['imageFile']) }}
            </div>
            <div class="formalerts" *ngIf="showEpisodesForm.controls['imageFile'].errors?.['pattern']">
              {{ getErrorMessage(showEpisodesForm.controls['imageFile']) }}
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="episode">Episode File</label>
          <input id="episode" type="file" class="form-control" #episodeInput (change)="onFileChange($event)">
          <div *ngIf="showEpisodesForm.controls['episode'].invalid && (showEpisodesForm.controls['episode'].dirty || showEpisodesForm.controls['episode'].touched || submitted)">
            <div class="formalerts" *ngIf="showEpisodesForm.controls['episode'].errors?.['required']">
              {{ getErrorMessage(showEpisodesForm.controls['episode']) }}
            </div>
            <div class="formalerts" *ngIf="showEpisodesForm.controls['episode'].errors?.['pattern']">
              {{ getErrorMessage(showEpisodesForm.controls['episode']) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary" type="submit">Submit</button>
  </form>
</div>