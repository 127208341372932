<div class="subscription-details-container">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple" *ngIf="isLoading"></ngx-spinner>
  <div *ngIf="error" class="error-message">{{ error }}</div>
  <div *ngIf="!isLoading && !error" class="content">
    <button class="back-button" (click)="goBack()">
      <i class="fas fa-arrow-left"></i> Back to Content
    </button>
    <h2>Subscription Details</h2>
    <div class="details-grid">
      <div class="details-section">
        <h3>General Info</h3>
        <div class="detail-item">
          <span class="detail-label">Start Date:</span>
          <span class="detail-value">{{ subscription.startDate ? (subscription.startDate | date) : 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">End Date:</span>
          <span class="detail-value">{{ subscription.endDate ? (subscription.endDate | date) : 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Active Status:</span>
          <span class="detail-value">{{ getActiveStatus() }}</span>
        </div>
      </div>
      <div class="details-section">
        <h3>User Details</h3>
        <div class="detail-item">
          <span class="detail-label">Username:</span>
          <span class="detail-value">{{ subscription.user.username || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Phone Number:</span>
          <span class="detail-value">{{ subscription.user.phoneNumber || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Email:</span>
          <span class="detail-value">{{ subscription.user.email || 'Not Available' }}</span>
        </div>
      </div>
      <div class="details-section">
        <h3>Plan Details</h3>
        <div class="detail-item">
          <span class="detail-label">Description:</span>
          <span class="detail-value">{{ subscription.plan.description || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Price:</span>
          <span class="detail-value">{{ subscription.plan.price || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Plan:</span>
          <span class="detail-value">{{ subscription.plan.plan || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Currency:</span>
          <span class="detail-value">{{ subscription.plan.currency || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Duration:</span>
          <span class="detail-value">{{ subscription.plan.duration || 'Not Available' }}</span>
        </div>
      </div>
      <div class="details-section">
        <h3>Payment Details</h3>
        <div class="detail-item">
          <span class="detail-label">Transaction ID:</span>
          <span class="detail-value">{{ subscription.payment.transactionId || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Amount:</span>
          <span class="detail-value">{{ subscription.payment.amount || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Payment Status:</span>
          <span class="detail-value">{{ subscription.payment.paymentStatus || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Payment Method:</span>
          <span class="detail-value">{{ subscription.payment.paymentMethod || 'Not Available' }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Payment Currency:</span>
          <span class="detail-value">{{ subscription.payment.currency || 'Not Available' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>