<div class="container">
  <div class="row">
    <div class="col-3">
      <div class="cards">
        <div class="card mb-3" (click)="showPodcastDetails()" 
        routerLink="podcastdetails">
          <div class="card-body">
            <h6 class="card-title">Podcast Details</h6>
            <i class="fa fa-lg fa-plus fa-bold" aria-hidden="true"></i>
          </div>
        </div>
        <div class="card mb-3" (click)="showPodcastEpisodes()" 
        routerLink="podcastepisodes">
          <div class="card-body">
            <h6 class="card-title">Podcast Episodes</h6>
            <i class="fa fa-lg fa-plus fa-bold" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9">
      <app-podcast-details-uploads *ngIf="podcastDetailsState"></app-podcast-details-uploads>
      <app-podcast-episodes-uploads *ngIf="podcastEpisodesState"></app-podcast-episodes-uploads>
    </div>
  </div>
</div>