import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-show-uploads',
  templateUrl: './show-uploads.component.html',
  styleUrls: ['./show-uploads.component.scss']
})
export class ShowUploadsComponent {

  showDetailsState = false;
  seasonDetailsState = false;
  showEpisodesState = false;
  showMetaDataState = false;

  showShowDetails() {
    this.showDetailsState = true;
    this.seasonDetailsState = false;
    this.showEpisodesState = false;
    this.showMetaDataState = false;
  }

  showSeasonDetails() {
    this.showDetailsState = false;
    this.seasonDetailsState = true;
    this.showEpisodesState = false;
    this.showMetaDataState = false;
  }

  showShowEpisodes() {
    this.showDetailsState = false;
    this.seasonDetailsState = false;
    this.showEpisodesState = true;
    this.showMetaDataState = false;
  }

  showShowMetaData() {
    this.showDetailsState = false;
    this.showEpisodesState = false;
    this.showMetaDataState = true;
  }

  constructor(private readonly router: Router) {

  }
  async ngOninit(): Promise<void> {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/uploadShowDetails') {
          this.showDetailsState = true;
          this.seasonDetailsState = false;
          this.showEpisodesState = false;
          this.showMetaDataState = false;
        } else if (event.url === '/uploadSeasonDetails') {
          this.showDetailsState = false;
          this.seasonDetailsState = true;
          this.showEpisodesState = false;
          this.showMetaDataState = false;
        } else if (event.url === '/uploadShowEpisodes') {
          this.showDetailsState = false;
          this.seasonDetailsState = false;
          this.showEpisodesState = true;
          this.showMetaDataState = false;
        } else {
          this.showDetailsState = false;
          this.seasonDetailsState = false;
          this.showEpisodesState = false;
          this.showMetaDataState = false;
        }
      }
    });
  }
  
  handleEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
  
  onKeyPress(event: KeyboardEvent): void {
    this.handleEnterKey(event);
  }

  onKeyDown(event: KeyboardEvent): void {
    this.handleEnterKey(event);
  }
  
  onKeyUp(event: KeyboardEvent): void {
    this.handleEnterKey(event);
  }
}