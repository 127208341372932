import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap, filter, catchError, take } from 'rxjs/operators';
import { ContentState } from '../store/state/content.state';
import {
  selectContent,
  selectContentLoading,
  selectContentError
} from '../store/selectors/content.selectors';
import { Media } from 'src/interfaces/player';
import { ContentItem, SeasonDetails } from 'src/interfaces/content';
import { selectContent as selectContentAction } from '../store/actions/content.actions';
import moment from 'moment';

@Component({
  selector: 'app-show-detail',
  templateUrl: './show-detail.component.html',
  styleUrls: ['./show-detail.component.scss']
})
export class ShowDetailComponent implements OnInit {
  show$!: Observable<Media | null>;
  seasons$!: Observable<SeasonDetails[]>;
  loading$!: Observable<boolean>;
  error$!: Observable<string | null>;
  modalData: any = null;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<ContentState>,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select(selectContentLoading);
    this.error$ = this.store.select(selectContentError);

    this.route.params.pipe(
      switchMap(params => {
        const contentId = params['id']; // This is actually the contentId
        const contentType = this.route.snapshot.url[0].path; // Get content type from URL
        this.store.dispatch(selectContentAction({ contentId, contentType }));
        return of({ contentId, contentType });
      }),
      switchMap(({ contentId, contentType }) => {
        return this.store.select(selectContent);
      }),
      filter((selectedContent: ContentItem | null) => !!selectedContent),
      map((selectedContent: ContentItem | null) => {
        if (selectedContent) {
          this.seasons$ = of(selectedContent.seasons || []);
          return this.mapToMedia(selectedContent);
        }
        return null;
      }),
      catchError((error: any) => {
        if (error.status === 401) {
          this.router.navigate(['/login']);
        }
        return throwError(() => error);
      }),
      take(1)
    ).subscribe(media => {
      this.show$ = of(media);
    });
  }

  private mapToMedia(data: ContentItem): Media {
    return {
      id: data.id,
      title: data.title,
      description: data.description,
      contentId: data.contentId,
      contentTheme: data.contentTheme,
      src: data.showUrl ?? '',
      type: 'video/mp4',
      thumbnail: data.image,
      label: data.label,
      resolution: data.resolution,
      ratio: data.ratio,
      autoplay: data.autoplay,
      image: data.image,
      episodes: data.episodes,
      showMetadata: data.showMetadata ? {
        director: data.showMetadata.director,
        producer: data.showMetadata.producer,
        releaseDate: this.formatReleaseDate(data.showMetadata.releaseDate),
        duration: data.showMetadata.duration,
        rating: data.showMetadata.rating,
        genre: data.showMetadata.genre
      } : undefined
    };
  }

  private mapEpisodeToMedia(episode: any): Media {
    return {
      id: episode.id,
      title: episode.title,
      description: episode.description,
      contentId: episode.contentId,
      contentTheme: episode.contentTheme,
      src: episode.showEpisodeUrl ?? '',
      type: 'video/mp4',
      thumbnail: episode.image,
      label: episode.label,
      resolution: episode.resolution,
      ratio: episode.ratio,
      autoplay: true,
      image: episode.image,
      rating: episode.rating ?? 0,
      showEpisodeMetadata: episode.showEpisodeMetadata ? {
        director: episode.showEpisodeMetadata.director,
        releaseDate: this.formatReleaseDate(episode.showEpisodeMetadata.releaseDate),
        duration: this.formatDuration(episode.showEpisodeMetadata.duration)
      } : undefined
    };
  }

  private formatReleaseDate(date: string): string {
    return moment(date).format('MMMM DD, YYYY');
  }

  private formatDuration(duration: number): string {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  }

  goBack() {
    this.router.navigate(['/content']);
  }

  generateStars(rating: number): boolean[] {
    return Array(10).fill(false).map((_, i) => i < rating);
  }

  playEpisode(episode: any): void {
    this.show$ = of(this.mapEpisodeToMedia(episode));
  }

  openShowInfo(show: Media): void {
    this.modalData = show;
    this.cdr.detectChanges(); // Trigger change detection
    this.ensureModalVisible(); // Additional check to force visibility
  }

  openSeasonInfo(season: SeasonDetails): void {
    this.modalData = season;
    this.cdr.detectChanges(); // Trigger change detection
    this.ensureModalVisible(); // Additional check to force visibility
  }

  openEpisodeInfo(episode: any, event: Event): void {
    event.stopPropagation(); // Prevent the click event from propagating to the parent element
    
    // Subscribe to the show$ observable to get the show metadata
    this.show$.pipe(
      map(show => show?.showMetadata)
    ).subscribe(showMetadata => {
      // Combine episode metadata with show metadata as fallback
      this.modalData = {
        ...episode,
        showEpisodeMetadata: {
          director: episode.showEpisodeMetadata?.director || showMetadata?.director || 'Unknown',
          releaseDate: episode.showEpisodeMetadata?.releaseDate ? this.formatReleaseDate(episode.showEpisodeMetadata.releaseDate)
            : (showMetadata?.releaseDate
              ? this.formatReleaseDate(showMetadata.releaseDate)
              : 'Unknown'),
          duration: episode.showEpisodeMetadata?.duration || showMetadata?.duration || 0
        }
      };
      
      this.cdr.detectChanges(); // Trigger change detection
      this.ensureModalVisible(); // Additional check to force visibility
    });
  }

  closeModal(): void {
    this.modalData = null;
    this.cdr.detectChanges(); // Trigger change detection
  }

  ensureModalVisible(): void {
    const modalElement = document.querySelector('.modal') as HTMLElement;
    if (modalElement) {
      modalElement.style.display = 'flex';
      modalElement.style.visibility = 'visible';
      modalElement.style.opacity = '1';
      modalElement.style.zIndex = '1000';
      modalElement.style.justifyContent = 'center';
      modalElement.style.alignItems = 'center';
      modalElement.style.position = 'fixed';
      modalElement.style.top = '0';
      modalElement.style.left = '0';
      modalElement.style.width = '100%';
      modalElement.style.height = '100%';
      modalElement.style.background = 'rgba(0, 0, 0, 0.8)';
      modalElement.style.transition = 'opacity 0.3s ease, visibility 0.3s ease';

      const modalContent = modalElement.querySelector('.modal-content') as HTMLElement;
      if (modalContent) {
        modalContent.style.position = 'relative';
        modalContent.style.background = 'linear-gradient(135deg, #fff, #f0f0f0)';
        modalContent.style.padding = '20px';
        modalContent.style.borderRadius = '10px';
        modalContent.style.maxWidth = '600px';
        modalContent.style.width = '90%';
        modalContent.style.textAlign = 'left';
        modalContent.style.boxShadow = '0 4px 15px rgba(0, 0, 0, 0.2)';
        modalContent.style.animation = 'fade-in 0.4s ease-out';

        const keyframes = `
          @keyframes fade-in {
            from {
              opacity: 0;
              transform: translateY(-20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `;
        const styleSheet = document.createElement("style");
        styleSheet.media = "all";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        const responsiveStyles = `
          @media (max-width: 600px) {
            .modal-content {
              padding: 15px;
              font-size: 0.9rem;
            }
            .modal-content h2 {
              font-size: 1.5rem;
            }
            .modal-content p {
              font-size: 0.9rem;
            }
          }
        `;
        styleSheet.innerText += responsiveStyles;

        const closeButton = modalContent.querySelector('.close') as HTMLElement;
        if (closeButton) {
          closeButton.style.position = 'absolute';
          closeButton.style.top = '10px';
          closeButton.style.right = '10px';
          closeButton.style.fontSize = '1.5rem';
          closeButton.style.color = '#333';
          closeButton.style.cursor = 'pointer';
          closeButton.style.transition = 'color 0.2s ease';

          closeButton.addEventListener('mouseover', () => {
            closeButton.style.color = '#d9534f';
          });

          closeButton.addEventListener('mouseout', () => {
            closeButton.style.color = '#333';
          });
        }

        const title = modalContent.querySelector('h2') as HTMLElement;
        if (title) {
          title.style.fontSize = '2rem';
          title.style.marginBottom = '10px';
          title.style.color = '#333';
        }

        const paragraphs = modalContent.querySelectorAll('p') as NodeListOf<HTMLElement>;
        paragraphs.forEach(p => {
          p.style.fontSize = '1rem';
          p.style.marginBottom = '10px';
          p.style.color = '#666';
          p.style.lineHeight = '1.6';
        });

        const strongElements = modalContent.querySelectorAll('strong') as NodeListOf<HTMLElement>;
        strongElements.forEach(strong => {
          strong.style.color = '#555';
        });
      }
    }
  }
}