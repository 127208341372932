import { createAction, props } from '@ngrx/store';
import { User } from 'src/interfaces/user';

// Action to clear the store
export const clearStore = createAction('[Auth] Clear Store');

// Action to sign out the user
export const signOut = createAction('[Auth] Sign Out');

// Action to set the authenticated user
export const setUser = createAction(
  '[Auth] Set User',
  props<{ user: User }>()
);

// Action to handle authentication errors
export const authError = createAction(
  '[Auth] Error',
  props<{ error: string }>()
);