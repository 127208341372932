import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  contactForm: FormGroup;
  submissionSuccess: boolean = false;
  submissionError: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly contactService: ContactService
  ) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.contactService.submitContactForm(this.contactForm.value).subscribe(
        response => {
          console.log('Form Submitted', response);
          this.submissionSuccess = true;
          this.submissionError = false;
          this.contactForm.reset();
        },
        error => {
          console.error('Form Submission Error', error);
          this.submissionSuccess = false;
          this.submissionError = true;
        }
      );
    }
  }

  goBack(): void {
    this.router.navigate(['/']);
  }
}