<div class="container">
  <div class="row justify-content-center">
    <div class="uploads-container">
      <div class="row cards">
        <div *ngFor="let item of uploadItems" class="card col-md-3 col-sm-6 m-2" [routerLink]="item.link">
          <div class="card-body">
            <h4 class="card-title">{{ item.title }}</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>