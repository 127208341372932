import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileTypeService {
  private readonly profileTypeSubject = new BehaviorSubject<'child' | 'adult'>('adult');
  profileType$ = this.profileTypeSubject.asObservable();

  setProfileType(profileType: 'child' | 'adult') {
    this.profileTypeSubject.next(profileType);
  }
}