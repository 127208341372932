import { createSelector, createFeatureSelector } from "@ngrx/store";
import { ContentState } from "../state/content.state";

export const selectContentState = createFeatureSelector<ContentState>('content');

export const selectAllMovies = createSelector(
    selectContentState,
    (state: ContentState) => state.movies
);

export const selectAllShows = createSelector(
    selectContentState,
    (state: ContentState) => state.shows
);

export const selectAllPodcasts = createSelector(
    selectContentState,
    (state: ContentState) => state.podcasts
);

export const selectAllRssFeeds = createSelector(
    selectContentState,
    (state: ContentState) => state.rssFeeds
);

export const selectContentLoading = createSelector(
    selectContentState,
    (state: ContentState) => state.loading
);

export const selectContentError = createSelector(
    selectContentState,
    (state: ContentState) => state.error
);

export const selectContent = createSelector(
    selectContentState,
    (state: ContentState) => state.selectedContent
);

export const selectContentId = createSelector(
    selectContentState,
    (state: ContentState) => state.selectedContentId
);

export const selectContentType = createSelector(
    selectContentState,
    (state: ContentState) => state.selectedContentType
);

export const selectContentLoaded = createSelector(
    selectContentState,
    (state: ContentState) => state.loaded
);