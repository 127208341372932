import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '../utils/constants';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private token: string | null = sessionStorage.getItem(LOCAL_STORAGE.accessToken);
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this.token}`,
  });
  
  private userInfoEndpoint = `${environment.apiBaseUrl}/api/users/profile`;
  private usersEndpoint = `${environment.apiBaseUrl}/api/users`;

  constructor(
    private auth: AuthenticationService,
    private http: HttpClient,
    private router: Router
  ) {}

  // Fetch logged-in user's profile
  async getUserInfo(): Promise<any | null> {
    try {
      const result: any = await firstValueFrom(
        this.http.get(this.userInfoEndpoint, { headers: this.headers })
      );
      if (result.success) {
        return result.data;
      } else {
        console.error(result.message);
        return null;
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return null;
    }
  }

  // Fetch a specific user's details by ID (admin access)
  async getUserById(userId: string): Promise<any | null> {
    try {
      const result: any = await firstValueFrom(
        this.http.get(`${this.usersEndpoint}/${userId}`, { headers: this.headers })
      );
      if (result.success) {
        return result.data;
      } else {
        console.error(result.message);
        return null;
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  }

  // Fetch all users (admin access)
  async getAllUsers(): Promise<any[] | null> {
    try {
      const result: any = await firstValueFrom(
        this.http.get(this.usersEndpoint, { headers: this.headers })
      );
      if (result.success) {
        return result.data;
      } else {
        console.error(result.message);
        return null;
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      return null;
    }
  }

  // Update user profile
  async updateUserProfile(userId: string, userData: any): Promise<any | null> {
    try {
      const result: any = await firstValueFrom(
        this.http.put(`${this.usersEndpoint}/${userId}`, userData, { headers: this.headers })
      );
      if (result.success) {
        return result.data;
      } else {
        console.error(result.message);
        return null;
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
      return null;
    }
  }

  // Delete a user (admin access)
  async deleteUser(userId: string): Promise<boolean> {
    try {
      const result: any = await firstValueFrom(
        this.http.delete(`${this.usersEndpoint}/${userId}`, { headers: this.headers })
      );
      if (result.success) {
        return true;
      } else {
        console.error(result.message);
        return false;
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      return false;
    }
  }

  // Check if the user is an admin
  async isAdmin(): Promise<boolean> {
    const token = sessionStorage.getItem(LOCAL_STORAGE.accessToken);
    if (!token) {
      return false;
    }

    try {
      const decoded: any = jwtDecode(token);
      const user = await this.getUserById(decoded.id);
      return user && user.isAdmin;
    } catch (error) {
      console.error('Error checking admin status:', error);
      return false;
    }
  }
}