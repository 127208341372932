export interface Plan {
  id: number;
  contentId: number | null;
  plan: string;
  description: string;
  price: number;
  duration: string;
  currency: string;
  slug: string;
  redirectUrl: string;
  activeStatus: boolean;
  isCustom: boolean;
  datePublished: string;
  dateUpdated: string;
  content: any;
}

export interface PaymentPlan {
  id: number;
  contentId?: number | null;
  plan: string;
  description: string;
  price: number;
  duration: string;
  currency: string;
  slug: string;
  redirectUrl: string;
  activeStatus: boolean;
  isCustom: boolean;
  datePublished: string;
  dateUpdated: string;
  content: any;
}

export enum PlanDuration {
  FreeTrial = 'FreeTrial',
  OneTime = 'OneTime',
  Daily = 'Daily',
  Biweekly = 'Biweekly',
  Triweekly = 'Triweekly',
  Quarterly = 'Quarterly',
  Semiannually = 'Semiannually',
  Annually = 'Annually',
  Custom = 'Custom',
  Weekly = 'Weekly',
  Monthly = 'Monthly'
}