import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, ViewChild, ElementRef, ChangeDetectorRef, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { catchError, finalize, of, switchMap, forkJoin, Observable, throwError } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-podcast-episodes-uploads',
  templateUrl: './podcast-episodes-uploads.component.html',
  styleUrls: ['./podcast-episodes-uploads.component.scss'],
  animations: [
    trigger('uploadAnimation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('300ms')),
      transition(':leave', animate('300ms')),
    ]),
  ],
})
export class PodcastEpisodesUploadsComponent implements OnInit, AfterViewInit {
  @ViewChild('audioInput') audioInput!: ElementRef;
  @ViewChild('videoInput') videoInput!: ElementRef;
  @ViewChild('imageInput') imageInput!: ElementRef;

  podcastEpisodesForm!: FormGroup;
  podcasts: any[] = [];
  showUploadAnimation = false;
  showUploadSuccessAnimation = false;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  showNextUploadPrompt = false;
  showForm = true;
  message = '';
  submitted = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly http: HttpClient,
    private readonly contentService: ContentService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.podcastEpisodesForm = this.formBuilder.group({
      podcastId: ['', Validators.required],
      episodeTitle: ['', [Validators.required, Validators.maxLength(255)]],
      episodeDesc: ['', [Validators.required, Validators.maxLength(500)]],
      duration: ['', [Validators.required, Validators.min(1), Validators.max(600)]],
      author: ['', [Validators.required, Validators.maxLength(255)]],
      director: ['', [Validators.required, Validators.maxLength(255)]],
      releaseDate: ['', [Validators.required]],
      audioEpisode: ['', Validators.pattern('.+\\.(mp3|wav|ogg)$')],
      videoEpisode: ['', Validators.pattern('.+\\.(mp4|mkv|avi|mov)$')],
      imageFile: ['']
    }, { validators: this.fileValidator });

    this.fetchPodcasts();
  }

  ngAfterViewInit(): void {
    // Ensure that the ViewChild elements are available
    if (!this.audioInput || !this.videoInput || !this.imageInput) {
      console.error('ViewChild elements are not available');
    }
  }

  fileValidator(control: AbstractControl): ValidationErrors | null {
    const audioEpisode = control.get('audioEpisode')?.value;
    const videoEpisode = control.get('videoEpisode')?.value;
    return audioEpisode && videoEpisode ? { fileConflict: true } : null;
  }

  fetchPodcasts(): void {
    this.contentService.getContentByType('podcasts').subscribe({
      next: (response) => this.podcasts = response.data,
      error: (error) => console.error('Error fetching podcasts', error),
      complete: () => console.log('Fetch completed')
    });
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.podcastEpisodesForm.invalid) {
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
      this.cdr.detectChanges();
      setTimeout(() => {
        this.showSaveErrorAnimation = false;
        this.message = '';
        this.cdr.detectChanges();
      }, 5000);
      return;
    }

    if (!this.audioInput || !this.videoInput || !this.imageInput) {
      this.showSaveErrorAnimation = true;
      this.message = 'Audio, video, or image input elements are not available.';
      this.cdr.detectChanges();
      setTimeout(() => {
        this.showSaveErrorAnimation = false;
        this.message = '';
        this.cdr.detectChanges();
      }, 5000);
      return;
    }

    const formData = new FormData();
    this.showUploadAnimation = true;
    formData.append('podcastId', this.podcastEpisodesForm.get('podcastId')?.value || '');
    formData.append('title', this.podcastEpisodesForm.get('episodeTitle')?.value || '');
    formData.append('description', this.podcastEpisodesForm.get('episodeDesc')?.value || '');
    formData.append('duration', this.podcastEpisodesForm.get('duration')?.value || '');
    formData.append('author', this.podcastEpisodesForm.get('author')?.value || '');
    formData.append('director', this.podcastEpisodesForm.get('director')?.value || '');
    formData.append('releaseDate', this.podcastEpisodesForm.get('releaseDate')?.value || '');

    const audioFile = this.audioInput.nativeElement.files[0];
    const videoFile = this.videoInput.nativeElement.files[0];
    const imageFile = this.imageInput.nativeElement.files[0];

    const audioFileType = audioFile ? audioFile.type : '';
    const videoFileType = videoFile ? videoFile.type : '';
    const imageFileType = imageFile ? imageFile.type : '';

    formData.append('audioFileType', audioFileType);
    formData.append('videoFileType', videoFileType);
    formData.append('imageFileType', imageFileType);

    this.contentService.uploadEpisode('podcasts', this.podcastEpisodesForm.get('podcastId')?.value, null, formData).pipe(
      switchMap((response: any) => {
        if (response?.success) {
          const signedEpisodeUrl = response.signedContentUrl;
          const signedImageUrl = response.signedImageUrl;

          const uploadVideoHeaders = new HttpHeaders({ 'Content-Type': videoFileType });
          const uploadImageHeaders = new HttpHeaders({ 'Content-Type': imageFileType });

          // Upload video file
          const uploadVideo$ = videoFile ? this.http.put(signedEpisodeUrl, videoFile, { headers: uploadVideoHeaders }).pipe(
            catchError(this.handleError)
          ) : of(null);

          // Upload image file
          const uploadImage$ = imageFile ? this.http.put(signedImageUrl, imageFile, { headers: uploadImageHeaders }).pipe(
            catchError(this.handleError)
          ) : of(null);

          return forkJoin([uploadVideo$, uploadImage$]).pipe(
            switchMap(() => {
              // Update the database with the new URLs
              return this.contentService.updateEpisodeUrls('podcasts', response.content.id, response.content.podcastEpisodeUrl, response.content.image);
            }),
            switchMap(() => of(response))
          );
        } else {
          throw new Error('Failed to get signed URLs');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.showSaveErrorAnimation = true;

        const status = error.status;
        const errorMessage = error.error?.message || 'An error occurred while uploading.';

        switch (status) {
          case 400:
            this.message = 'Bad request. Please check the input data';
            break;
          case 401:
            this.message = 'Unauthorized access. Please login and try again';
            break;
          case 403:
            this.message = 'Forbidden. You do not have permission to upload content';
            break;
          case 404:
            this.message = 'Resource not found. Please try again';
            break;
          case 409:
            this.message = 'Conflict. Episode with the same name already exists';
            break;
          case 500:
            this.message = 'Internal server error. Please try again later';
            break;
          default:
            this.message = errorMessage;
            break;
        }

        this.cdr.detectChanges();
        setTimeout(() => {
          this.showSaveErrorAnimation = false;
          this.message = '';
          this.cdr.detectChanges();
          this.resetForm();
        }, 5000);

        return of(null);
      }),
      finalize(() => {
        this.showUploadAnimation = false;
        this.cdr.detectChanges();
      })
    ).subscribe((response) => {
      if (response?.success) {
        this.showUploadSuccessAnimation = true;
        this.showSaveSuccessAnimation = true;
        this.message = response.message || 'Upload Successful';
        this.resetForm();
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showUploadSuccessAnimation = false;
          this.showSaveSuccessAnimation = false;
          this.showNextUploadPrompt = true;
          this.cdr.detectChanges();
        }, 5000);
      } else if (!this.message) {
        this.showSaveErrorAnimation = true;
        this.message = 'Upload failed. Please try again.';
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showSaveErrorAnimation = false;
          this.message = '';
          this.cdr.detectChanges();
          this.resetForm();
        }, 5000);
      }
    });
  }

  resetForm(): void {
    this.podcastEpisodesForm.reset();
    if (this.audioInput) this.audioInput.nativeElement.value = '';
    if (this.videoInput) this.videoInput.nativeElement.value = '';
    if (this.imageInput) this.imageInput.nativeElement.value = '';
    this.podcastEpisodesForm.markAsPristine();
    this.podcastEpisodesForm.markAsUntouched();
    this.submitted = false;
    this.message = '';
    this.showSaveErrorAnimation = false;
  }

  onNextUploadResponse(response: boolean): void {
    this.showNextUploadPrompt = false;
    if (response) {
      this.resetForm();
    } else {
      this.showForm = false;
      this.showUploadAnimation = false;
      this.showUploadSuccessAnimation = false;
      this.showSaveSuccessAnimation = false;
      this.showSaveErrorAnimation = false;
      this.message = '';
    }
  }

  getErrorMessage(control: AbstractControl): string {
    if (control.hasError('required')) {
      return 'This field is required';
    } else if (control.hasError('maxlength')) {
      return `Maximum length is ${control.getError('maxlength').requiredLength} characters`;
    } else if (control.hasError('pattern')) {
      return 'Invalid format';
    } else if (control.hasError('fileConflict')) {
      return 'Cannot upload both audio and video files';
    } else if (control.hasError('pastDate')) {
      return 'Date cannot be in the past';
    } else if (control.hasError('min')) {
      return `Minimum value is ${control.getError('min').min}`;
    } else if (control.hasError('max')) {
      return `Maximum value is ${control.getError('max').max}`;
    }
    return '';
  }

  futureDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const selectedDate = new Date(control.value);
    const today = new Date();
    if (selectedDate <= today) {
      return { pastDate: true };
    }
    return null;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error); // Rethrow it back to the component
  }
}