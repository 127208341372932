<div class="rssfeed-detail-container" *ngIf="rssFeed$ | async as rssFeed">
  <div class="rssfeed-image">
    <img [src]="rssFeed.image" alt="{{ rssFeed.title }}" />
    <div class="rssfeed-title">{{ rssFeed.title }}</div>
  </div>
  <div class="rssfeed-content">
    <div class="audio-section">
      <div class="rssfeed-header">
        <h1 class="rssfeed-title">
          {{ rssFeed.title }}
          <i class="fas fa-info-circle info-icon" (click)="openRssFeedInfo(rssFeed)"></i>
        </h1>
      </div>
      <app-audio-player [media]="rssFeed"></app-audio-player>
    </div>
    <div class="playlist-section" *ngIf="paginatedEpisodes$ | async as episodes">
      <h2>Episodes</h2>
      <ul>
        <li *ngFor="let episode of episodes" (click)="playEpisode(episode)" (keydown.enter)="playEpisode(episode)" tabindex="0">
          <h3>{{ episode.title }}</h3>
          <p [innerHTML]="sanitizeDescription(episode.description)"></p>
          <i class="fas fa-play-circle play-icon"></i>
        </li>
      </ul>
      <div class="pagination-controls">
        <button (click)="previousPage()" [disabled]="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button (click)="nextPage()" [disabled]="currentPage === totalPages">Next</button>
      </div>
    </div>
  </div>
  <button class="back-button" (click)="goBack()">Back</button>
</div>

<!-- Loading Spinner -->
<div *ngIf="loading$ | async" class="loading-spinner"></div>

<!-- Error Message -->
<div *ngIf="error$ | async as error" class="error-message">{{ error }}</div>

<!-- Modal for displaying RSS feed metadata -->
<div class="modal" [ngClass]="{'visible': modalData}" *ngIf="modalData">
  <div class="modal-content">
    <span class="close" (click)="closeModal()">&times;</span>
    <h2>{{ modalData.title }}</h2>
    <p [innerHTML]="sanitizeDescription(modalData.description || '')"></p>
    <div *ngIf="modalData.rssMetadata">
      <p><strong>Author:</strong> {{ modalData.rssMetadata.producer }}</p>
      <p><strong>Release Date:</strong> {{ modalData.rssMetadata.releaseDate | date }}</p>
    </div>
    <div *ngIf="modalData.rssEpisodeMetadata">
      <p><strong>Author:</strong> {{ modalData.rssEpisodeMetadata.author }}</p>
      <p><strong>Release Date:</strong> {{ modalData.rssEpisodeMetadata.releaseDate | date }}</p>
    </div>
  </div>
</div>