import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ContentService } from '../../services/content.service';
import { VideoModalComponent } from '../../components/video-modal/video-modal.component';
import { Media } from 'src/interfaces/player';

@Component({
  selector: 'app-lifestyle',
  templateUrl: './lifestyle.component.html',
  styleUrls: ['./lifestyle.component.scss']
})
export class LifestyleComponent implements OnInit {
  lifestyleContent: any[] = [];
  loading: boolean = true;

  constructor(
    private readonly contentService: ContentService,
    private readonly router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadLifestyleContent();
  }

  handleError(error: any) {
    return throwError(() => new Error('Failed to load content'));
  }

  loadLifestyleContent() {
    this.contentService.getContentByTheme('Lifestyle')
      .pipe(
        catchError(this.handleError)
      )
      .subscribe({
        next: (response) => {
          const content = response.content;
          this.lifestyleContent = (content.movies ?? [])
            .concat(content.podcasts ?? [], content.shows ?? []);
          this.loading = false;
        },
        error: () => {
          this.lifestyleContent = [];
          this.loading = false;
        }
      });
  }

  openVideoPlayer(content: any) {
    let playlist: Media[] = [];

    if (content.content.contentType === 'shows') {
      playlist = this.createPlaylistFromShow(content);
    } else if (content.content.contentType === 'podcasts') {
      playlist = this.createPlaylistFromPodcast(content);
    } else {
      playlist = [{
        src: content.movieUrl,
        title: content.title,
        description: content.description,
        type: content.content.contentType,
      }];
    }

    const currentUrl = content.movieUrl || content.podcastUrl || content.showUrl;
    const currentIndex = playlist.findIndex(item => item.src === currentUrl);

    if (currentIndex !== -1) {
      this.dialog.open(VideoModalComponent, {
        width: '80%',
        data: { media: playlist[currentIndex], playlist, currentIndex }
      });
    } else {
      console.error('Media not found in playlist');
    }
  }

  createPlaylistFromShow(show: any): Media[] {
    const playlist: Media[] = [{
      src: show.showUrl,
      title: show.title,
      description: show.description,
      type: 'show',
    }];

    const episodes = show.seasons.flatMap((season: any) =>
      season.seasonEpisodes.map((episode: any) => ({
        src: episode.showEpisodeUrl,
        title: episode.title,
        description: episode.description,
        type: 'show',
      }))
    );

    return playlist.concat(episodes);
  }

  createPlaylistFromPodcast(podcast: any): Media[] {
    const playlist: Media[] = [{
      src: podcast.podcastUrl,
      title: podcast.title,
      description: podcast.description,
      type: 'podcast',
    }];

    const episodes = podcast.episodes.map((episode: any) => ({
      src: episode.podcastEpisodeUrl,
      title: episode.title,
      description: episode.description,
      type: 'podcast',
    }));

    return playlist.concat(episodes);
  }

  navigateBack() {
    this.router.navigate(['/content']);
  }
}