import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ContentService } from '../../services/content.service';
import { catchError, map, mergeMap, of } from "rxjs";
import {
  checkAuthentication,
  loadContent,
  loadContentFailure,
  loadContentSuccess,
  selectContent,
  selectContentSuccess,
  selectContentError
} from "../actions/content.actions";
import {
  Movie,
  Podcast,
  Show,
  RssFeed,
  MovieResponse,
  PodcastResponse,
  ShowResponse,
  RssFeedResponse,
  AllData
} from 'src/interfaces/content';

@Injectable()
export class ContentEffects {
  checkAuthentication$ = createEffect(() => this.actions$.pipe(
    ofType(checkAuthentication),
    mergeMap(() => {
      const token = sessionStorage.getItem('2lietkapas');
      if (token) {
        return of(loadContent());
      } else {
        return of(loadContentFailure({ error: 'User not authenticated' }));
      }
    })
  ));

  loadContent$ = createEffect(() => this.actions$.pipe(
    ofType(loadContent),
    mergeMap(() => {
      return this.contentService.getAllContent().pipe(
        map(content => {
          const transformedContent = transformContentResponse(content);
          return loadContentSuccess(transformedContent);
        }),
        catchError(error => {
          return of(loadContentFailure({ error: error.message }));
        })
      );
    })
  ));

  selectContent$ = createEffect(() => this.actions$.pipe(
    ofType(selectContent),
    mergeMap(action => {
      return this.contentService.getContentById(action.contentType, action.contentId.toString()).pipe(
        map(response => {
          return selectContentSuccess({ content: response.content });
        }),
        catchError(error => {
          return of(selectContentError({ error: error.message }));
        })
      );
    })
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly contentService: ContentService
  ) { }
}

function transformContentResponse(response: {
  movies: MovieResponse,
  podcasts: PodcastResponse,
  shows: ShowResponse,
  rssFeeds: RssFeedResponse
}): AllData {
  const movies = response.movies.data.map((movie: Movie) => ({
    contentId: movie.contentId ?? undefined,
    id: movie.id,
    title: movie.title,
    description: movie.description,
    image: movie.image,
    movieUrl: movie.movieUrl,
    contentTheme: movie.contentTheme,
    contentType: movie.contentType,
    convertedPath: movie.convertedPath,
    isConverted: movie.isConverted,
    slug: movie.slug,
    movieMetadata: movie.movieMetadata,
    datePublished: movie.datePublished,
    dateUpdated: movie.dateUpdated,
    isChildFriendly: movie.isChildFriendly // Add this line
  })) || [];

  const podcasts = response.podcasts.data.map((podcast: Podcast) => ({
    contentId: podcast.contentId ?? undefined,
    id: podcast.id,
    title: podcast.title,
    description: podcast.description,
    image: podcast.image,
    podcastUrl: podcast.podcastUrl,
    contentTheme: podcast.contentTheme,
    contentType: podcast.contentType,
    convertedPath: podcast.convertedPath,
    isConverted: podcast.isConverted,
    slug: podcast.slug,
    podcastMetadata: podcast.podcastMetadata,
    datePublished: podcast.datePublished,
    dateUpdated: podcast.dateUpdated,
    episodes: podcast.episodes?.map(episode => ({
      ...episode,
      podcastEpisodeMetadata: episode.podcastEpisodeMetadata
    })),
    isChildFriendly: podcast.isChildFriendly // Add this line
  })) || [];

  const shows = response.shows.data.map((show: Show) => ({
    contentId: show.contentId ?? undefined,
    id: show.id,
    title: show.title,
    description: show.description,
    image: show.image,
    showUrl: show.showUrl,
    contentTheme: show.contentTheme,
    contentType: show.contentType,
    convertedPath: show.convertedPath,
    isConverted: show.isConverted,
    slug: show.slug,
    showMetadata: show.showMetadata,
    datePublished: show.datePublished,
    dateUpdated: show.dateUpdated,
    episodes: show.episodes?.map(episode => ({
      ...episode,
      showEpisodeMetadata: episode.showEpisodeMetadata
    })),
    seasons: show.seasons?.map(season => ({
      ...season,
      episodes: season.episodes?.map(episode => ({
        ...episode,
        showEpisodeMetadata: episode.showEpisodeMetadata
      }))
    })),
    isChildFriendly: show.isChildFriendly // Add this line
  })) || [];

  const rssFeeds = response.rssFeeds.data.map((feed: RssFeed) => ({
    contentId: feed.contentId ?? undefined,
    id: feed.id,
    title: feed.title,
    description: feed.description,
    image: feed.image,
    rssUrl: feed.rssUrl,
    contentTheme: feed.contentTheme,
    contentType: feed.contentType,
    convertedPath: feed.convertedPath,
    isConverted: feed.isConverted,
    slug: feed.slug,
    rssMetadata: feed.rssMetadata,
    datePublished: feed.datePublished,
    dateUpdated: feed.dateUpdated,
    url: feed.url,
    feeds: feed.feeds?.map(feedItem => ({
      id: feedItem.id,
      episodeTitle: feedItem.episodeTitle,
      episodeDesc: feedItem.episodeDesc,
      episodeUrl: feedItem.episodeUrl,
      guid: feedItem.guid,
      pubDate: feedItem.pubDate,
      duration: feedItem.duration,
      author: feedItem.author,
      explicit: feedItem.explicit,
      dateUpdated: feedItem.dateUpdated,
      rssEpisodeMetadata: feedItem.rssEpisodeMetadata,
      rssEpisodeUrl: feedItem.rssEpisodeUrl,
      datePublished: feedItem.datePublished
    })),
    isChildFriendly: feed.isChildFriendly // Add this line
  })) || [];

  return {
    movies,
    podcasts,
    shows,
    rssFeeds
  };
}