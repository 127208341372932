<div class="form-container p-2" *ngIf="showForm">
  <div class="top-title">
    <h4>Upload RSSFeed</h4>
  </div>
  <form class="form-content p-2" [formGroup]="rssFeedForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
    <div class="upload-animation" *ngIf="showUploadAnimation">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
        </svg>
      </div>
    </div>
    <div *ngIf="showUploadSuccessAnimation" class="alert alert-success">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="tick"
                d="M10 18l5 5l10 -10"/>
        </svg>
      </div>
      Upload Successful
    </div>
    <div *ngIf="showSaveSuccessAnimation" class="alert alert-success">
      Data Was Saved Successfully
    </div>
    <div *ngIf="showSaveErrorAnimation" class="alert alert-danger">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="cross"
                d="M12 12l12 12M12 24l12 -12"/>
        </svg>
      </div>
      {{ message }}
    </div>
    <div *ngIf="showNextUploadPrompt" class="alert alert-info">
      <p>Do you want to upload the next RSS feed?</p>
      <button type="button" (click)="onNextUploadResponse(true)">Yes</button>
      <button type="button" (click)="onNextUploadResponse(false)">No</button>
    </div>
    <div class="p-1 form-group">
      <input type="url" class="form-control" formControlName="url" placeholder="RSS Feed Url">
      <div *ngIf="rssFeedForm.controls['url'].invalid && (rssFeedForm.controls['url'].dirty || rssFeedForm.controls['url'].touched || submitted)">
        <div class="formalerts" *ngIf="rssFeedForm.controls['url'].errors?.['required']">URL is required.</div>
        <div class="formalerts" *ngIf="rssFeedForm.controls['url'].errors?.['pattern']">Invalid URL format.</div>
      </div>
    </div>
    <div class="p-1 form-group">
      <input type="text" class="form-control" formControlName="contentTheme" placeholder="Content Theme">
      <div *ngIf="rssFeedForm.controls['contentTheme'].invalid && (rssFeedForm.controls['contentTheme'].dirty || rssFeedForm.controls['contentTheme'].touched || submitted)">
        <div class="formalerts" *ngIf="rssFeedForm.controls['contentTheme'].errors?.['required']">Content Theme is required.</div>
      </div>
    </div>
    <button class="my-2 btn btn-primary" type="submit">Submit</button>
  </form>
</div>