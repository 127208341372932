import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-google-callback',
  templateUrl: './google-callback.component.html',
})
export class GoogleCallbackComponent implements OnInit {
  isLoading$ = new BehaviorSubject<boolean>(true);
  isContentLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      if (code) {
        this.authService.handleGoogleCallback().pipe(
        tap(() => {
          this.spinner.hide();
          this.isLoading$.next(false);
          this.isContentLoaded$.next(true);
        }),
        catchError(() => {
          this.spinner.hide();
          this.isLoading$.next(false);
          return this.router.navigate(['/signin'], { queryParams: { error: 'auth_failed' } });
        })
      ).subscribe();
      } else {
        this.spinner.hide();
        this.isLoading$.next(false);
        this.router.navigate(['/signin'], { queryParams: { error: 'auth_failed' } });
      }
    });
  }
}