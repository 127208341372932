import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, ViewChild, ElementRef, ChangeDetectorRef, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { catchError, finalize, of, switchMap, forkJoin, Observable, throwError } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { LoggingService } from 'src/app/services/logging.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-show-episodes-uploads',
  templateUrl: './show-episodes-uploads.component.html',
  styleUrls: ['./show-episodes-uploads.component.scss'],
  animations: [
    trigger('uploadAnimation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('300ms')),
      transition(':leave', animate('300ms')),
    ]),
  ],
})
export class ShowEpisodesUploadsComponent implements OnInit, AfterViewInit {
  @ViewChild('episodeInput') episodeInput!: ElementRef;
  @ViewChild('imageInput') imageInput!: ElementRef;

  showEpisodesForm!: FormGroup;
  shows: any[] = [];
  seasons: any[] = [];
  showUploadAnimation = false;
  showUploadSuccessAnimation = false;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  showNextUploadPrompt = false;
  showForm = true;
  message = '';
  submitted = false;
  episodeFile: File | null = null;
  imageFile: File | null = null;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly http: HttpClient,
    private readonly contentService: ContentService,
    private readonly loggingService: LoggingService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.showEpisodesForm = this.formBuilder.group({
      showTitle: ['', Validators.required],
      seasonNo: ['', Validators.required],
      episodeTitle: ['', [Validators.required, Validators.maxLength(255)]],
      episodeDesc: ['', [Validators.required, Validators.maxLength(500)]],
      duration: ['', [Validators.required, Validators.min(1), Validators.max(600)]],
      director: ['', [Validators.required, Validators.maxLength(255)]],
      releaseDate: ['', [Validators.required]],
      episode: ['', [Validators.required, Validators.pattern('.+\\.(mp4|avi|mov|mkv)$')]],
      imageFile: ['', [Validators.required, Validators.pattern('.+\\.(jpg|jpeg|png)$')]],
    }, { validators: this.fileValidator });

    this.fetchShows();
  }

  ngAfterViewInit(): void {
    if (!this.episodeInput || !this.imageInput) {
      console.error('ViewChild elements are not available');
    }
  }

  fileValidator(control: AbstractControl): ValidationErrors | null {
    const episode = control.get('episode')?.value;
    const imageFile = control.get('imageFile')?.value;
    return episode && imageFile ? null : { fileConflict: true };
  }

  fetchShows(): void {
    this.contentService.getContentByType('shows')
      .subscribe({
        next: (response) => this.shows = response.data,
        error: (error) => this.loggingService.error('Error fetching shows: ' + error),
        complete: () => this.loggingService.log('Fetch completed')
      });
  }
  
  fetchSeasons(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const contentId = target.value;
    
    const selectedShow = this.shows.find(show => show.contentId == contentId);
    const showId = selectedShow ? selectedShow.id : null;
    
    if (showId) {
      this.contentService.getAllSeasons(showId).subscribe({
        next: (response) => this.seasons = response.data,
        error: (error) => this.loggingService.error('Error fetching seasons: ' + error),
        complete: () => this.loggingService.log('Fetch completed')
      });
    } else {
      this.loggingService.error('Show ID not found for the selected content ID');
    }
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      if (input.id === 'episode') {
        this.episodeFile = input.files[0];
        this.showEpisodesForm.patchValue({ episode: this.episodeFile.name });
      } else if (input.id === 'imageFile') {
        this.imageFile = input.files[0];
        this.showEpisodesForm.patchValue({ imageFile: this.imageFile.name });
      }
    }
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.showEpisodesForm.invalid) {
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
      this.cdr.detectChanges();
      setTimeout(() => {
        this.showSaveErrorAnimation = false;
        this.cdr.detectChanges();
      }, 5000);
      return;
    }

    const formData = new FormData();
    this.showUploadAnimation = true;
    const showId = this.showEpisodesForm.get('showTitle')?.value;
    const seasonId = this.showEpisodesForm.get('seasonNo')?.value;
    formData.append('title', this.showEpisodesForm.get('episodeTitle')?.value || '');
    formData.append('description', this.showEpisodesForm.get('episodeDesc')?.value || '');
    formData.append('duration', this.showEpisodesForm.get('duration')?.value || '');
    formData.append('director', this.showEpisodesForm.get('director')?.value || '');
    formData.append('releaseDate', this.showEpisodesForm.get('releaseDate')?.value || '');

    const episodeFile = this.episodeInput.nativeElement.files[0];
    const imageFile = this.imageInput.nativeElement.files[0];

    const episodeFileType = episodeFile ? episodeFile.type : '';
    const imageFileType = imageFile ? imageFile.type : '';

    formData.append('videoFileType', episodeFileType);
    formData.append('imageFileType', imageFileType);

    this.contentService.uploadEpisode('shows', showId, seasonId, formData).pipe(
      switchMap((response: any) => {
        if (response?.success) {
          const signedEpisodeUrl = response.signedContentUrl;
          const signedImageUrl = response.signedImageUrl;

          const uploadEpisodeHeaders = new HttpHeaders({ 'Content-Type': episodeFileType });
          const uploadImageHeaders = new HttpHeaders({ 'Content-Type': imageFileType });

          // Upload episode file
          const uploadEpisode$ = this.http.put(signedEpisodeUrl, episodeFile, { headers: uploadEpisodeHeaders }).pipe(
            catchError(this.handleError)
          );

          // Upload image file
          const uploadImage$ = this.http.put(signedImageUrl, imageFile, { headers: uploadImageHeaders }).pipe(
            catchError(this.handleError)
          );

          return forkJoin([uploadEpisode$, uploadImage$]).pipe(
            switchMap(() => {
              // Update the database with the new URLs
              return this.contentService.updateEpisodeUrls('shows', response.content.id, response.content.showEpisodeUrl, response.content.image);
            }),
            switchMap(() => of(response))
          );
        } else {
          throw new Error('Failed to get signed URLs');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.showSaveErrorAnimation = true;

        const status = error.status;
        const errorMessage = error.error?.message || 'An error occurred while uploading.';

        switch (status) {
          case 400:
            this.message = 'Bad request. Please check the input data';
            break;
          case 401:
            this.message = 'Unauthorized access. Please login and try again';
            break;
          case 403:
            this.message = 'Forbidden access. Please contact the administrator';
            break;
          case 404:
            this.message = 'Resource not found. Please check the URL';
            break;
          case 409:
            this.message = 'Conflict. Episode with the same title already exists';
            break;
          case 500:
            this.message = 'Internal server error. Please try again later';
            break;
          default:
            this.message = errorMessage;
            break;
        }

        this.cdr.detectChanges();
        setTimeout(() => {
          this.showSaveErrorAnimation = false;
          this.message = '';
          this.cdr.detectChanges();
          this.resetForm();
        }, 5000);

        return of(null);
      }),
      finalize(() => {
        this.showUploadAnimation = false;
        this.cdr.detectChanges();
      })
    ).subscribe((response) => {
      if (response?.success) {
        this.showUploadSuccessAnimation = true;
        this.showSaveSuccessAnimation = true;
        this.message = response.message || 'Upload Successful';
        this.resetForm();
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showUploadSuccessAnimation = false;
          this.showSaveSuccessAnimation = false;
          this.showNextUploadPrompt = true;
          this.cdr.detectChanges();
        }, 5000);
      } else if (!this.message) {
        this.showSaveErrorAnimation = true;
        this.message = 'Upload failed. Please try again.';
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showSaveErrorAnimation = false;
          this.message = '';
          this.cdr.detectChanges();
          this.resetForm();
        }, 5000);
      }
    });
  }

  resetForm(): void {
    this.showEpisodesForm.reset();
    if (this.episodeInput) this.episodeInput.nativeElement.value = '';
    if (this.imageInput) this.imageInput.nativeElement.value = '';
    this.episodeFile = null;
    this.imageFile = null;
    this.showEpisodesForm.markAsPristine();
    this.showEpisodesForm.markAsUntouched();
    this.submitted = false;
    this.message = '';
    this.showSaveErrorAnimation = false;
  }

  onNextUploadResponse(response: boolean): void {
    this.showNextUploadPrompt = false;
    if (response) {
      this.resetForm();
    } else {
      this.showForm = false;
      this.showUploadAnimation = false;
      this.showUploadSuccessAnimation = false;
      this.showSaveSuccessAnimation = false;
      this.showSaveErrorAnimation = false;
      this.message = '';
    }
  }

  getErrorMessage(control: AbstractControl): string {
    if (control.hasError('required')) {
      return 'This field is required';
    } else if (control.hasError('maxlength')) {
      return `Maximum length is ${control.getError('maxlength').requiredLength} characters`;
    } else if (control.hasError('pattern')) {
      return 'Invalid format';
    } else if (control.hasError('fileConflict')) {
      return 'Cannot upload both episode and image files';
    } else if (control.hasError('pastDate')) {
      return 'Date cannot be in the past';
    } else if (control.hasError('min')) {
      return `Minimum value is ${control.getError('min').min}`;
    } else if (control.hasError('max')) {
      return `Maximum value is ${control.getError('max').max}`;
    }
    return '';
  }

  futureDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const selectedDate = new Date(control.value);
    const today = new Date();
    if (selectedDate <= today) {
      return { pastDate: true };
    }
    return null;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error); // Rethrow it back to the component
  }
}