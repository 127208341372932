import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentPlanService {
  private readonly apiUrl: string = `${environment.apiBaseUrl}/api/plans`;
  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('2lietkapas')}`,
  });

  constructor(private readonly http: HttpClient) {}

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      errorMessage = `A client-side error occurred: ${error.error.message}`;
    } else {
      switch (error.status) {
        case 404:
          errorMessage = 'No plans found.';
          break;
        case 400:
          errorMessage = 'Bad request. Please try again.';
          break;
        case 401:
          errorMessage = 'Unauthorized access. Please login and try again.';
          break;
        case 403:
          errorMessage = 'Forbidden access. Please check your permissions.';
          break;
        case 409:
          errorMessage = 'Conflict. A plan with the same name or duration already exists.';
          break;
        case 500:
          errorMessage = 'Server error. Please try again later.';
          break;
        default:
          errorMessage = error.error?.message || 'An unexpected error occurred. Please try again later.';
      }
    }
    return throwError(() => new Error(errorMessage));
  }

  createPlatformWidePlans(): Observable<any> {
    return this.http.post(`${this.apiUrl}/platform-wide`, {}, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  createCustomPlatformWidePlan(planData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/platform-wide/custom`, planData, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  getAllPlans(): Observable<any> {
    return this.http.get(this.apiUrl, { headers: this.headers })
      .pipe(
        map((response: any) => response.data),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of([]); // Return an empty array if no plans are found
          } else {
            return this.handleError(error);
          }
        })
      );
  }

  getPlan(planId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${planId}`, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  updatePlan(planId: string, planData: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/${planId}`, planData, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  deletePlan(planId: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${planId}`, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  deleteAllPlans(): Observable<any> {
    return this.http.delete(this.apiUrl, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }
}