import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { LOCAL_STORAGE } from '../utils/constants';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {
  email: string | null = window.sessionStorage.getItem(LOCAL_STORAGE.emailForSignIn);
  errorMessage: string | null = null;
  successMessage: string | null = null;

  constructor(
    private readonly auth: AuthenticationService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    if (!this.email) {
      this.router.navigate(['/signin']).catch(err => {
        this.errorMessage = 'Navigation error: ' + err.message;
      });
    }
  }

  resendLink(): void {
    if (this.email) {
      this.auth.resendOTP(this.email).subscribe({
        next: () => {
          this.successMessage = 'OTP has been resent successfully.';
          this.errorMessage = null; // Clear any previous error messages
        },
        error: (err) => {
          this.errorMessage = 'Failed to resend OTP: ' + err.message;
          this.successMessage = null; // Clear any previous success messages
        }
      });
    } else {
      this.errorMessage = 'Email is not available for resending OTP';
      this.successMessage = null; // Clear any previous success messages
    }
  }

  onOkayButtonClick(): void {
    this.router.navigate(['/verify-otp']).catch(err => {
      this.errorMessage = 'Navigation error: ' + err.message;
    });
  }

  onKeyPress(event: KeyboardEvent): void {
    console.log('Key Pressed:', event.key);
  }

  onKeyDown(event: KeyboardEvent): void {
    console.log('Key Down:', event.key);
  }

  onKeyUp(event: KeyboardEvent): void {
    console.log('Key Up:', event.key);
  }
}