import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { AuthenticationService } from '../services/authentication.service';
import { SplashCardItem } from '../utils/interfaces';
// import PullToRefresh from 'pulltorefreshjs';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  signUpBtnLabel: string = 'Sign up';
  signInBtnLabel: string = 'Sign in';
  signUpBtnClass: string = 'btn--wide';
  currentYear: number;

  cardItems: SplashCardItem[] = [
    { imgUri: '../../assets/FreeTrialImage.png', description: 'Free Trial' },
    { imgUri: '../../assets/FamilyImage.png', description: 'Family' },
    { imgUri: '../../assets/LifestyleImage.png', description: 'Lifestyle' },
    { imgUri: '../../assets/KidsImage.png', description: 'Kids' },
    { imgUri: '../../assets/SignupImage.png', description: 'Today' },
  ];

  constructor(
    private readonly router: Router,
    private readonly auth: AuthenticationService,
    private readonly meta: Meta,
    private readonly title: Title
  ) {
    this.currentYear = new Date().getFullYear();
  }

  async ngOnInit() {
    this.meta.addTags([
      { name: 'description', content: 'Explore movies, shows, and podcasts that bring joy to every member of your family' },
      { name: 'keywords', content: 'nwplus, NwPlus, nwplustv, NWPLUS TV, NWPLUS, NWPlus TV, nwplus tv, entertainment, movies, shows, podcasts, family' },
      { name: 'robots', content: 'index, follow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'theme-color', content: '#1976d2' }
    ]);

    let canAuthenticateResult = await this.auth.canActivate();
    if (canAuthenticateResult === true) {
      this.router.navigate(["/content"]);
    }

    // const isMobileOrTablet = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // if (isMobileOrTablet) {
    //   PullToRefresh.init({
    //     mainElement: 'body',
    //     onRefresh() {
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 5000);
    //     },
    //     instructionsPullToRefresh: 'Pull down to refresh',
    //     instructionsReleaseToRefresh: 'Release to refresh',
    //     instructionsRefreshing: 'Refreshing...',
    //     iconArrow: '<i class="fas fa-arrow-down"></i>',
    //     iconRefreshing: '<i class="fas fa-sync-alt fa-spin"></i>'
    //   });
    // }
  }
}