<div class="about-container">
  <div class="back-button" (click)="goBack()">
    <span class="back-icon">&#8592;</span>
  </div>
  <h1>About Us</h1>
  <section class="about-section">
    <h2>Our Mission</h2>
    <p>At NWPLUS TV, our mission is to provide high-quality entertainment for the whole family. We offer a wide variety of movies, shows, and podcasts that bring joy and excitement to every member of your household.</p>
  </section>
  <section class="about-section">
    <h2>Our Story</h2>
    <p>NWPLUS TV was founded in 2021 with the goal of creating a streaming service that caters to families. We believe in the power of entertainment to bring people together, and we strive to offer content that is both enjoyable and enriching.</p>
  </section>
  <section class="about-section">
    <h2>Our Team</h2>
    <div class="team-container">
      <div class="team-member">
        <img src="assets/AfricaMap.jpg" alt="Team Member 1">
        <div class="member-info">
          <h3>Muthoni Waigwa</h3>
          <p>CEO & Founder</p>
        </div>
      </div>
      <div class="team-member">
        <img src="assets/AfricaMap.jpg" alt="Team Member 2">
        <div class="member-info">
          <h3>Nyatetu Tets</h3>
          <p>Chief Content Officer</p>
        </div>
      </div>
      <div class="team-member">
        <img src="assets/AfricaMap.jpg" alt="Team Member 3">
        <div class="member-info">
          <h3>Nderru Nderru</h3>
          <p>Head of Customer Support</p>
        </div>
      </div>
    </div>
  </section>
  <section class="about-section">
    <h2>Contact Us</h2>
    <p>If you have any questions or feedback, we would love to hear from you. Please visit our <a routerLink="/contact">Contact Us</a> page to get in touch with our team.</p>
  </section>
</div>