import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToggleDescriptionService } from '../../services/toggle-description.service';
import { ContentService } from '../../services/content.service';
import { TopcontentVideoModalComponent } from '../topcontent-video-modal/topcontent-video-modal.component';
import { Media } from 'src/interfaces/player';
import { ContentComponent } from 'src/app/content/content.component';

@Component({
  selector: 'app-top-content',
  templateUrl: './top-content.component.html',
  styleUrls: ['./top-content.component.scss']
})
export class TopContentComponent implements OnInit {
  @Input() contentData!: { key: string, data: any };
  @Input() isPaid!: boolean;
  routeComponent!: string;
  componentName!: string;
  contentRoutes = [
    'movie',
    'show',
    'podcast',
  ];

  constructor(
    public toggleDesc: ToggleDescriptionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly contentService: ContentService,
    private readonly dialog: MatDialog,
    private readonly contentComponent: ContentComponent // Inject ContentComponent
  ) {}

  ngOnInit() {
    const routeParts = this.route.snapshot.url.map(segment => segment.path);
    const firstPart = routeParts[0];
    this.routeComponent = firstPart;
  }

  encodeUrl(url: string): string {
    return url.replace(/ /g, "%20");
  }

  openVideoModal(media: Media): void {
    const dialogConfig = {
      width: '70%',
      height: '80%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: media,
      panelClass: 'top-content-video-modal'
    };

    if (window.innerWidth < 768) {
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
    } else if (window.innerWidth < 1024) {
      dialogConfig.width = '90%';
      dialogConfig.height = '90%';
    }

    const dialogRef = this.dialog.open(TopcontentVideoModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      this.contentComponent.fetchNewRandomPost(); // Fetch new video data
    });
  }

  private createMedia(contentData: { key: string, data: any }): Media | null {
    switch (contentData.key) {
      case 'movies':
        return this.createMovieMedia(contentData.data);
      case 'shows':
        return this.createShowMedia(contentData.data);
      case 'podcasts':
        return this.createPodcastMedia(contentData.data);
      default:
        console.error('Unsupported content type');
        return null;
    }
  }

  private createMovieMedia(data: any): Media {
    return {
      src: data.movieUrl ? this.encodeUrl(data.movieUrl) : data.movieUrl,
      type: data.convertedPath ? data.mediaType || 'application/x-mpegURL' : 'video/mp4',
      image: data.image || "../../assets/logo.png"
    };
  }

  private createShowMedia(data: any): Media | null {
    if (!data.seasons || data.seasons.length === 0 ||
        !data.seasons[0].episodes || data.seasons[0].episodes.length === 0) {
      console.error('No seasons or episodes available');
      return null;
    }
    return {
      src: data.seasons[0].episodes[0].showEpisodeUrl ? this.encodeUrl(data.seasons[0].episodes[0].showEpisodeUrl) : data.showUrl,
      type: data.seasons[0].episodes[0].showEpisodeUrl ? data.mediaType || 'application/x-mpegURL' : 'video/mp4',
      image: data.image || "../../assets/logo.png"
    };
  }

  private createPodcastMedia(data: any): Media | null {
    if (!data.episodes || data.episodes.length === 0) {
      console.error('No episodes available');
      return null;
    }
    const episode = data.episodes[0];
    return {
      src: episode.podcastEpisodeUrl ? this.encodeUrl(episode.podcastEpisodeUrl) : episode.podcastEpisodeUrl,
      type: episode.convertedPath ? data.mediaType || 'application/x-mpegURL' : 'audio/mpeg',
      image: data.image || "../../assets/logo.png"
    };
  }

  onPlay(contentData: { key: string, data: any }) {
    const media = this.createMedia(contentData);
    if (media) {
      this.openVideoModal(media);
    }
  }
}