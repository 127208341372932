import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RssFeedService } from 'src/app/services/rss-feed.service';

@Component({
  selector: 'app-verify-rss-feed',
  templateUrl: './verify-rss-feed.component.html',
  styleUrls: ['./verify-rss-feed.component.scss']
})
export class VerifyRssFeedComponent implements OnInit {
  message: string = '';
  success: boolean = false;
  currentYear: number = new Date().getFullYear();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly rssFeedService: RssFeedService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      if (token) {
        this.verifyRssFeed(token);
      } else {
        this.message = 'Verification token is missing.';
      }
    });
  }

  verifyRssFeed(token: string): void {
    this.rssFeedService.verifyRSSFeed(token).subscribe({
      next: (response: any) => {
        this.success = response.success;
        this.message = response.message;
      },
      error: (error: any) => {
        this.success = false;
        switch (error.status) {
          case 400:
            this.message = 'Invalid or expired verification token.';
            break;
          case 404:
            this.message = 'RSS feed not found or already verified.';
            break;
          case 500:
            this.message = 'Internal server error. Please try again later.';
            break;
          default:
            this.message = 'An error occurred during verification.';
        }
      }
    });
  }
}