import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-play-button',
  templateUrl: './play-button.component.html',
  styleUrls: ['./play-button.component.scss']
})
export class PlayButtonComponent {
  @Input() contentData!: { key: string, data: any };
  @Output() play = new EventEmitter<{ key: string, data: any }>();

  onPlayButton() {
    this.play.emit(this.contentData);
  }
}