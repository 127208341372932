<div class="verify-email">
  <app-light-logo class="logo"></app-light-logo>   
  <div class="content">
    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div *ngIf="successMessage" class="success-message">
      {{ successMessage }}
    </div>
    <p>We have sent an email to <u>{{email}}</u> with an OTP to verify your email address.</p>
    <p>
      <u id="click-here" 
         (click)="resendLink()" 
         (keypress)="onKeyPress($event)" 
         (keydown)="onKeyDown($event)" 
         (keyup)="onKeyUp($event)">
        Click here
      </u> 
      if you did not receive an email
    </p>
  </div>
  <button mat-raised-button color="primary" (click)="onOkayButtonClick()">Okay</button>
</div>
<app-footer></app-footer>