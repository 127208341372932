import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentPlanService } from 'src/app/services/payment-plan.service';
import { PaymentPlan, PlanDuration } from 'src/interfaces/plan';

@Component({
  selector: 'app-payment-plans',
  templateUrl: './payment-plans.component.html',
  styleUrls: ['./payment-plans.component.scss']
})
export class PaymentPlansComponent implements OnInit {
  paymentPlans: PaymentPlan[] = [];
  availableDurations: string[] = Object.values(PlanDuration);
  paymentPlanForm: FormGroup;
  isEditing: boolean = false;
  editingPlanId: number | null = null;
  errorMessage: string | null = null;
  formErrorMessage: string | null = null; // New form error message property
  successMessage: string | null = null; // New success message property
  loading: boolean = false; // New loading property
  noPlansMessage: string | null = null; // New no plans message property

  constructor(private readonly fb: FormBuilder, private readonly paymentPlanService: PaymentPlanService) {
    this.paymentPlanForm = this.fb.group({
      plan: ['', [Validators.required, Validators.maxLength(100)]],
      price: [0, [Validators.required, Validators.min(0)]],
      duration: ['', Validators.required],
      currency: ['', [Validators.required, Validators.maxLength(3)]],
      description: ['', [Validators.required, Validators.maxLength(500)]]
    });
  }

  ngOnInit(): void {
    this.loadPaymentPlans();
  }

  loadPaymentPlans(): void {
    this.loading = true;
    this.paymentPlanService.getAllPlans().subscribe({
      next: (plans: PaymentPlan[]) => {
        // Filter out plans with a price of zero
        this.paymentPlans = plans.filter(plan => plan.price > 0);
        this.errorMessage = null; // Clear any previous error messages
        this.noPlansMessage = this.paymentPlans.length === 0 ? 'No payment plans available.' : null; // Set no plans message
        this.loading = false;
      },
      error: (error: any) => {
        this.handleError(error);
        this.loading = false;
      }
    });
  }

  createPaymentPlan(): void {
    if (this.paymentPlanForm.invalid) {
      return;
    }

    const formValue = this.paymentPlanForm.value;
    const newPaymentPlan: PaymentPlan = {
      ...formValue,
      slug: '',
      redirectUrl: '',
      activeStatus: true,
      isCustom: true,
      datePublished: '',
      dateUpdated: '',
    };

    this.loading = true;
    if (this.isEditing) {
      this.updatePaymentPlan(newPaymentPlan);
    } else {
      this.paymentPlanService.createCustomPlatformWidePlan(newPaymentPlan).subscribe({
        next: (plan: PaymentPlan) => {
          this.resetForm();
          this.loadPaymentPlans();
          this.errorMessage = null;
          this.formErrorMessage = null; // Clear form error message
          this.successMessage = 'Payment plan created successfully.'; // Set success message
          this.clearMessagesAfterDelay(); // Clear messages after delay
          this.loading = false;
        },
        error: (error: any) => {
          this.formErrorMessage = error.message; // Handle form error
          this.clearMessagesAfterDelay(); // Clear messages after delay
          this.loading = false;
        }
      });
    }
  }

  editPaymentPlan(plan: PaymentPlan): void {
    this.isEditing = true;
    this.editingPlanId = plan.id;
    this.paymentPlanForm.patchValue(plan);
  }

  updatePaymentPlan(updatedPlan: PaymentPlan): void {
    if (this.editingPlanId !== null) {
      this.loading = true;
      this.paymentPlanService.updatePlan(this.editingPlanId.toString(), updatedPlan).subscribe({
        next: (plan: PaymentPlan) => {
          this.resetForm();
          this.loadPaymentPlans();
          this.errorMessage = null;
          this.formErrorMessage = null; // Clear form error message
          this.successMessage = 'Payment plan updated successfully.'; // Set success message
          this.clearMessagesAfterDelay(); // Clear messages after delay
          this.loading = false;
        },
        error: (error: any) => {
          this.formErrorMessage = error.message; // Handle form error
          this.clearMessagesAfterDelay(); // Clear messages after delay
          this.loading = false;
        }
      });
    }
  }

  deletePaymentPlan(id: number): void {
    this.loading = true;
    this.paymentPlanService.deletePlan(id.toString()).subscribe({
      next: () => {
        this.loadPaymentPlans();
        this.errorMessage = null;
        this.successMessage = 'Payment plan deleted successfully.'; // Set success message
        this.clearMessagesAfterDelay(); // Clear messages after delay
        this.loading = false;
      },
      error: (error: any) => {
        this.handleError(error);
        this.clearMessagesAfterDelay(); // Clear messages after delay
        this.loading = false;
      }
    });
  }

  resetForm(): void {
    this.paymentPlanForm.reset({
      plan: '',
      price: 0,
      duration: '',
      currency: '',
      description: ''
    });
    this.isEditing = false;
    this.editingPlanId = null;
    this.errorMessage = null;
    this.formErrorMessage = null; // Clear form error message
    this.successMessage = null; // Clear success message
  }
  
  private handleError(error: any): void {
    const errorMessage = error?.error?.message ?? 'An unexpected error occurred. Please try again later.';
    this.errorMessage = errorMessage;
    this.clearMessagesAfterDelay(); // Clear messages after delay
  }

  private clearMessagesAfterDelay(): void {
    setTimeout(() => {
      this.errorMessage = null;
      this.formErrorMessage = null;
      this.successMessage = null;
    }, 5000); // Clear messages after 5 seconds
  }
}