import { createAction, props } from "@ngrx/store";
import { Subscription } from "src/interfaces/subscription";

export const loadSubscriptions = createAction(
    '[Subscription] Load Subscriptions',
    props<{ userId: string }>()
);

export const loadSubscriptionsSuccess = createAction(
    '[Subscription] Load Subscriptions Success',
    props<{ subscriptions: Subscription[] }>()
);

export const loadSubscriptionsFailure = createAction(
    '[Subscription] Load Subscriptions Failure',
    props<{ error: string }>()
);

export const subReset = createAction('[Subscription] Reset');