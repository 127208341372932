<div class="show-detail-container" *ngIf="show$ | async as show">
  <div class="show-video">
    <app-video-player [media]="show"></app-video-player>
    <div class="show-header">
      <h1 class="show-title">{{ show.title }}
        <i class="fas fa-info-circle info-icon" (click)="openShowInfo(show)"></i>
      </h1>
    </div>
  </div>
  <div class="show-seasons" *ngIf="seasons$ | async as seasons">
    <h2>Seasons</h2>
    <div *ngFor="let season of seasons">
      <h3>Season {{ season.number }}
        <i class="fas fa-info-circle info-icon" (click)="openSeasonInfo(season)"></i>
      </h3>
      <p>{{ season.description }}</p>
      <ul *ngIf="season.episodes.length > 0; else noEpisodes">
        <li *ngFor="let episode of season.episodes" (click)="playEpisode(episode)" (keydown.enter)="playEpisode(episode)" tabindex="0">
          <h4>{{ episode.title }}</h4>
          <p>{{ episode.description }}</p>
          <i class="fas fa-play-circle play-icon"></i>
          <i class="fas fa-info-circle info-icon" (click)="openEpisodeInfo(episode, $event)"></i>
        </li>
      </ul>
      <ng-template #noEpisodes>
        <p class="no-episodes-message">No episodes available for this season.</p>
      </ng-template>
    </div>
  </div>
  <button class="back-button" (click)="goBack()">Back</button>
  
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    *ngIf="loading$ | async"
  ></ngx-spinner>
  <div *ngIf="error$ | async as error" class="error-message">{{ error }}</div>
</div>

<!-- Modal for displaying metadata -->
<div class="modal" [ngClass]="{'visible': modalData}" *ngIf="modalData">
  <div class="modal-content">
    <span class="close" (click)="closeModal()">&times;</span>
    <h2>{{ modalData.title }}</h2>
    <p>{{ modalData.description }}</p>
    <div *ngIf="modalData.showMetadata">
      <p><strong>Director:</strong> {{ modalData.showMetadata.director }}</p>
      <p><strong>Producer:</strong> {{ modalData.showMetadata.producer }}</p>
      <p><strong>Release Date:</strong> {{ modalData.showMetadata.releaseDate | date }}</p>
      <p><strong>Duration:</strong> {{ modalData.showMetadata.duration }} Minutes</p>
      <p><strong>Rating:</strong> {{ modalData.showMetadata.rating }}</p>
      <p><strong>Genre:</strong> {{ modalData.showMetadata.genre }}</p>
    </div>
    <div *ngIf="modalData.showEpisodeMetadata">
      <p><strong>Director:</strong> {{ modalData.showEpisodeMetadata.director }}</p>
      <p><strong>Release Date:</strong> {{ modalData.showEpisodeMetadata.releaseDate | date }}</p>
      <p><strong>Duration:</strong> {{ modalData.showEpisodeMetadata.duration }} Minutes</p>
    </div>
  </div>
</div>