import { Component } from '@angular/core';
import { ContentService } from '../services/content.service';
import { ActivatedRoute, UrlTree } from '@angular/router';
import { ShowEpisodes, ShowResponse, Show } from 'src/interfaces/content';
import { ToggleDescriptionService } from '../services/toggle-description.service';
import { Media } from 'src/interfaces/player';
import { PaymentService } from '../services/payment.service';
import { IsPaidService } from '../services/is-paid.service';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesComponent {
  id!: string;
  show!:any;
  showData!:{key:string, data:any};
  seasonNumber = 1;
  filteredEpisodes! :ShowEpisodes[];
  public fileSrc!: string;
  media!: Media;
  isPaid!:boolean;

  playerOptions!: { autoplay: boolean; fluid: boolean; controls: boolean; sources: { src: string; type: string; }[]; };
  constructor(private contentService:ContentService ,
    private route:ActivatedRoute ,
    public toggleDesc: ToggleDescriptionService,
    private paymentService:PaymentService,
    private isPaidService:IsPaidService){}
  async ngOnInit(){
    this.getShows();
    this.isPaid = this.isPaidService.getPaidStatus();
    console.log(this.isPaid);
  }

  getShows(){
    this.id = this.route.snapshot.paramMap.get('id') ?? ""
    this.contentService.show(this.id).subscribe(
      response => {
        this.show = response.data;
        this.showData = {key: 'shows', data:this.show}

      }
    );
  }



  filterEpisodes(seasonNo: string) {
    this.seasonNumber = parseInt(seasonNo)
    this.filteredEpisodes = this.show.seasons.find((season: { no: number; }) => season.no === this.seasonNumber)?.episodes ?? [];
  }

  encodeUrl(url:string) : string{
    return url.replace(/ /g, "%20");
  }


}
