import { ContentItem } from "src/interfaces/content";

export interface ContentState {
    movies: ContentItem[];
    shows: ContentItem[];
    podcasts: ContentItem[];
    rssFeeds: ContentItem[];
    loading: boolean;
    error: string | null;
    selectedContent: ContentItem | null;
    selectedContentType: string | null;
    selectedContentId: string | null;
    contentId: string | null;
    loaded: boolean;
}

export const initialContentState: ContentState = {
    movies: [],
    shows: [],
    podcasts: [],
    rssFeeds: [],
    loading: false,
    error: null,
    selectedContent: null,
    selectedContentType: null,
    selectedContentId: null,
    contentId: null,
    loaded: false
}