<div 
  mat-raised-button class="google-btn" 
  (click)="continueWithGoogle()" 
  (keyup.enter)="continueWithGoogle()" 
  (keydown.space)="continueWithGoogle()" 
  (keydown.tab)="continueWithGoogle()" 
  tabindex="0" 
  role="button"
>
    <img src="../../assets/google-logo.png" width="5%" alt="Google logo" />
    <p>{{ buttonText }}</p>
</div>