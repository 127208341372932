<div class="form-container p-2" *ngIf="showForm">
  <div class="top-title">
    <h4>Season Details Upload</h4>
  </div>
  <form class="form-content p-2" [formGroup]="seasonDetailsForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
    <div class="upload-animation" *ngIf="showUploadAnimation">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
        </svg>
      </div>
    </div>
    <div *ngIf="showUploadSuccessAnimation" class="alert alert-success">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="tick"
                d="M10 18l5 5l10 -10"/>
        </svg>
      </div>
      Upload Successful
    </div>
    <div *ngIf="showSaveSuccessAnimation" class="alert alert-success">
      Data Was Saved Successfully
    </div>
    <div *ngIf="showSaveErrorAnimation" class="alert alert-danger">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="cross"
                d="M12 12l12 12M12 24l12 -12"/>
        </svg>
      </div>
      {{ message }}
    </div>
    <div *ngIf="showNextUploadPrompt" class="alert alert-info">
      <p>Do you want to upload another season?</p>
      <button type="button" class="btn btn-primary" (click)="onNextUploadResponse(true)">Yes</button>
      <button type="button" class="btn btn-secondary" (click)="onNextUploadResponse(false)">No</button>
    </div>
    <div class="form-row">
      <div class="form-column">
        <div class="p-1 form-group">
          <label for="showTitle">Show Title</label>
          <select id="showTitle" class="form-control" formControlName="showTitle">
            <option *ngFor="let show of shows" [value]="show.id">
              {{ show.title | slice:0:80 }}{{ show.title.length > 80 ? '...' : '' }}
            </option>
          </select>
          <div *ngIf="seasonDetailsForm.controls['showTitle'].invalid && (seasonDetailsForm.controls['showTitle'].dirty || seasonDetailsForm.controls['showTitle'].touched || submitted)">
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['showTitle'].errors?.['required']">
              Show Title is required.
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="seasonTitle">Season Title</label>
          <input id="seasonTitle" type="text" class="form-control" formControlName="seasonTitle" placeholder="Season Title">
          <div *ngIf="seasonDetailsForm.controls['seasonTitle'].invalid && (seasonDetailsForm.controls['seasonTitle'].dirty || seasonDetailsForm.controls['seasonTitle'].touched || submitted)">
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['seasonTitle'].errors?.['required']">
              Season Title is required.
            </div>
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['seasonTitle'].errors?.['maxLength']">
              Season Title exceeds the maximum length of 255 characters.
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="seasonNo">Season Number</label>
          <input id="seasonNo" type="number" class="form-control" formControlName="seasonNo" placeholder="Season No">
          <div *ngIf="seasonDetailsForm.controls['seasonNo'].invalid && (seasonDetailsForm.controls['seasonNo'].dirty || seasonDetailsForm.controls['seasonNo'].touched || submitted)">
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['seasonNo'].errors?.['required']">
              Season Number is required.
            </div>
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['seasonNo'].errors?.['min']">
              Season Number must be at least 1.
            </div>
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['seasonNo'].errors?.['max']">
              Season Number cannot exceed 100.
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="desc">Season Description</label>
          <textarea id="desc" class="form-control" formControlName="desc" placeholder="Season Description"></textarea>
          <div *ngIf="seasonDetailsForm.controls['desc'].invalid && (seasonDetailsForm.controls['desc'].dirty || seasonDetailsForm.controls['desc'].touched || submitted)">
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['desc'].errors?.['required']">
              Description is required.
            </div>
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['desc'].errors?.['maxLength']">
              Description exceeds the maximum length of 500 characters.
            </div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="image">Image</label>
          <input id="image" type="file" class="form-control" formControlName="image" placeholder="Image" #imageInput>
          <div *ngIf="seasonDetailsForm.controls['image'].invalid && (seasonDetailsForm.controls['image'].dirty || seasonDetailsForm.controls['image'].touched || submitted)">
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['image'].errors?.['required']">
              Image is required.
            </div>
            <div class="formalerts" *ngIf="seasonDetailsForm.controls['image'].errors?.['pattern']">
              Wrong type of file, image should have a valid extension (jpg, jpeg, png).
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary" type="submit">Submit</button>
  </form>
</div>