import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RssFeedService {
  private readonly rssfeed_proxy_url: string = `${environment.apiBaseUrl}/api/proxy/rss-feed`;
  private readonly audio_proxy_url: string = `${environment.apiBaseUrl}/api/proxy/audio`;
  private readonly rssfeed_url: string = `${environment.apiBaseUrl}/api/rssfeed/`;

  constructor(private readonly httpClient: HttpClient) { }

  private getAuthHeaders(): HttpHeaders {
    const accessToken = sessionStorage.getItem('2lietkapas');
    let headers = new HttpHeaders();
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  }

  // Handling errors
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error('Service Error:', errorMessage); // Log the error in the service
    return throwError(() => ({ status: error.status, message: errorMessage })); // Rethrow it back to the component
  }

  // Fetch RSS feed data using the proxy
  fetchRSSFeed(url: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.rssfeed_proxy_url}?url=${encodeURIComponent(url)}`, { headers, responseType: 'text' })
      .pipe(
        map(feedData => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(feedData, 'application/xml');
          const channel = xmlDoc.querySelector('channel');
          if (!channel) {
            throw new Error('Channel element not found in RSS feed');
          }
          const items = Array.from(channel.querySelectorAll('item')).map(item => {
            const enclosureUrl = item.querySelector('enclosure')?.getAttribute('url');
            return {
              title: item.querySelector('title')?.textContent,
              description: item.querySelector('description')?.textContent,
              link: item.querySelector('link')?.textContent,
              pubDate: item.querySelector('pubDate')?.textContent,
              guid: item.querySelector('guid')?.textContent,
              enclosure: enclosureUrl ? `${this.audio_proxy_url}?url=${encodeURIComponent(enclosureUrl)}` : null,
              duration: item.querySelector('itunes\\:duration')?.textContent,
              explicit: item.querySelector('itunes\\:explicit')?.textContent,
              episode: item.querySelector('itunes\\:episode')?.textContent,
              season: item.querySelector('itunes\\:season')?.textContent,
              episodeType: item.querySelector('itunes\\:episodeType')?.textContent,
              author: item.querySelector('itunes\\:author')?.textContent,
              subtitle: item.querySelector('itunes\\:subtitle')?.textContent,
              summary: item.querySelector('itunes\\:summary')?.textContent,
              image: item.querySelector('itunes\\:image')?.getAttribute('href')
            };
          });
          return { channel, items };
        }),
        catchError(this.handleError)
      );
  }

  // Create an RSS feed
  createRSSFeed(url: string, data: { contentTheme: string, email: string, useProvidedEmail: boolean }): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.post(`${this.rssfeed_url}submit`, { url, ...data }, { headers }) // Flatten the data structure
      .pipe(
        catchError(this.handleError)
      );
  }

  // Update the feed
  updateRSSFeed(feedId: string, data: { url: string, contentTheme: string }): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.put(`${this.rssfeed_url}${feedId}`, data, { headers })
      .pipe(
        catchError(this.handleError)
    );
  }

  // Get the feed by its ID
  getRSSFeedById(feedId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.rssfeed_url}${feedId}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get all feeds
  getAllRSSFeeds(): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.rssfeed_url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Delete the feed by its ID
  deleteRSSFeed(feedId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.delete(`${this.rssfeed_url}${feedId}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Delete all feeds
  deleteAllRSSFeeds(): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.delete(`${this.rssfeed_url}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Verify RSS feed
  verifyRSSFeed(token: string): Observable<any> {
    return this.httpClient.get(`${this.rssfeed_url}verify?token=${token}`)
      .pipe(
        catchError(this.handleError)
      );
  }
}
