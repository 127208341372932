<div class="container">
  <div class="content">
    <div class="header">
      <img src="assets/logo.png" alt="Company Logo" class="logo">
      <h1>RSS Feed Verification</h1>
    </div>
    <div class="message-box" [ngClass]="{'success': success, 'error': !success}">
      <p>{{ message }}</p>
    </div>
    <div class="footer">
      <p>&copy; {{ currentYear }} Nifty Work Plus TV. All rights reserved.</p>
    </div>
  </div>
</div>