import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private readonly payEndpoint = `${environment.apiBaseUrl}/api/payments`;
  private readonly confirmPayEndpoint = `${environment.apiBaseUrl}/api/v1/pay/verify`;

  private get headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('2lietkapas')}`,
    });
  }

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthenticationService,
    private readonly profileService: ProfileService
  ) {}

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Backend error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(() => new Error(errorMessage));
  }

  initiatePayment(userId: string, planId: string): Observable<any> {
    const body = { userId, planId };
    return this.http
      .post(`${this.payEndpoint}/initiate`, body, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  updatePayment(transactionReference: string, paymentStatus: string): Observable<any> {
    const body = { paymentStatus };
    return this.http
      .patch(`${this.payEndpoint}/${transactionReference}`, body, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  verifyPayment(transactionReference: string): Observable<any> {
    return this.http
      .get(`${this.payEndpoint}/${transactionReference}/verify`, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  getPaymentDetails(transactionReference: string): Observable<any> {
    return this.http
      .get(`${this.payEndpoint}/${transactionReference}`, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  getAllPayments(): Observable<any> {
    return this.http
      .get(this.payEndpoint, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  handlePaymentCallback(transactionId: string, txRef: string): Observable<any> {
    const params = { transaction_id: transactionId, tx_ref: txRef };
    return this.http
      .get(`${this.payEndpoint}/callback/pay`, { params })
      .pipe(catchError(this.handleError));
  }

  handlePaymentWebhook(event: any): Observable<any> {
    return this.http
      .post(`${this.payEndpoint}/payment/webhook`, event)
      .pipe(catchError(this.handleError));
  }
}
