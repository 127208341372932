import { createReducer, on } from '@ngrx/store';
import {
    loadSubscriptions,
    loadSubscriptionsSuccess,
    loadSubscriptionsFailure,
    subReset
} from '../actions/subscription.actions';
import { initialSubscriptionState } from '../state/subscription.state';

export const subscriptionReducer = createReducer(
    initialSubscriptionState,
    on(loadSubscriptions, state => {
        return { ...state, loading: true, error: null };
    }),
    on(loadSubscriptionsSuccess, (state, { subscriptions }) => {
        return {
            ...state,
            subscriptions,
            loading: false,
            error: null,
            subLoaded: true
        };
    }),
    on(loadSubscriptionsFailure, (state, { error }) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(subReset, state => {
        return initialSubscriptionState;
    }),
);