import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '../utils/constants';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent {
  passwordFormControl = new FormControl('', [Validators.required, Validators.minLength(6)]);
  networkErrorMessage: string = '';
  isLoading: boolean = false;
  actionCode: string = '';

  constructor(
    private readonly auth: AuthenticationService,
    private readonly router: Router
  ) {
    const userMngmtObject = JSON.parse(localStorage.getItem(LOCAL_STORAGE.userMngmtActionCode) ?? '{}');
    this.actionCode = userMngmtObject.actionCode;
    this.password = '';
  }

  email = '';
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  ]);
  password = ''

  getErrorMessage() {
    if (
      this.emailFormControl?.hasError('required') ||
      this.emailFormControl?.hasError('email') ||
      this.emailFormControl?.hasError('pattern')
    )
      return 'Please enter a valid email';
    else return;
  }

  getPasswordErrorMessage() {
    if (this.passwordFormControl?.hasError('required')) {
      return 'Please enter a password';
    } 

    if (this.passwordFormControl.value && this.passwordFormControl.value.length < 6) {
      return 'Password too short';
    } 
    return;
  }

  async updatePassword() {
    if (this.passwordFormControl.valid) {
      this.isLoading = true;
      this.auth.resetPasswordWithEmail(this.actionCode, this.passwordFormControl.value ?? '').subscribe({
        next: (response) => {
          if (response?.success) {
            this.router.navigate(['/signin']);
          } else {
            if (response?.error) {
              switch (response.error.code) {
                case 'auth/expired-action-code':
                  this.networkErrorMessage = 'The action code has expired.';
                  break;
                case 'auth/invalid-action-code':
                  this.networkErrorMessage = 'The action code is invalid.';
                  break;
                default:
                  this.networkErrorMessage = 'Oops! Something went wrong.';
                  console.error(response.error.code);
                  break;
              }
            } else {
              this.networkErrorMessage = 'Oops! Something went wrong.';
              console.error('Unexpected error');
            }
          }
          this.isLoading = false;
        },
        error: (err) => {
          // Handle any errors that occur during the request
          this.isLoading = false;
          console.error(err);
        }
      });
    }
  }
}