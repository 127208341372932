import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ProfileService } from '../services/profile.service';
import { LOCAL_STORAGE } from '../utils/constants';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuard {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly profileService: ProfileService,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.authService.canActivate().then(async (isAuthenticated) => {
      if (!isAuthenticated) {
        this.router.navigate(['/signin']);
        return false;
      }

      const token = sessionStorage.getItem(LOCAL_STORAGE.accessToken);

      if (token) {
        const decoded: any = jwtDecode(token);
        
        const user = await this.profileService.getUserById(decoded.id);
        
        if (user?.isAdmin) {
          return true;
        }
      }

      this.router.navigate(['/signin']);
      return false;
    });
  }
}