import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent {
  otpForm: FormGroup;
  errorMessage: string | null = null;
  loading: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthenticationService,
    private readonly router: Router
  ) {
    this.otpForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  onSubmit() {
    if (this.otpForm.valid) {
      this.loading = true;
      const email = this.otpForm.get('email')?.value;
      const otp = this.otpForm.get('otp')?.value;
      this.authService.verifyOTP(email, otp).subscribe({
        next: (response) => {
          this.loading = false;
          if (response.success) {
            this.router.navigate(['/signin']);
          } else {
            this.errorMessage = 'OTP verification failed. Please try again.';
          }
        },
        error: (err) => {
          this.loading = false;
          if (err.error.error) {
            switch (err.error.error) {
              case 'User not found':
                this.errorMessage = 'No user found with this email.';
                break;
              case 'Invalid OTP':
                this.errorMessage = 'The OTP you entered is incorrect.';
                break;
              case 'OTP has expired':
                this.errorMessage = 'The OTP has expired. Please request a new one.';
                break;
              default:
                this.errorMessage = 'An unexpected error occurred. Please try again later.';
            }
          } else {
            this.errorMessage = 'An unexpected error occurred. Please try again later.';
          }
        }
      });
    }
  }

  goBack() {
    this.router.navigate(['/verify-email']);
  }
}