import { Component, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { RssFeedService } from 'src/app/services/rss-feed.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { catchError, finalize, of, throwError, Observable } from 'rxjs';

@Component({
  selector: 'app-feed-details-upload',
  templateUrl: './feed-details-upload.component.html',
  styleUrls: ['./feed-details-upload.component.scss'],
  animations: [
    trigger('uploadAnimation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('300ms')),
      transition(':leave', animate('300ms')),
    ]),
  ],
})
export class FeedDetailsUploadComponent implements OnInit {
  @ViewChild('urlInput') urlInput!: ElementRef;

  rssFeedForm!: FormGroup;
  showUploadAnimation = false;
  showUploadSuccessAnimation = false;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  showNextUploadPrompt = false;
  showForm = true;
  message = '';
  submitted = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly rssFeedService: RssFeedService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.rssFeedForm = this.formBuilder.group({
      url: ['', [Validators.required, Validators.pattern('https?://.+')]],
      contentTheme: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      useProvidedEmail: [false] // Add the new form control
    });
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.rssFeedForm.invalid) {
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
      this.cdr.detectChanges();
      setTimeout(() => {
        this.showSaveErrorAnimation = false;
        this.cdr.detectChanges();
      }, 5000);
      return;
    }

    const url = this.rssFeedForm.get('url')?.value || '';
    const contentTheme = this.rssFeedForm.get('contentTheme')?.value || 'Lifestyle';
    const email = this.rssFeedForm.get('email')?.value || '';
    const useProvidedEmail = this.rssFeedForm.get('useProvidedEmail')?.value || false;

    this.showUploadAnimation = true;

    this.rssFeedService.createRSSFeed(url, { contentTheme, email, useProvidedEmail }).pipe(
      catchError((error: any) => {
        this.showSaveErrorAnimation = true;
        this.message = this.getErrorMessageFromStatusCode(error.status) || 'An error occurred while uploading.';
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showSaveErrorAnimation = false;
          this.message = '';
          this.cdr.detectChanges();
          this.resetForm();
        }, 5000);
        return of(null);
      }),
      finalize(() => {
        this.showUploadAnimation = false;
        this.cdr.detectChanges();
      })
    ).subscribe((response: any) => {
      if (response) {
        this.showUploadSuccessAnimation = true;
        this.showSaveSuccessAnimation = true;
        this.message = 'Upload Successful';
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showUploadSuccessAnimation = false;
          this.showSaveSuccessAnimation = false;
          this.showNextUploadPrompt = true;
          this.cdr.detectChanges();
          this.resetForm();
        }, 5000);
      } else if (!this.message) {
        this.showSaveErrorAnimation = true;
        this.message = 'Upload failed. Please try again.';
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showSaveErrorAnimation = false;
          this.message = '';
          this.cdr.detectChanges();
          this.resetForm();
        }, 5000);
      }
    });
  }

  resetForm(): void {
    this.rssFeedForm.reset();
    if (this.urlInput && this.urlInput.nativeElement) {
      this.urlInput.nativeElement.value = '';
    }
    this.rssFeedForm.markAsPristine();
    this.rssFeedForm.markAsUntouched();
    this.submitted = false;
    this.message = '';
    this.showSaveErrorAnimation = false;
  }

  onNextUploadResponse(response: boolean): void {
    this.showNextUploadPrompt = false;
    if (response) {
      this.resetForm();
    } else {
      this.showForm = false;
      this.showUploadAnimation = false;
      this.showUploadSuccessAnimation = false;
      this.showSaveSuccessAnimation = false;
      this.showSaveErrorAnimation = false;
      this.message = '';
    }
  }

  getErrorMessage(control: AbstractControl): string {
    if (control.hasError('required')) {
      return 'This field is required';
    } else if (control.hasError('pattern')) {
      return 'Invalid URL format';
    }
    return '';
  }

  private getErrorMessageFromStatusCode(status: number): string {
    switch (status) {
      case 400:
        return 'Invalid URL or missing required fields.';
      case 409:
        return 'RSS feed already exists.';
      case 404:
        return 'RSS feed not found.';
      case 500:
        return 'Internal server error. Please try again later.';
      default:
        return 'An unknown error occurred.';
    }
  }

  private handleError(error: any): Observable<never> {
    return throwError(() => error); // Rethrow it back to the component
  }
}
