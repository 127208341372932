<div class="container">
  <div class="row">
    <div class="col-3">
  <div class="cards">
    <div class="card mb-3" (click)="showShowDetails()" (keypress)="onKeyPress($event)" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" routerLink="showdetails">
      <div class="card-body">
        <h6 class="card-title">Show Details</h6>
        <i class="fa fa-lg fa-plus fa-bold" aria-hidden="true"></i>
      </div>
    </div>
    <div class="card mb-3" (click)="showSeasonDetails()" (keypress)="onKeyPress($event)" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" routerLink="seasons">
      <div class="card-body">
        <h6 class="card-title">Season Details</h6>
        <i class="fa fa-lg fa-plus fa-bold" aria-hidden="true"></i>
      </div>
    </div>
    <div class="card mb-3" (click)="showShowEpisodes()" (keypress)="onKeyPress($event)" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" routerLink="showepisodes">
      <div class="card-body">
        <h6 class="card-title">Show Episodes</h6>
        <i class="fa fa-lg fa-plus fa-bold" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>
    <div class="col-9">
      <app-show-details-uploads *ngIf="showDetailsState"></app-show-details-uploads>
      <app-season-details-uploads *ngIf="seasonDetailsState"></app-season-details-uploads>
      <app-show-episodes-uploads *ngIf="showEpisodesState"></app-show-episodes-uploads>
    </div>
  </div>
</div>