<div class="custom-payment-container">
  <h1>Payment Plan Management</h1>
  
  <div class="columns is-multiline">
    <!-- Payment Plan Form Column -->
    <div class="column is-half">
      <!-- Form Error Message -->
      <div *ngIf="formErrorMessage" class="notification is-danger">
        {{ formErrorMessage }}
      </div>
      
      <!-- Success Message -->
      <div *ngIf="successMessage" class="notification is-success">
        {{ successMessage }}
      </div>
      
      <form [formGroup]="paymentPlanForm" (ngSubmit)="createPaymentPlan()" class="form-container">
        <div class="field">
          <label class="label" for="plan">Plan Name</label>
          <div class="control">
            <input id="plan" class="input" formControlName="plan" placeholder="Enter plan name" required>
          </div>
          <p class="help is-danger" *ngIf="paymentPlanForm?.get('plan')?.invalid && paymentPlanForm?.get('plan')?.touched">
            <small *ngIf="paymentPlanForm?.get('plan')?.errors?.['required']">Plan name is required.</small>
            <small *ngIf="paymentPlanForm?.get('plan')?.errors?.['maxlength']">Plan name cannot exceed 100 characters.</small>
          </p>
        </div>

        <div class="field">
          <label class="label" for="price">Price</label>
          <div class="control">
            <input id="price" type="number" class="input" formControlName="price" placeholder="Enter price" required>
          </div>
          <p class="help is-danger" *ngIf="paymentPlanForm?.get('price')?.invalid && paymentPlanForm?.get('price')?.touched">
            <small *ngIf="paymentPlanForm?.get('price')?.errors?.['required']">Price is required.</small>
            <small *ngIf="paymentPlanForm?.get('price')?.errors?.['min']">Price must be a positive number.</small>
          </p>
        </div>

        <div class="field">
          <label class="label" for="duration">Duration</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select id="duration" formControlName="duration" required>
                <option *ngFor="let duration of availableDurations" [value]="duration">{{ duration }}</option>
              </select>
            </div>
          </div>
          <p class="help is-danger" *ngIf="paymentPlanForm?.get('duration')?.invalid && paymentPlanForm?.get('duration')?.touched">
            <small *ngIf="paymentPlanForm?.get('duration')?.errors?.['required']">Duration is required.</small>
          </p>
        </div>

        <div class="field">
          <label class="label" for="currency">Currency</label>
          <div class="control">
            <input id="currency" class="input" formControlName="currency" placeholder="Enter currency (e.g., USD)" required maxlength="3">
          </div>
          <p class="help is-danger" *ngIf="paymentPlanForm?.get('currency')?.invalid && paymentPlanForm?.get('currency')?.touched">
            <small *ngIf="paymentPlanForm?.get('currency')?.errors?.['required']">Currency is required.</small>
            <small *ngIf="paymentPlanForm?.get('currency')?.errors?.['maxlength']">Currency code cannot exceed 3 characters.</small>
          </p>
        </div>

        <div class="field">
          <label class="label" for="description">Description</label>
          <div class="control">
            <textarea id="description" class="textarea" formControlName="description" placeholder="Enter a brief description" required></textarea>
          </div>
          <p class="help is-danger" *ngIf="paymentPlanForm?.get('description')?.invalid && paymentPlanForm?.get('description')?.touched">
            <small *ngIf="paymentPlanForm?.get('description')?.errors?.['required']">Description is required.</small>
            <small *ngIf="paymentPlanForm?.get('description')?.errors?.['maxlength']">Description cannot exceed 500 characters.</small>
          </p>
        </div>

        <div class="field is-grouped">
          <div class="control">
            <button type="submit" class="button is-primary" [disabled]="paymentPlanForm.invalid">
              {{ isEditing ? 'Update' : 'Create' }} Payment Plan
            </button>
          </div>
          <div class="control" *ngIf="isEditing">
            <button type="button" class="button is-light" (click)="resetForm()">Cancel</button>
          </div>
        </div>
      </form>
    </div>

    <!-- Existing Payment Plans Column -->
    <div class="column is-half">
      <h2 class="subtitle">Existing Payment Plans</h2>
      <ul class="payment-plans-list">
        <li *ngFor="let plan of paymentPlans" class="payment-plan-item">
          <span>{{ plan.plan }} - {{ plan.currency }} {{ plan.price }} - {{ plan.duration }}</span>
          <div class="buttons">
            <button class="button is-warning is-small" (click)="editPaymentPlan(plan)">Edit</button>
            <button class="button is-danger is-small" (click)="deletePaymentPlan(plan.id)">Delete</button>
          </div>
        </li>
      </ul>
      <p *ngIf="noPlansMessage" class="notification is-info">{{ noPlansMessage }}</p>
    </div>
  </div>

  <!-- Error Message -->
  <div *ngIf="errorMessage" class="notification is-danger">
    {{ errorMessage }}
  </div>

  <!-- Loading Spinner -->
  <div *ngIf="loading" class="spinner-overlay">
    <div class="spinner-container">
      <div class="la-ball-clip-rotate-pulse la-2x" style="color: #f4696b;">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>