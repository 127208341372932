<div class="form-container p-2" *ngIf="showForm">
  <div class="top-title">
    <h4>Podcast Episodes Upload</h4>
  </div>
  <form class="form-content p-2" [formGroup]="podcastEpisodesForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">


    <!-- Upload Animation -->
    <div class="upload-animation" *ngIf="showUploadAnimation">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
        </svg>
      </div>
    </div>

    <!-- Upload Success Animation -->
    <div *ngIf="showUploadSuccessAnimation" class="alert alert-success">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="tick"
                d="M10 18l5 5l10 -10"/>
        </svg>
      </div>
      Upload Successful
    </div>

    <!-- Save Success Animation -->
    <div *ngIf="showSaveSuccessAnimation" class="alert alert-success">
      Data Was Saved Successfully
    </div>

    <!-- Save Error Animation -->
    <div *ngIf="showSaveErrorAnimation" class="alert alert-danger">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="cross"
                d="M12 12l12 12M12 24l12 -12"/>
        </svg>
      </div>
      {{ message }}
    </div>

    <!-- Next Upload Prompt -->
    <div *ngIf="showNextUploadPrompt" class="alert alert-info">
      <p>Would you like to upload another episode?</p>
      <button class="btn btn-secondary" (click)="onNextUploadResponse(true)">Yes</button>
      <button class="btn btn-secondary" (click)="onNextUploadResponse(false)">No</button>
    </div>

    <!-- Form Fields -->
    <div class="form-row">
      <div class="form-column">

        <!-- Podcast Title -->
        <div class="p-1 form-group">
          <label for="podcastId">Podcast Title</label>
          <select id="podcastId" class="form-control" formControlName="podcastId">
            <option value="" disabled>Select Podcast</option>
            <option *ngFor="let podcast of podcasts" [value]="podcast.contentId">
              {{ podcast.title | slice:0:40 }}{{ podcast.title.length > 40 ? '...' : '' }}
            </option>
          </select>
          <div *ngIf="podcastEpisodesForm.controls['podcastId'].invalid && (podcastEpisodesForm.controls['podcastId'].dirty || podcastEpisodesForm.controls['podcastId'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['podcastId'].errors?.['required']">
              {{ getErrorMessage(podcastEpisodesForm.controls['podcastId']) }}
            </div>
          </div>
        </div>

        <!-- Episode Title -->
        <div class="p-1 form-group">
          <label for="episodeTitle">Episode Title</label>
          <input id="episodeTitle" type="text" class="form-control" formControlName="episodeTitle" placeholder="Episode Title">
          <div *ngIf="podcastEpisodesForm.controls['episodeTitle'].invalid && (podcastEpisodesForm.controls['episodeTitle'].dirty || podcastEpisodesForm.controls['episodeTitle'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['episodeTitle'].errors?.['required']">
              {{ getErrorMessage(podcastEpisodesForm.controls['episodeTitle']) }}
            </div>
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['episodeTitle'].errors?.['maxlength']">
              {{ getErrorMessage(podcastEpisodesForm.controls['episodeTitle']) }}
            </div>
          </div>
        </div>

        <!-- Episode Description -->
        <div class="p-1 form-group">
          <label for="episodeDesc">Episode Description</label>
          <textarea id="episodeDesc" class="form-control" formControlName="episodeDesc" placeholder="Episode Description"></textarea>
          <div *ngIf="podcastEpisodesForm.controls['episodeDesc'].invalid && (podcastEpisodesForm.controls['episodeDesc'].dirty || podcastEpisodesForm.controls['episodeDesc'].touched || submitted)">
                        <div class="formalerts" *ngIf="podcastEpisodesForm.controls['episodeDesc'].errors?.['maxlength']">
              {{ getErrorMessage(podcastEpisodesForm.controls['episodeDesc']) }}
            </div>
          </div>
        </div>

        <!-- Duration -->
        <div class="p-1 form-group">
          <label for="duration">Duration</label>
          <input id="duration" type="text" class="form-control" formControlName="duration" placeholder="Duration">
          <div *ngIf="podcastEpisodesForm.controls['duration'].invalid && (podcastEpisodesForm.controls['duration'].dirty || podcastEpisodesForm.controls['duration'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['duration'].errors?.['required']">
              {{ getErrorMessage(podcastEpisodesForm.controls['duration']) }}
            </div>
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['duration'].errors?.['min']">
              {{ getErrorMessage(podcastEpisodesForm.controls['duration']) }}
            </div>
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['duration'].errors?.['max']">
              {{ getErrorMessage(podcastEpisodesForm.controls['duration']) }}
            </div>
          </div>
        </div>

        <!-- Author -->
        <div class="p-1 form-group">
          <label for="author">Author</label>
          <input id="author" type="text" class="form-control" formControlName="author" placeholder="Author">
          <div *ngIf="podcastEpisodesForm.controls['author'].invalid && (podcastEpisodesForm.controls['author'].dirty || podcastEpisodesForm.controls['author'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['author'].errors?.['required']">
              {{ getErrorMessage(podcastEpisodesForm.controls['author']) }}
            </div>
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['author'].errors?.['maxlength']">
              {{ getErrorMessage(podcastEpisodesForm.controls['author']) }}
            </div>
          </div>
        </div>
      </div>

      <!-- Form Column -->
      <div class="form-column">

        <!-- Director -->
        <div class="p-1 form-group">
          <label for="director">Director</label>
          <input id="director" type="text" class="form-control" formControlName="director" placeholder="Director">
          <div *ngIf="podcastEpisodesForm.controls['director'].invalid && (podcastEpisodesForm.controls['director'].dirty || podcastEpisodesForm.controls['director'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['director'].errors?.['required']">
              {{ getErrorMessage(podcastEpisodesForm.controls['director']) }}
            </div>
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['director'].errors?.['maxlength']">
              {{ getErrorMessage(podcastEpisodesForm.controls['director']) }}
            </div>
          </div>
        </div>

        <!-- Release Date -->
        <div class="p-1 form-group">
          <label for="releaseDate">Release Date</label>
          <input id="releaseDate" type="date" class="form-control" formControlName="releaseDate">
          <div *ngIf="podcastEpisodesForm.controls['releaseDate'].invalid && (podcastEpisodesForm.controls['releaseDate'].dirty || podcastEpisodesForm.controls['releaseDate'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['releaseDate'].errors?.['required']">
              {{ getErrorMessage(podcastEpisodesForm.controls['releaseDate']) }}
            </div>
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['releaseDate'].errors?.['pastDate']">
              {{ getErrorMessage(podcastEpisodesForm.controls['releaseDate']) }}
            </div>
          </div>
        </div>

        <!-- Audio Episode -->
        <div class="p-1 form-group">
          <label for="audioEpisode">Audio Episode</label>
          <input id="audioEpisode" type="file" class="form-control" formControlName="audioEpisode" #audioInput>
          <div *ngIf="podcastEpisodesForm.controls['audioEpisode'].invalid && (podcastEpisodesForm.controls['audioEpisode'].dirty || podcastEpisodesForm.controls['audioEpisode'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['audioEpisode'].errors?.['pattern']">
              {{ getErrorMessage(podcastEpisodesForm.controls['audioEpisode']) }}
            </div>
          </div>
        </div>

        <!-- Video Episode -->
        <div class="p-1 form-group">
          <label for="videoEpisode">Video Episode</label>
          <input id="videoEpisode" type="file" class="form-control" formControlName="videoEpisode" #videoInput>
          <div *ngIf="podcastEpisodesForm.controls['videoEpisode'].invalid && (podcastEpisodesForm.controls['videoEpisode'].dirty || podcastEpisodesForm.controls['videoEpisode'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastEpisodesForm.controls['videoEpisode'].errors?.['pattern']">
              {{ getErrorMessage(podcastEpisodesForm.controls['videoEpisode']) }}
            </div>
          </div>
        </div>

        <!-- Image File -->
        <div class="p-1 form-group">
          <label for="imageFile">Image File</label>
          <input id="imageFile" type="file" class="form-control" formControlName="imageFile" #imageInput>
        </div>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="p-1 form-group">
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
</div>