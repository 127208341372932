import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthenticationService
  ) { }

  // Sidebar toggle show hide function
  status = false;
  addToggle() {
    this.status = !this.status;       
  }

  // Logout function
  logout() {
    this.authService.signOut();
  }
}