<div class="reset-password">
    <app-light-logo class="logo"></app-light-logo>
    <form *ngIf="step === 1" (ngSubmit)="requestOtp()">
        <mat-form-field appearance="outline" class="mat-mdc-form-field">
            <mat-label>Please enter your email address</mat-label>
            <input matInput required name="email" id="email" [formControl]="emailFormControl">
            <mat-error *ngIf="emailFormControl?.invalid">{{ getErrorMessage() }}</mat-error>
        </mat-form-field>
        <mat-error class="network-error">{{ networkErrorMessage }}</mat-error>
        <button mat-raised-button color="primary" [disabled]="emailFormControl.invalid" type="submit">Request OTP</button>
    </form>

    <form *ngIf="step === 2" (ngSubmit)="resetPassword()">
        <mat-form-field appearance="outline" class="mat-mdc-form-field">
            <mat-label>Please enter the OTP</mat-label>
            <input matInput required name="otp" id="otp" [formControl]="otpFormControl">
            <mat-error *ngIf="otpFormControl?.invalid">{{ getErrorMessage() }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="mat-mdc-form-field">
            <mat-label>Please enter your new password</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" required name="password" id="password" [formControl]="passwordFormControl">
            <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="passwordFormControl?.invalid">{{ getErrorMessage() }}</mat-error>
        </mat-form-field>
        <mat-error class="network-error">{{ networkErrorMessage }}</mat-error>
        <button mat-raised-button color="primary" [disabled]="otpFormControl.invalid || passwordFormControl.invalid" type="submit">Reset Password</button>
    </form>
</div>
<app-footer></app-footer>