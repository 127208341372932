<div class="form-container p-2" *ngIf="showForm">
  <div class="top-title">
    <h4>Podcast Details Upload</h4>
  </div>
  <form class="form-content p-2" [formGroup]="podcastForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
    <div class="upload-animation" *ngIf="showUploadAnimation">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
        </svg>
      </div>
    </div>
    <div *ngIf="showUploadSuccessAnimation" class="alert alert-success">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="tick"
                d="M10 18l5 5l10 -10"/>
        </svg>
      </div>
      Upload Successful
    </div>
    <div *ngIf="showSaveSuccessAnimation" class="alert alert-success">
      Data Was Saved Successfully
    </div>
    <div *ngIf="showSaveErrorAnimation" class="alert alert-danger">
      <div class="progress-circle">
        <svg viewBox="0 0 36 36">
          <path class="circle-bg"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="circle"
                stroke-dasharray="100, 100"
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"/>
          <path class="cross"
                d="M12 12l12 12M12 24l12 -12"/>
        </svg>
      </div>
      {{ message }}
    </div>
    <div *ngIf="showNextUploadPrompt" class="alert alert-info">
      <p>Do you want to upload the next podcast details?</p>
      <button type="button" (click)="onNextUploadResponse(true)">Yes</button>
      <button type="button" (click)="onNextUploadResponse(false)">No</button>
    </div>
    <div class="form-row">
      <div class="form-column">
        <div class="p-1 form-group">
          <label for="podcastTitle">Podcast Title (First Episode)</label>
          <input type="text" class="form-control" formControlName="title" placeholder="Title" id="podcastTitle">
          <div *ngIf="podcastForm.controls['title'].invalid && (podcastForm.controls['title'].dirty || podcastForm.controls['title'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['title'].errors?.['required']">Title is required.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['title'].errors?.['maxlength']">Title exceeds the maximum length of 255 characters.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['title'].errors?.['pattern']">Title should only contain alphanumeric characters.</div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="podcastDesc">Podcast Description</label>
          <textarea class="form-control" formControlName="podcastDesc" placeholder="Podcast Description" id="podcastDesc"></textarea>
          <div *ngIf="podcastForm.controls['podcastDesc'].invalid && (podcastForm.controls['podcastDesc'].dirty || podcastForm.controls['podcastDesc'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['podcastDesc'].errors?.['required']">Description is required.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['podcastDesc'].errors?.['maxlength']">Description exceeds the maximum length of 500 characters.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['podcastDesc'].errors?.['pattern']">Description should only contain alphanumeric characters.</div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="image">Image</label>
          <input id="image" type="file" class="form-control" formControlName="image" placeholder="Image" #imageInput>
          <div *ngIf="podcastForm.controls['image'].invalid && (podcastForm.controls['image'].dirty || podcastForm.controls['image'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['image'].errors?.['required']">Image is required.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['image'].errors?.['pattern']">Wrong type of file, image should have a valid extension (jpg, jpeg, png).</div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="podcastFile">Podcast Audio (First Episode)</label>
          <input id="podcastFile" type="file" class="form-control" formControlName="podcastFile" placeholder="Podcast File" #podcastInput>
          <div *ngIf="podcastForm.controls['podcastFile'].invalid && (podcastForm.controls['podcastFile'].dirty || podcastForm.controls['podcastFile'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['podcastFile'].errors?.['pattern']">Wrong type of file, podcast should have a valid extension (mp3, wav).</div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="videoFile">Podcast Video (First Episode)</label>
          <input id="videoFile" type="file" class="form-control" formControlName="videoFile" placeholder="Video File" #videoInput>
          <div *ngIf="podcastForm.controls['videoFile'].invalid && (podcastForm.controls['videoFile'].dirty || podcastForm.controls['videoFile'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['videoFile'].errors?.['pattern']">Wrong type of file, video should have a valid extension (mp4, avi, mov).</div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="contentTheme">Content Theme</label>
          <select id="contentTheme" class="form-control" formControlName="contentTheme">
            <option value="Family">Family</option>
            <option value="Kids">Kids</option>
            <option value="Animations">Animation</option>
            <option value="Lifestyle">Lifestyle</option>
          </select>
          <div *ngIf="podcastForm.controls['contentTheme'].invalid && (podcastForm.controls['contentTheme'].dirty || podcastForm.controls['contentTheme'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['contentTheme'].errors?.['required']">Content Theme is required.</div>
          </div>
        </div>
      </div>

      <div class="form-column">
        <div class="p-1 form-group">
          <label for="duration">Duration</label>
          <input id="duration" type="text" class="form-control" formControlName="duration" placeholder="Duration">
          <div *ngIf="podcastForm.controls['duration'].invalid && (podcastForm.controls['duration'].dirty || podcastForm.controls['duration'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['duration'].errors?.['required']">Duration is required.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['duration'].errors?.['min']">Duration must be at least 1 minute.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['duration'].errors?.['max']">Duration cannot exceed 600 minutes.</div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="producer">Producer</label>
          <input id="producer" type="text" class="form-control" formControlName="producer" placeholder="Producer">
          <div *ngIf="podcastForm.controls['producer'].invalid && (podcastForm.controls['producer'].dirty || podcastForm.controls['producer'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['producer'].errors?.['required']">Producer is required.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['producer'].errors?.['maxlength']">Producer name exceeds the maximum length of 255 characters.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['producer'].errors?.['pattern']">Producer name should only contain alphabetic characters.</div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="director">Director</label>
          <input id="director" type="text" class="form-control" formControlName="director" placeholder="Director">
          <div *ngIf="podcastForm.controls['director'].invalid && (podcastForm.controls['director'].dirty || podcastForm.controls['director'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['director'].errors?.['required']">Director is required.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['director'].errors?.['maxlength']">Director name exceeds the maximum length of 255 characters.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['director'].errors?.['pattern']">Director name should only contain alphabetic characters.</div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="releaseDate">Release Date</label>
          <input id="releaseDate" type="date" class="form-control" formControlName="releaseDate" placeholder="Release Date">
          <div *ngIf="podcastForm.controls['releaseDate'].invalid && (podcastForm.controls['releaseDate'].dirty || podcastForm.controls['releaseDate'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['releaseDate'].errors?.['required']">Release Date is required.</div>
            <div class="formalerts" *ngIf="podcastForm.controls['releaseDate'].errors?.['pastDate']">Release Date cannot be in the past.</div>
          </div>
        </div>

        <div class="p-1 form-group">
          <label for="genre">Genre</label>
          <select id="genre" class="form-control" formControlName="genre">
            <option value="" disabled selected>Select Genre</option>
            <option value="Comedy">Comedy</option>
            <option value="Musical">Musical</option>
            <option value="Sports">Sports</option>
            <option value="Drama">Drama</option>
            <option value="Adventure">Adventure</option>
            <option value="Romance">Romance</option>
            <option value="Documentary">Documentary</option>
          </select>
          <div *ngIf="podcastForm.controls['genre'].invalid && (podcastForm.controls['genre'].dirty || podcastForm.controls['genre'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['genre'].errors?.['required']">Genre is required.</div>
          </div>
        </div>
        
        <div class="p-1 form-group">
          <label for="rating">Rating</label>
          <select id="rating" class="form-control" formControlName="rating">
            <option value="" disabled selected>Select Rating</option>
            <option value="GE">GE</option>
            <option value="PG">PG</option>
            <option value="PG13">PG13</option>
            <option value="R">R</option>
            <option value="NC16">NC16</option>
            <option value="NC18">NC18</option>
          </select>
          <div *ngIf="podcastForm.controls['rating'].invalid && (podcastForm.controls['rating'].dirty || podcastForm.controls['rating'].touched || submitted)">
            <div class="formalerts" *ngIf="podcastForm.controls['rating'].errors?.['required']">Rating is required.</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="podcastForm.errors?.['fileConflict']" class="formalerts">
      You can upload either a podcast file or a video file, but not both.
    </div>

    <button class="btn btn-primary" type="submit">Submit</button>
  </form>
</div>