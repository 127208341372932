import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  private _isLoading: boolean = false;

  @Input()
  set isLoading(value: boolean | null) {
    this._isLoading = value ?? false;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
}